.rr-simple-table {

}

.rr-simple-table-row, .rr-simple-table-header-row {
    display: flex;
    align-items: stretch;
    flex-direction: row;
}

.rr-simple-table-row:hover {
    /*background: #d1d4da;*/
}

.rr-simple-table-row-odd {
    /*background: #CCCCCC;*/
}

.rr-simple-table-row-even {
    /*background: #95989d;*/
}

.rr-simple-table-header-cell {
    font-weight: 600;
}

.rr-simple-table-cell, .rr-simple-table-header-cell {
    /*padding: 4px;*/
    word-break: break-word;
    flex-grow: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.rr-simple-table-cell > div {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow-wrap: break-word;
    width: 100%;
}

.rr-simple-table-cell-align-left {
    align-items: flex-start;
}

.rr-simple-table-cell-align-center {
    align-items: center;
}

.rr-simple-table-cell-align-right {
    align-items: flex-end;
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';