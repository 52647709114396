.rr-customField-select-list-item {
  display: flex;
  align-items: center;
}

.rr-customField-select-list-item-icon {
  margin-right: 4px;
}

.ant-select-dropdown-menu-item-selected .rr-customField-select-list-item {
  color: #ffffff !important;
}
@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';