.rr-PricingScheme-price__popover .ant-popover-inner-content {
  padding: 0;
  min-width: 200px;
}

.rr-PricingScheme-price__popover.rr-PricingScheme-price__popover-full.ant-popover-placement-leftTop .ant-popover-arrow,
.rr-PricingScheme-price__popover.rr-PricingScheme-price__popover-full.ant-popover-placement-rightTop .ant-popover-arrow
{
  border-top-color: #525a95;
  border-right-color: #525a95;
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';