@distance: 64px;

.rr-drawer-lvl-1 {
    transform: translateX(-@distance*1) !important;
}

.rr-drawer-lvl-2 {
    transform: translateX(-@distance*2) !important;
}

.rr-drawer-lvl-3 {
    transform: translateX(-@distance*3) !important;
}

.rr-drawer-lvl-4 {
    transform: translateX(-@distance*4) !important;
}

.rr-drawer-lvl-5 {
    transform: translateX(-@distance*4) !important;
}

.rr-drawer-lvl-6 {
    transform: translateX(-@distance*4) !important;
}

.rr-drawer-lvl-7 {
    transform: translateX(-@distance*4) !important;
}

.rr-drawer-lvl-8 {
    transform: translateX(-@distance*4) !important;
}

.rr-drawer-lvl-9 {
    transform: translateX(-@distance*4) !important;
}

.rr-drawer-lvl-10 {
    transform: translateX(-@distance*4) !important;
}


@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';