@import '../../../../assets/styles/vars';

.rr-product-page-variants-columns-name {
  text-align: left;
}


.rr-product-page-variants-columns-externalCode {
  color: #cccccc;
}


.rr-product-page-variants-columns-externalCode-own {
  color: #575962;
}

.rr-product-page-variants-columns-stockInstanceCount {
  font-weight: 600;
  color: #343f9a
}

.product-page-description-link:hover {
  text-decoration: underline;
  opacity: @rr-link-hover-color-opacity;
}



@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';