.rr-alert-simple.ant-alert-with-description {
  padding: 8px;
}

.rr-alert-simple.ant-alert-with-description .ant-alert-message {
  margin-bottom: 0;
}

.rr-alert-simple.ant-alert-with-description .ant-alert-close-icon {
  top: 8px;
  right: 8px;
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';