.rr-img-modal {
  .ant-modal-body, .ant-modal-content {
    border-radius: 0;
    padding: 0;
  }
}

.rr-img-modal img {
  max-width: calc(100vw - 80px);
  max-height: calc(100vh - 80px);
}
.rr-img-modal {
  padding: 0px;
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';