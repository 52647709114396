.rr-infoPopover {
  max-width: 760px;
}

.rr-infoPopover .ant-popover-inner {
  box-shadow: 0 0px 10px rgba(0, 0, 0, .20);
}

.rr-infoPopover .ant-popover-inner-content {
  padding: 0px;
}
@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';