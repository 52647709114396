.calendar-footer-container {
    cursor: pointer;
    margin-top: 34px;

    display: flex;
    justify-content: center;

    .calendar-footer {
        text-decoration: underline;
        color: #525993;
        font-size: 18px;
        margin-right: -20px;

        .items-count {
            font-weight: 700;
        }
    }
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';