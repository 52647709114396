.rr-operationForm-param-reset-button {
  font-size: 20px;
  //vertical-align: middle;
  color: #CCCCCC;
}

.rr-operationForm-param-reset-button__changed {
  color: #8d5bae;
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';