.rootuncharted {
  //display: inline-block;
  width: 100%;
  height: 100%;
  min-height: 300px;
}

.containeruncharted {
  //position: absolute;
  height: 100%;
  //width: 100%;
  width: 100%;
  display: inline-block;
}

.chart-area-spinner {
  width: 100%;
  height: 100%;
}

.chart-area-spinner > .ant-spin-container {
  width: 100%;
  height: 100%;
}

//i.anticon-loading {
//  position: absolute;
//  top: 50%;
//  margin-top: -15px;
//}

.not-enough-space {
  display: none;
}

@media (max-width: 665px) {
  .graphic {
    display: none;
  }
  .not-enough-space {
    display: block;
  }
  .card-no-padding {
    margin-top: 0;
  }
}

.card-no-padding {
  margin-top: 12px;
  background-color: #fff;
  padding: 0;
}

.padded-row {
  padding-left: 32px;
  padding-right: 32px;
  padding-bottom: 32px;
}

.rr-report-filters .ant-form-inline .ant-form-item {
  margin-right: 0;
}

div.rr-report-filters > div > div > form > div.rr-grid-filters {
  margin-bottom: 0;
}

//div.rr-grid-filters-left > div.ant-row > div.ant-col > span {
//  font-weight: 700;
//  font-size: 21px;
//  text-align: center;
//}

.rr-report-filters .ant-form-inline .rr-grid-filters-left {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
}

.rr-report-filters-buttons {
  display: flex;
  flex-wrap: nowrap;
}

@media (max-width: 1199px) {
  .rr-report-filters .ant-form-inline .rr-grid-filters-left .ant-form-item {
    margin-right: 0;
  }
  .rr-report-filters-buttons {
    flex-wrap: wrap;
  }
}

@media (min-width: 1200px) and (max-width: 1550px) {
  .rr-report-filters .ant-form-inline .rr-grid-filters-left {
    grid-template-columns: repeat(2, 1fr);
  }

  .rr-report-filters .ant-form-inline .rr-grid-filters-left .ant-form-item {
    border-right: 1px solid #dbdde6 !important;
  }

  .rr-report-filters .ant-form-inline .rr-grid-filters-left .ant-form-item:nth-child(2n),
  .rr-report-filters .ant-form-inline .rr-grid-filters-left .ant-form-item:last-child {
    border-right: none !important;
  }
}

@media (min-width: 1550px) and (max-width: 1785px) {
  .rr-report-filters .ant-form-inline .rr-grid-filters-left {
    grid-template-columns: repeat(3, 1fr);
  }

  .rr-report-filters .ant-form-inline .rr-grid-filters-left .ant-form-item {
    border-right: 1px solid #dbdde6 !important;
  }

  .rr-report-filters .ant-form-inline .rr-grid-filters-left .ant-form-item:nth-child(3n),
  .rr-report-filters .ant-form-inline .rr-grid-filters-left .ant-form-item:last-child {
    border-right: none !important;
  }
}

@media (min-width: 1785px) {
  .rr-report-filters .ant-form-inline .rr-grid-filters-left {
    grid-template-columns: repeat(4, 1fr);
  }

  .rr-report-filters .ant-form-inline .rr-grid-filters-left .ant-form-item {
    border-right: 1px solid #dbdde6 !important;
  }

  .rr-report-filters .ant-form-inline .rr-grid-filters-left .ant-form-item:nth-child(4n),
  .rr-report-filters .ant-form-inline .rr-grid-filters-left .ant-form-item:last-child {
    border-right: none !important;
  }
}

//@media (min-width: 2000px) {
//  .rr-report-filters .ant-form-inline .rr-grid-filters-left {
//    grid-template-columns: repeat(5, 1fr);
//  }
//
//  .rr-report-filters .ant-form-inline .rr-grid-filters-left .ant-form-item {
//    border-right: 2px solid #dbdde6 !important;
//  }
//
//  .rr-report-filters .ant-form-inline .rr-grid-filters-left .ant-form-item:nth-child(5n),
//  .rr-report-filters .ant-form-inline .rr-grid-filters-left .ant-form-item:last-child {
//    border-right: none !important;
//  }
//}

.rr-reports-uncharted-link {
  color: #FFFFFF;
}

.rr-reports-uncharted-link:hover {
  color: #FFFFFF;
  cursor: pointer;
  text-decoration: underline;
}

.rr-uncharted-tooltip-text {
  color: #FFFFFF;
}

.rr-report-filters {
  display: flex;
}

div.rr-report-filters > div > div > form > .rr-grid-filters > .rr-grid-filters-left > div > div {
  margin-bottom: 0;
}

.rr-report-filters .rr-grid-filters-left .ant-form-item-control-wrapper {
  flex: unset;
}

.rr-report-filters .ant-form-inline .rr-grid-filters-left .ant-form-item {
  justify-content: center;
  display: inline-flex;
  margin-bottom: 18px;
}

.rr-report-filters .ant-form-inline .rr-grid-filters-left .ant-form-item:first-child {
  //justify-content: start;
  font-weight: 700;
  font-size: 21px;
  //  text-align: center;
}

.rr-report-filters-buttons > * {
  margin-left: 6px;
  margin-bottom: 6px;
}

.rr-report-export-button > span {
  margin-left: 10px;
}

.rr-report-filters-buttons .rr-grid-export-button {
  height: 40px;
  border: 0;
  background-color: #fff;
  margin-right: 30px;
  padding-right: 0;
  padding-left: 0;
  white-space: nowrap;
  display: flex;
}

.rr-report-filters-buttons .rr-grid-export-button-disabled {
  opacity: .8;
  cursor: default;
  color: rgba(0, 0, 0, 0.25)
}

.ant-card .graphic > * {
  height: 1025px;
}
@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';