@import '../../../../../assets/styles/vars';

.rr-dashboard-info-panel-header {
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.rr-dashboard-info-panel-div {
    background-color: #ffffff;
    flex-grow: 1;
    padding: 15px;
    padding-right: 5px;
    // height: 131px; //

    .left-block {
        margin-top: 5px;
        font-size: 18px;

        .rr-dashboard-info-panel-link:hover {
            opacity: @rr-link-hover-color-opacity;
        }
    }

    .left-block-span {
        font-size: 14px;
        margin-top: -5px;
        padding-bottom: 11px;
        display: block;
    }

    .left-block-icon {
        font-size: 22px;
        // margin-left: 15px;
        margin-right: 3px;
        margin-top: 1px;
        color: rgb(170, 170, 170);
    }
}

.rr-dashboard-info-panel-div .rr-dashboard-info-panel-flex-block .rr-dashboard-info-panel-icon,
.rr-dashboard-info-panel-flex-block .rr-dashboard-info-panel-icon {
    font-size: 22px;
    margin-right: 7px;
    margin-top: 1px;
    vertical-align: middle;
}

.rr-dashboard-info-panel-right-span-2 {
    font-size: 18px;
    color: rgb(87, 89, 98);
    font-weight: 700;
}

.rr-dashboard-info-panel-right-span-1 {
    font-size: 18px;
    color: #aaaaaa;
}

.rr-dashboard-info-panel-link-1 {
    display: flex;
    margin-top: 0;
    flex-grow: 1;
    min-width: 210px;
}

.rr-dashboard-info-panel-link-1:hover {
    opacity: 0.8;
}

.rr-dashboard-info-panel-link-2 {
    display: flex;
    min-width: 210px;
    margin-top: 0;
    flex-grow: 1;
    cursor: default;
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';