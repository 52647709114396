.calendar-group-item-content {
    display: flex !important;
    align-items: center;
    gap: 12px;
    width: 100%;
    padding: 0 !important;
    overflow: initial !important;
    background-color: #f2f3f8;
    color: rgb(87, 89, 98);
    height: 26px;
    font-weight: 600;
    border: none;
    border-top: 1px solid #ccc;
    position: absolute;
    cursor: pointer;
}

.calendar-group-item-collapse-icon {
    &.normal {
        font-size: 20px;
        margin-left: 8px;
    }
    &.compact {
        font-size: 11px;
        margin-left: 8px;
    }
    &.unfold {
        margin-top: 2px;
    }
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';