.ant-badge {
  font-feature-settings: normal;
  font-variant: normal;
}

.ant-badge-dot, .ant-badge-count {
  box-shadow: none;
}

.ant-badge-count {
  font-feature-settings: normal;
  font-variant: normal;
  font-size: 11px;
  font-weight: 500;
  z-index: 1; // TODO В antd 3.26.7 z-index изменили с 10 на auto, из-за этого сломались клики на нем в меню. Потом попробовать обойтись без этого хака
}