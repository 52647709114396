.rr-prolong-operation-confirm-modal .rr-modal-confirm-content {
  padding: 0;
  padding-bottom: 20px;
}

.rr-prolong-operation-confirm-modal .rr-form-group {
  padding: 0;
  margin: 0;
}

.rr-prolong-operation-confirm-modal .rr-prolong-operation-confirm-modal-block {
  padding-top: 16px;
  padding-left: 32px;
  padding-right: 32px;
  padding-bottom: 6px;
}

.rr-prolong-operation-confirm-modal .rr-prolong-operation-confirm-modal-block:not(:last-child) {
  border-bottom: 1px solid #ebedf2;
}

.rr-prolong-operation-confirm-modal .rr-prolong-operation-confirm-modal-block:last-child {
  padding-top: 36px;
  padding-bottom: 5px;
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';