.ant-avatar > img {
  object-fit: cover;
}

.rr-avatar-medium.ant-avatar {
  //background-color: #e2e5ec;
  vertical-align: middle;
  width: 130px;
  height: 130px;
  line-height: 130px;
}

.rr-avatar-medium.ant-avatar .ant-avatar-string {
  font-size: 60px;
}

//
.rr-avatar-big.ant-avatar {
  //background-color: #e2e5ec;
  vertical-align: middle;
  width: 158px;
  height: 158px;
  line-height: 158px;
}

.rr-avatar-big.ant-avatar .ant-avatar-string {
  font-size: 80px;
}

.rr-avatar-big-with-shadow.ant-avatar {
  vertical-align: middle;
  width: 158-12*2px;
  height: 158-12*2px;
  line-height: 158-12*2px;
}

.rr-avatar-big-with-shadow.ant-avatar .ant-avatar-string {
  font-size: 80px;
}

.rr-avatar-medium.ant-avatar {
  vertical-align: middle;
  width: 120px;
  height: 120px;
  line-height: 120px;
}

.rr-avatar-medium.ant-avatar .ant-avatar-string {
  font-size: 60px;
}

.rr-avatar-medium-110.ant-avatar {
  vertical-align: middle;
  width: 110px;
  height: 110px;
  line-height: 110px;
}

.rr-avatar-medium-110.ant-avatar .ant-avatar-string {
  font-size: 60px;
}