.event-type-container {
    display: inline-flex !important;
    align-items: center;
    justify-content: center;
    grid-gap: 6px !important;
    gap: 6px !important;

    .anticon {
        font-size: 21px;
        line-height: 21px;
        margin-top: 2px;
    }
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';