.contacts-editor-table {
  line-height: 1;
}

.contacts-editor-table .rr-simple-table-header-cell {
  padding-bottom: 4px;
  align-items: flex-start;
}

.contacts-editor-table .rr-simple-table-cell {
  padding-top: 8px;
  padding-bottom: 8px;
}

////////////////////////////////////////
.has-error .rr-edit-contacts-form .ant-input, .has-error .rr-edit-contacts-form .ant-input:not([disabled]):hover {
  border-color: #dcdfe8;
  color: rgba(87, 89, 98, 0.8);
}

.has-error .rr-edit-contacts-form .ant-input:hover {
  border-color: #c9cedc;
}

.has-error .rr-edit-contacts-form .ant-input:focus, .has-error .rr-edit-contacts-form .ant-input:not([disabled]):focus:hover {
  border-color: unset;
}

////////////////////////////////////////

.has-error .rr-edit-contacts-form .ant-select .ant-select-selection {
  border-color: #dcdfe8;
  color: rgba(87, 89, 98, 0.8);
}

.has-error .rr-edit-contacts-form .ant-select .ant-select-selection:hover {
  border-color: #dcdfe8;
  color: rgba(87, 89, 98, 0.8);
}

.has-error .rr-edit-contacts-form .ant-select .ant-select-arrow {
  color: #6c6e86;
}

.has-error .rr-edit-contacts-form .ant-select.ant-select-focused .ant-select-selection {
  border-color: #dcdfe8;
  color: rgba(87, 89, 98, 0.8);
}

.has-error .rr-edit-contacts-form .ant-select.ant-select-focused:hover .ant-select-selection {
  border-color: #dcdfe8;
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';