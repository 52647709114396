.historyBottomCard .ant-card-body {
  padding: 20px;
}

.actionColumHover:hover {
  text-decoration: underline;
  cursor: pointer;
}

.rr-label .history-elements-lock i {
  cursor: default;
}
@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';