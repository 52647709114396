@import '../../../../assets/styles/vars';

.rr-page-settings__content .rr-page-settings-settings-item:not(:last-child) .rr-settings-page__info-block {
    margin-bottom: 18px;
}

.rr-settings-page__info-block {
    color: #bcbcbc;
    font-size: 12px;
    padding-top: 3px;
    flex-grow: 10;
    position: relative;
    transition: all 0.15s;
}

.rr-settings-page__info-block:hover {
    font-size: 14px;
    color: unset;
}

.rr-settings-page__info-block .rr-settings-page__info-block__icon {
    opacity: 0;
    font-size: 22px;
    color: #525993;
    padding-right: 5px;
    vertical-align: top;
    margin-top: -2px;
}

.rr-settings-page__info-block:hover .rr-settings-page__info-block__icon {
    opacity: 1;
}

.rr-settings-page__token-reset-button,
.rr-settings-page__token-reset-button:hover,
.rr-settings-page__token-reset-button:focus {
    background-color: #525993;
    color: #ffffff;
    border: none;
}

.rr-settings-page__token-reset-button.ant-btn .anticon {
    font-size: 20px;
    padding-top: 8px;
}

.rr-settings-page__token-reset-button:hover {
    background-color: @rr-colors-purple-1;
}

.rr-settings-page__token-reset-button {
    margin-top: 10px;
}

.rr-settings-page__barcode-preview-link {
    margin-left: 20px;
    font-size: 14px;
    font-weight: 400;
    text-decoration: underline;
    cursor: pointer;
    color: #343e99;

    &:hover,
    &:focus {
        opacity: 0.8;
    }
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';