.rr-simpleOrderPage-bottomBlock-price {
    padding: 32px;
    display: flex;
    flex-direction: column;
    align-items: end;
    flex-grow: 1;
}

.rr-simpleOrderPage-bottomBlock-left {
    // padding: 32px;
    flex-grow: 1;
}

.rr-simpleOrderPage-bottomBlock-padding {
    padding: 32px;
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';