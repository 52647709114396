.filters-reset-container {
    align-items: center;
    display: inline-flex;
    justify-content: center;
    font-weight: 600;
    margin-left: 22px;
    color: #525993;

    & > div {
        display: flex;
        align-items: center;
    }

    .icon {
        font-size: 24px;
        color: #f4516c;
        cursor: pointer;
        margin-left: 6px;
    }
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';