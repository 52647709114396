@import '../../assets/styles/vars';

.ant-empty.rr-empty-categories .ant-avatar {
    background-color: #e2e5ec;
}

.ant-empty.rr-empty-categories .ant-empty-image {
    height: auto;
}

.ant-empty.rr-empty-categories .ant-empty-description > div:nth-child(1) {
    font-weight: 600;
    margin-top: 20px;
    margin-bottom: 2px;
}

.ant-empty.rr-empty-categories .ant-empty-description > div:nth-child(2) a {
    color: @rr-link-default-color;
    text-decoration: underline;
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';