.rr-product-instance-action-circle-ACTIVATE {
  width: 10px;
  height: 10px;
  background-color: #34BFA3;
  border-radius: 5px;
  margin-left: 6px;
}

.rr-product-instance-action-circle-SEND_UNDER_MAINTENANCE {
  width: 10px;
  height: 10px;
  background-color: #f25572;
  border-radius: 5px;
  margin-left: 6px;
}

.rr-product-instance-action-circle-DECOMMISSION {
  width: 10px;
  height: 10px;
  background-color: #a0a0a0;
  border-radius: 5px;
  margin-left: 6px;
}


@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';