@import url('../../../../assets/styles/vars');

.profession-popover-header {
    padding: 8px;
    min-height: unset;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
}

.select-option {
    color: @rr-status-active-color;
    margin-right: 8px;
}

.profession-popover-overlay {
    min-width: 500px;

    .ant-select-selection__choice {
        max-width: 380px;
    }
    &.ant-select-selection,
    &.ant-select-selection:hover,
    &.ant-select-selection:focus,
    &.ant-select-selection:active,
    &.ant-select-focused .ant-select-selection {
        border-color: transparent;
    }
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';