@import '../../../../../../assets/styles/vars';

.instanceWidget {
    border-radius: 5px;
    padding: 8px 12px;
    font-weight: 600;
    color: white;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    cursor: pointer;
    margin-right: 10px;
}

.instanceWidget-withCalendarLink {
    margin-right: 0px;
}

.instanceWidgetAnonymous {
    padding: 7px 12px;
    border: 1px solid #525993;
    color: #525993;
    cursor: default;
    margin-bottom: 10px;
}

.instanceWidgetAnonymous span {
    font-weight: 400;
}

.instanceWidget .instanceWidget-icon {
    font-size: 20px;
    margin-right: 6px;
    margin-top: 0px;
}

.instanceWidget .instanceWidget-content {
    display: inline-flex;
}

.instanceAnonymousOrderShortage {
    border: 1px solid @rr-color-warning;
    color: @rr-color-warning;
}

.instanceAnonymousBookShortage {
    border: 1px solid @rr-color-danger;
    color: @rr-color-danger;
}

.instanceAnonymousShortage {
    border: 1px solid @rr-color-danger;
    color: @rr-color-danger;
}

.instanceWidget-calendar-icon {
    display: inline-flex;
    height: 37px;
    align-items: center;
    margin-left: 4px;
    margin-right: 14px;

    a {
        height: 21px;

        .anticon {
            font-size: 21px;
        }
    }
}

.instanceWidgetWrapper {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.instanceWidgetWrapper-orderShortage .instanceWidget {
    background-color: @rr-color-warning;
}

.instanceWidgetWrapper-bookShortage .instanceWidget {
    background-color: @rr-color-danger;
}

.instanceWidgetWrapper-orderShortage .instanceWidget-calendar-icon .anticon {
    color: @rr-color-warning;
}

.instanceWidgetWrapper-bookShortage .instanceWidget-calendar-icon .anticon {
    color: @rr-color-danger;
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';