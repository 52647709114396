@import '../../../../assets/styles/vars.less';

.inline-number-input-container {
    min-width: 105px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1px;
    user-select: none;
    z-index: 999;

    .inline-number-input {
        min-width: 66px;
        height: 26px;
        border-radius: 5px;
        text-align: center;
        color: rgba(87, 89, 98, 0.8);

        border: none;

        &.light-theme {
            background-color: #fff;
        }
        &.dark-theme {
            background-color: #f4f5f8;
        }

        &.empty {
            width: 66px;
        }
    }

    .arrow {
        position: relative;
        cursor: pointer;

        svg {
            width: 18px;
            height: 18px;
            color: @rr-default-input-border-focus-color;
        }

        &.disabled {
            cursor: not-allowed;

            svg {
                color: @rr-default-input-placeholder-color;
            }
        }
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type='number'] {
    -moz-appearance: textfield;
}

.absolute {
    position: absolute;
}

.icon-container {
    all: initial !important;
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';