@import '../../../../assets/styles/vars.less';

.rr-operation-problem-modal .ant-modal-header {
  padding: 24px 40px 23px 24px;
}

.rr-operation-problem-modal .ant-modal-title .anticon {
  margin-right: 14px;
  font-size: 32px;
  vertical-align: middle;
}

.rr-operation-problem-modal .product-link {
  color: #343f9a;
}

.rr-operation-problem-modal .product-link:hover {
  text-decoration: underline;
}

.rr-operation-problem-modal-okButton-disbaled .ant-btn {
  color: @rr-color-disabled !important;
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';