.pricing-scheme-field {
    .ant-select-selection-selected-value {
        i {
            position: relative;
            top: 4px;
        }
    }
}

.pricing-scheme-option {
    &.ant-select-dropdown-menu-item {
        display: flex !important;
        align-items: center;
        gap: 3px;

        .white {
            color: #fff !important;
        }
    }
}

.price-scheme-icon {
    color: #3d64d4;
    margin-right: 6px;

    svg {
        width: 21px;
        height: 21px;
    }
}

.pricing-scheme-option.ant-select-dropdown-menu-item-selected {
    i {
        svg {
            color: #fff;
        }
    }
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';