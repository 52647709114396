.invitation .ant-modal-header {
  background-color: #525a95;

}

.invitation {
  z-index: 3000;
}

.invitation .ant-modal-close-x {
  display: none;
}


.rr-menu-logo-container2 {
  height: 100px;
  min-height: 100px;
  line-height: 100px;
  padding-left: 24px;
  background-color: #525a95;
  overflow: hidden;
}

.rr-menu-logo-container2 svg:first-child {
  display: inline-block;
  height: 50px;
  vertical-align: middle;
  margin-top: -5px;
}

.rr-menu-logo-container2 svg:last-child {
  display: inline-block;
  height: 30.5px;
  vertical-align: middle;
  margin-left: 10px;
  margin-top: -2px;
}
//////////////
@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';