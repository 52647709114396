@import '~antd/lib/style/themes/default';

.ant-switch {
  min-width: 40px;
  background-color: #e2e5ec;
}

.ant-switch::after {
  box-shadow: none;
  background-color: #c4c5d6;
}

.ant-switch-loading-icon, .ant-switch::after {
  width: 16px;
  height: 16px;
  top: 2px;
  left: 3px;
}

.ant-switch-checked {
  background-color: #525a95;
}

.ant-switch-checked::after {
  margin-left: -3px;
  background-color: #ffffff;
  left: 100%;
}

.ant-switch .ant-click-animating-node {
  border: 0 solid #525a95;
}

.ant-switch-checked.ant-switch-loading .ant-switch-loading-icon {
  color: #525a95;
}

.ant-switch-loading, .ant-switch-disabled {
  opacity: 0.45;
  cursor: default;
}

.ant-switch-checked.ant-switch-loading, .ant-switch-checked.ant-switch-disabled {
  opacity: 0.7;
}

.ant-switch-loading *, .ant-switch-disabled * {
  cursor: default;
}

.ant-switch-loading::before, .ant-switch-disabled::before, .ant-switch-loading::after, .ant-switch-disabled::after {
  cursor: default;
}
