.control-popover-container {
    padding: 3px 0;

    .radio-button {
        display: block;
        height: 30px;
        line-height: 30px;

        &,
        * {
            cursor: pointer !important;
        }
    }

    .ant-checkbox-wrapper:not(:last-child) {
        margin-bottom: 8px;
    }
}

.control-popover-header {
    color: #383f77;
    font-weight: 700;
    margin-bottom: 10px;
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';