.rr-select-detailed {
    .ant-select-selection-selected-value {
        width: 100%;

        .rr-select-detailed-cell:not(:first-of-type) {
            display: none;
        }

        .rr-select-detailed-cell:first-of-type {
            width: 100% !important;
            flex-grow: 1;
        }
    }

    .ant-select-selection--multiple {
        .rr-select-detailed-cell:not(:first-of-type) {
            display: none;
        }
    }

    &.ant-select-open .ant-select-selection {
        border-color: #575962;
        box-shadow: none;
    }

    .ant-select-selection--multiple {
        height: auto !important;
    }
}

.ant-select-selection--multiple .rr-select-detailed-cell:first-of-type {
    width: auto !important;
}

.rr-select-detailed-line {
    display: flex;
    gap: 2px;
    align-items: center;
    width: 100%;
}

.rr-select-detailed-detail-button {
    margin-left: auto;
    height: 24px;
    line-height: 24px;

    &:hover span,
    &:focus span {
        text-decoration: underline;
    }

    .ant-select-selection-selected-value &,
    .ant-select-selection--multiple & {
        display: none;
    }
}

.rr-select-detailed-cell {
    overflow: hidden;
    text-overflow: ellipsis;
}

.ant-select-dropdown-menu-item-selected .ant-btn-link {
    color: white;
}

.rr-select-detailed-dropdown,
.rr-select-detailed-dropdown-with-drawer {
    z-index: 999;
    overflow: auto;
}

.rr-select-detailed-cell-secondary {
    color: #aaaaaa;
}
