// Лэйбл
.rr-dd-i-l {
    color: rgba(87, 89, 98, 0.9);
    font-size: 14px;
    margin-bottom: 6px;
}

// Значение, Пустое значение
.rr-dd-i-v,
.rr-dd-i-ev {
    font-size: 18px;
    word-break: break-word;
    //min-height: 27px; // Хз зачем это
}

// Значение
.rr-dd-i-v {
    color: #575962;
    font-weight: bold;
}

// Пустое значение
.rr-dd-i-ev {
    color: #aaaaaa;
    font-weight: 400;
}

// Хинт серый под значением
.rr-dd-i-h {
    color: #aaaaaa;
    font-size: 11px;
}

.rr-dd-i-v a {
    color: #525993;
}

.rr-dd-i-v a:hover {
    text-decoration: underline;
    color: rgba(82, 89, 147, 0.8);
}

// Комментарий
.rr-dd-i {
    .rr-dd-i-v.rr-dd-i-v-comment {
        font-size: 14px !important;
        font-weight: 500 !important;
        word-break: break-word !important;
        white-space: pre-wrap !important;
    }
}

.rr-dd-i {
    .rr-dd-i-v .rr-dd-i-v-sn-icon {
        font-size: 32px;
    }

    .rr-dd-i-v a:hover .rr-dd-i-v-sn-icon {
        opacity: 0.8;
    }

    .rr-dd-i-v .rr-dd-i-v-sn-icon-telegram {
        color: #26a4e5;
    }

    .rr-dd-i-v .rr-dd-i-v-sn-icon-vk {
        color: #0273f6;
    }

    .rr-dd-i-v .rr-dd-i-v-sn-icon-instagram {
        color: #dd2a7b;
    }

    .rr-dd-i-v .rr-dd-i-v-sn-icon-facebook {
        color: #0766ff;
    }

    .rr-dd-i-v .rr-dd-i-v-sn-icon-website {
        color: #25406a;
    }
}

// Список экземпляров
.rr-dd-i-v.rr-dd-i-v-instances-list {
    font-size: unset;
    margin-bottom: -10px;
}

// Номер телефона и E-Mail
.rr-dd-i-v.rr-dd-iv-phone a,
.rr-dd-i-v.rr-dd-iv-email a {
    display: flex;
    align-items: center;
}

.rr-dd-iv-phone-icon,
.rr-dd-iv-email-icon {
    opacity: 0;
    font-size: 24px;
    margin-left: 14px;
}

.rr-dd-i-v.rr-dd-iv-phone:hover .rr-dd-iv-phone-icon,
.rr-dd-i-v.rr-dd-iv-email:hover .rr-dd-iv-email-icon {
    opacity: 1;
}

//.rr-dd-i-v.rr-dd-i-v-boolean-false {
//    font-size: unset;
//    margin-bottom: -10px;
//}

// Это для подсветки значений - красный
.rr-dd-i-v-problem,
.rr-value.rr-dd-i-v-problem,
.rr-value.rr-dd-i-v-problem .rr-0-value {
    color: #f8516c;
}

// Это для подсветки значений - оранжевый
.rr-dd-i-v-warning,
.rr-value.rr-dd-i-v-warning,
.rr-value.rr-dd-i-v-warning .rr-0-value {
    color: #ffb822;
}

// Это для подсветки значений - зеленый
.rr-dd-i-v-success,
.rr-value.rr-dd-i-v-success,
.rr-value.rr-dd-i-v-success .rr-0-value {
    color: #34bfa3;
}

// Вертикальная группа
.rr-dd-g {
}

.rr-dd-b > .rr-dd-g > .rr-dd-g-r > div > .rr-dd-i {
    padding: 9px 0 9px 0;
}

.rr-dd-b > .rr-dd-g > .rr-dd-g-r:first-child > div > .rr-dd-i {
    padding-top: 0;
}

.rr-dd-b > .rr-dd-g > .rr-dd-g-r:last-child > div > .rr-dd-i {
    padding-bottom: 0;
    padding-right: 0;
}

.rr-dd-b > .rr-dd-g > .rr-dd-g-r > div > .rr-dd-i-p {
    padding: 9px 10px 9px 32px;
}

.rr-dd-b > .rr-dd-g > .rr-dd-g-r:first-child > div > .rr-dd-i-p {
    padding-top: 32px;
}

.rr-dd-b > .rr-dd-g > .rr-dd-g-r:last-child > div > .rr-dd-i-p {
    padding-bottom: 32px;
    //padding-right: 32px;
}

.rr-dd-b > .rr-dd-g > .rr-dd-g-r > div:last-child > .rr-dd-i-p {
    padding-right: 32px;
}

// Это блок
.rr-dd-b {
    //padding: 32px;
}

// Блок, Рамка снизу
.rr-dd-b.rr-dd-b-bb:not(:last-child) {
    border-bottom: 1px solid #eaeaea;
}

// Блок, Пэддинг
.rr-dd-b.rr-dd-b-p {
    //padding: 32px;

    &.rr-dd-b-p-s {
        padding: 18px 32px;
    }
}

.rr-dd-i-dark {
    background-color: #525a95;

    .rr-dd-i-ev {
        color: #c0c0c0;
    }
}

.rr-dd-i-grey {
    background-color: #aaa;

    .rr-dd-i-ev {
        color: #ffffff;
    }
}

.rr-dd-i-dark,
.rr-dd-i-grey {
    .rr-dd-i-l {
        color: #ffffff;
    }

    .rr-dd-i-v {
        color: #ffffff;
    }

    .rr-dd-i-h {
        color: #ffffff;
    }
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';