.rr-filters-selected-filter .ant-form-item-label label {
    font-weight: 600;
}

.rr-filters-selected-filter1 .ant-form-item-label label {
    font-weight: 600;
}

.rr-filters-selected-filter .ant-input:hover {
    border-right-width: 2px !important;
}

.rr-filters-selected-filter .ant-input,
.rr-filters-selected-filter .ant-input:hover {
    border: 2px solid #383f77;
    color: #383f77;
}

.rr-filters-selected-filter .ant-input.rr-input-gray,
.rr-filters-selected-filter .rr-input-gray > .ant-input {
    border: 2px solid #383f77;
}

.rr-filters-selected-filter .ant-input-affix-wrapper.rr-input-gray:hover .ant-input:not(.ant-input-disabled) {
    border: 2px solid #383f77;
}

.rr-filters-selected-filter .ant-form-item-children .ant-select-selection {
    border: 2px solid #383f77;
}

.rr-filters-selected-filter .ant-input.rr-input-gray:focus,
.rr-filters-selected-filter .rr-input-gray > .ant-input:focus {
    border: 2px solid #383f77;
    border-right-width: 2px !important;
}

.rr-filters-selected-filter .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
    border-right-width: 2px !important;
}

.rr-filters-selected-filter .rr-select-gray .ant-select-selection:hover {
    border: 2px solid #383f77;
}

.rr-filters-selected-filter .ant-select-selection:hover {
    border-right-width: 2px !important;
}

.rr-filters-selected-filter .rr-select-gray.ant-select-focused .ant-select-selection {
    border: 2px solid #383f77;
}

.rr-filters-selected-filter .rr-select-gray.ant-select-focused .ant-select-selection,
.rr-filters-selected-filter .ant-select-selection:focus {
    border-right-width: 2px !important;
}

.rr-filters-selected-filter .ant-calendar-picker:hover .ant-calendar-picker-input:not(.ant-input-disabled) {
    border: 2px solid #383f77;
}

.rr-filters-selected-filter .ant-calendar-picker-input.ant-input {
    border: 2px solid #383f77;
}

.rr-filters-selected-filter .ant-calendar-picker-input.ant-input:focus {
    border: 2px solid #383f77;
}

.rr-filters-selected-filter .ant-input:focus {
    border-right-width: 2px !important;
}

.rr-filters-selected-filter .ant-select {
    color: #383f77;
}

.rr-filters-selected-filter .ant-input.rr-input-gray:hover,
.rr-filters-selected-filter .rr-input-gray > .ant-input:hover {
    border-color: #383f77;
}

.rr-filters-selected-filter .rr-input-gray.ant-calendar-picker:hover .ant-calendar-picker-input:not(.ant-input-disabled) {
    border-color: #383f77;
}

.rr-filters-selected-filter .rr-operation-element-create-popover-param-changed > .ant-input.ant-calendar-picker-input {
    border: 2px solid #383f77;
}

.rr-filters-not-selected-filter .rr-input-gray .ant-input,
.rr-filters-not-selected-filter .rr-input-gray .ant-input:hover {
    border-width: 2px !important;
}

.rr-filters-selected-filter .ant-form-item-label label {
    letter-spacing: -.5px;
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';