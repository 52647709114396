.sidebar {
    overflow: auto;
    height: 100vh;
    position: fixed;
    left: 0;
    z-index: 10;
    background-color: #383f77;
}

.rr-app-menu-container .ant-menu {
    background: none;
    color: #ffffff;
}

.rr-app-menu-container .ant-menu .ant-menu-item > a {
    color: #ffffff;
}

.rr-app-menu-container .ant-menu-inline,
.rr-app-menu-container .ant-menu-vertical,
.rr-app-menu-container .ant-menu-vertical-left {
    border-right: none;
}

.rr-app-menu-container .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #525993;
    border-left: 7px solid #34bfa3;
}

.rr-app-menu-container .ant-menu-item {
    border-left: 7px solid transparent;
}

.rr-app-menu-container .ant-menu-item:hover,
.rr-app-menu-container .ant-menu-submenu-title:hover {
    background: lighten(#525993, 5%);
}

.rr-app-menu-container .ant-menu-item:active,
.rr-app-menu-container .ant-menu-submenu-title:active {
    background: lighten(#525993, 10%);
}

.rr-app-menu-container .ant-menu-item-selected {
    color: #ffffff;
}

.rr-app-menu-container .ant-menu-item:hover,
.rr-app-menu-container .ant-menu-item-active,
.rr-app-menu-container .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.rr-app-menu-container .ant-menu-submenu-active,
.rr-app-menu-container .ant-menu-submenu-title:hover {
    color: #ffffff;
}

.rr-app-menu-container .ant-menu-vertical .ant-menu-item::after,
.rr-app-menu-container .ant-menu-vertical-left .ant-menu-item::after,
.rr-app-menu-container .ant-menu-vertical-right .ant-menu-item::after,
.rr-app-menu-container .ant-menu-inline .ant-menu-item::after {
    border-right: none;
}

.rr-app-menu-container .ant-menu-inline .ant-menu-item,
.rr-app-menu-container .ant-menu-inline .ant-menu-submenu-title {
    width: calc(100%);
}

.rr-app-menu-container .ant-menu-item .anticon {
    position: relative;
    top: 4px;
}

.ant-menu-item .anticon,
.ant-menu-submenu-title .anticon {
    margin-right: 18px;
}

.rr-app-menu-container .ant-menu-vertical > .ant-menu-item,
.rr-app-menu-container .ant-menu-vertical-left > .ant-menu-item,
.rr-app-menu-container .ant-menu-vertical-right > .ant-menu-item,
.rr-app-menu-container .ant-menu-inline > .ant-menu-item,
.rr-app-menu-container .ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title,
.rr-app-menu-container .ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title,
.rr-app-menu-container .ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title,
.rr-app-menu-container .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
    height: 44px;
    line-height: 44px;
}

.rr-app-menu-container .ant-menu-vertical .ant-menu-item,
.rr-app-menu-container .ant-menu-vertical-left .ant-menu-item,
.rr-app-menu-container .ant-menu-vertical-right .ant-menu-item,
.rr-app-menu-container .ant-menu-inline .ant-menu-item,
.rr-app-menu-container .ant-menu-vertical .ant-menu-submenu-title,
.rr-app-menu-container .ant-menu-vertical-left .ant-menu-submenu-title,
.rr-app-menu-container .ant-menu-vertical-right .ant-menu-submenu-title,
.rr-app-menu-container .ant-menu-inline .ant-menu-submenu-title {
    height: 44px;
    line-height: 44px;
    margin-top: 0;
    margin-bottom: 0;
}

.rr-app-menu-container .ant-menu-vertical .ant-menu-item:not(:last-child),
.rr-app-menu-container .ant-menu-vertical-left .ant-menu-item:not(:last-child),
.rr-app-menu-container .ant-menu-vertical-right .ant-menu-item:not(:last-child),
.rr-app-menu-container .ant-menu-inline .ant-menu-item:not(:last-child) {
    margin-bottom: 0;
}

.rr-app-menu-container .ant-menu-inline-collapsed > .ant-menu-item,
.rr-app-menu-container .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item,
.rr-app-menu-container
    .ant-menu-inline-collapsed
    > .ant-menu-item-group
    > .ant-menu-item-group-list
    > .ant-menu-submenu
    > .ant-menu-submenu-title,
.rr-app-menu-container .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
    padding: 0 22px !important;
}

.rr-app-menu-container .ant-menu-item .anticon.rr-menu-icon {
    font-size: 22px;
}

.rr-app-menu-profile {
    background-color: #525a95;
    padding: 24px 8px;
    padding-left: 17px;
    color: #ffffff;
    display: flex;
    border-left: 7px solid #525993;
    transition: all 0.3s;
}

.rr-app-menu-profile-selected {
    background-color: #525993;
    border-left: 7px solid #34bfa3;
}

.rr-app-menu-profile-avatar {
    //align-self: center;
    white-space: nowrap;
}

.rr-app-menu-profile-avatar .ant-avatar {
    background-color: #00c5dc;
}

.rr-app-menu-profile-name {
    align-self: center;
    margin-left: 16px;
    min-width: 150px;
    max-width: 150px;
    display: block;
}

.rr-app-menu-collapsed .rr-app-menu-profile {
    padding-left: 24px;
}

.ant-tooltip .ant-badge-dot {
    display: none;
}

// Бэйджи
.rr-app-menu-container .ant-menu-inline .ant-badge-count {
    top: 22px;
    right: 21px;
}

.rr-app-menu-container .ant-menu-inline-collapsed .ant-badge-dot {
    top: 21px;
    right: -10px;
}

.rr-app-menu-container .ant-menu-inline .ant-badge {
    display: block;
    line-height: 44px;
    height: 44px;
}

////////////// Логотип
.rr-menu-logo-container {
    height: 70px;
    min-height: 70px;
    line-height: 70px;
    padding-left: 24px;
    background-color: #525a95;
    overflow: hidden;
    user-select: none;
}

.rr-menu-logo-container svg:first-child {
    display: inline-block;
    height: 30px;
    vertical-align: middle;
    margin-top: -5px;
}

.rr-menu-logo-container svg:last-child {
    display: inline-block;
    height: 20.5px;
    vertical-align: middle;
    margin-left: 10px;
    margin-top: -2px;
}
//////////////

.ant-layout-sider-children {
    display: flex;
    flex-shrink: 0;
}

.rr-app-menu-container {
    //display: flex;
    //flex-direction: column;
    width: 100%;
}

.rr-app-menu-items1 {
    -webkit-box-flex: 1;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0px;
}

.rr-app-menu-items2 {
    -webkit-box-flex: 0;
    flex-grow: 0;
}

//////////////////////////

.ant-layout-sider {
    z-index: 100;
}

.ant-layout-sider:hover button {
    opacity: 1;
}

body.rr-is-touch-device .ant-layout-sider button {
    opacity: 1;
}

.ant-layout-sider button {
    opacity: 0;
}

.ant-layout-sider-collapsed .rr-app-menu-scrollContainer {
    sup.ant-scroll-number {
        pointer-events: none;
    }
}

/////
/////
.rr-app-menu-container .ant-menu-inline .ant-menu-item.rr-menu-item-operation,
.rr-app-menu-container .ant-menu-inline-collapsed .ant-menu-item.rr-menu-item-operation {
    height: 62px;
    line-height: 62px;
    padding-left: 13px !important;
}

.rr-app-menu-container .ant-menu-inline-collapsed .ant-menu-item.rr-menu-item-operation {
    padding-left: 17px !important;
}

.rr-app-menu-container .ant-menu-inline .ant-menu-item.rr-menu-item-operation:not(:last-child),
.rr-app-menu-container .ant-menu-inline-collapsed .ant-menu-item.rr-menu-item-operation:not(:last-child) {
    margin-top: 20px;
    margin-bottom: 9px;
}

.rr-app-menu-container .ant-menu-inline .ant-menu-item.rr-menu-item-operation .ant-badge,
.rr-app-menu-container .ant-menu-inline-collapsed .ant-menu-item.rr-menu-item-operation .ant-badge {
    line-height: 62px;
    height: 62px;
}

.rr-app-menu-container .ant-menu-inline .ant-menu-item.rr-menu-item-operation .ant-badge-count,
.rr-app-menu-container .ant-menu-inline-collapsed .ant-menu-item.rr-menu-item-operation .ant-badge-count {
    top: 31px;
    background-color: transparent;
    box-shadow: 0 0 0 1px #ffffff inset;
}

.rr-app-menu-container .ant-menu-inline .ant-menu-item.rr-menu-item-operation .anticon.rr-menu-icon,
.rr-app-menu-container .ant-menu-inline-collapsed .ant-menu-item.rr-menu-item-operation .anticon.rr-menu-icon {
    font-size: 32px;
    top: 7px;
    margin-right: 10px;
}

.rr-app-menu-container .ant-menu-inline-collapsed .ant-menu-item.rr-menu-item-operation .ant-badge-dot {
    top: 31px;
    right: 5px;
    background-color: #ffffff;
}

.rr-app-menu-container .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected.ant-menu-item.rr-menu-item-operation::before,
.rr-app-menu-container .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected.ant-menu-item.rr-menu-item-operation::before {
    border-left: 3px solid #383e75;
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
}

.rr-app-menu-container .ant-menu-inline .ant-menu-item.rr-menu-item-operation .ant-badge > i + span {
    width: 126px;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    position: absolute;
}

.rr-app-menu-container .ant-badge-count:hover {
    opacity: 0.75;
}

.rr-app-menu-container .ant-menu-inline-collapsed .ant-badge-dot:hover {
    opacity: 0.75;
}

.rr-app-menu-container .ant-menu-inline .ant-menu-item.rr-menu-item-operation .ant-badge-count:hover {
    opacity: 1;
}

.rr-app-menu-scrollContainer {
    position: absolute;
    top: 70px;
    bottom: 0;
    overflow-y: hidden;
    overflow-x: hidden;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.rr-app-menu-scrollContainer:hover {
    overflow-y: auto;
}

.rr-app-menu-scrollContainer > div {
    width: 240px;
}

.rr-app-menu-scrollContainer::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

.rr-app-menu-scrollContainer::-webkit-scrollbar-track {
    background-color: rgb(56, 63, 119);
}

.rr-app-menu-scrollContainer {
    &::-webkit-scrollbar {
        position: absolute;
        width: 12px !important;
        height: 12px !important;
    }
    &::-webkit-scrollbar-track {
        background-color: inherit !important;
    }
    &::-webkit-scrollbar-thumb {
        background-color: rgba(246, 254, 255, 0.4) !important;
        border: 4px solid #383f77 !important;
    }
}

.rr-app-menu-operation-popover .ant-popover-inner-content {
    padding: 6px 0;
}

/////
.rr-app-menu-operation-popover-content .ant-btn {
    border-width: 0;
    border-radius: 0;
    text-align: left;
    padding: 0 25px;
    height: 45px;
    color: #575962;
    animation-duration: 0s !important;
}

.rr-app-menu-operation-popover-content .ant-btn > .anticon {
    font-size: 22px;
    position: relative;
    top: 4px;
}

.rr-app-menu-operation-popover-content .ant-btn:hover {
    background-color: #f4f5f8;
    color: #525a95;
}

.rr-app-menu-operation-popover-content .ant-btn > .anticon + span,
.rr-app-menu-operation-popover-content .ant-btn > span + .anticon {
    margin-left: 12px;
}

.rr-app-menu-operation-popover-content .ant-btn[disabled],
.rr-app-menu-operation-popover-content .ant-btn[disabled]:hover {
    color: #c4c5d5;
    background: transparent;
}

.rr-app-menu-operation-popover-content .ant-btn[disabled] > .anticon {
    opacity: 0.25;
}

.rr-app-menu-operation-popover-content .ant-btn[ant-click-animating-without-extra-node]:after {
    animation: none;
}

.rr-app-menu-operation-popover.ant-popover-placement-rightTop > .ant-popover-content > .ant-popover-arrow {
    top: 25px;
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';