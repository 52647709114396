.rr-instance-info-popover {
  min-width: 680px !important;
}

.tab-popap-instance  .ant-tabs-tab, .tab-popap-instance  .ant-tabs-tab:hover {
  color: #575962;
  font-size: 16px;
}

.tab-popap-instance .ant-tabs-nav-wrap {
  border-bottom: 1px solid #ccc ;
}

.tab-popap-instance  .ant-tabs-nav.ant-tabs-nav-animated {
  padding-left: 32px;
}

.tab-popap-instance .ant-tabs-nav .ant-tabs-tab-active,
.tab-popap-instance .ant-tabs-nav .ant-tabs-tab-active:hover {
  background-color: white ;
  color: #575962;
  font-size: 16px;

  padding-top: 16px;
  padding-bottom: 16px;
}

.tab-popap-instance .ant-tabs-nav .ant-tabs-tab {
  position: inherit;
}

.ant-tabs-ink-bar {
  background-color:  #525a95;
}
@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';