.ant-upload-list-item.ant-upload-list-item-done.ant-upload-list-item-list-type-picture {
  border-radius: 8px;
}

.ant-upload-list-item.ant-upload-list-item-error.ant-upload-list-item-list-type-picture {
  border: 1px solid #f4516c;
  border-radius: 8px;
}

.ant-upload-list-item.ant-upload-list-item-error.ant-upload-list-item-list-type-picture i {
  background-color: #f4516c;
}

.ant-upload-list-item.ant-upload-list-item-error.ant-upload-list-item-list-type-picture .ant-upload-list-item-info .ant-upload-list-item-image {
  line-height: 20px;
  padding-top: 48px;
  font-size: 14px;
  font-weight: 600;
  color: #f4516c;
  margin-left: -20px;
  white-space: pre;
  background-color: #eaeaea;
  margin-right: -20px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ant-upload-list-item.ant-upload-list-item-error.ant-upload-list-item-list-type-picture .ant-upload-list-item-info {
  background-color: #eaeaea;
}

.ant-upload-list-item.ant-upload-list-item-error.ant-upload-list-item-list-type-picture:hover {
  background-color: #eaeaea;
}

.ant-upload-list-item.ant-upload-list-item-error.ant-upload-list-item-list-type-picture .ant-upload-list-item-info a {
  cursor: default;
}

.ant-upload-list-item.ant-upload-list-item-error.ant-upload-list-item-list-type-picture .ant-upload-list-item-info .ant-upload-list-item-card-actions.picture {
  cursor: pointer;
}

.ant-upload-list-item.ant-upload-list-item-error.ant-upload-list-item-list-type-picture .ant-upload-list-item-info .ant-upload-list-item-image:before {
  content: url(./image.svg);
  display: flex;
  font-size: 14px;
  width: 40px;
  height: 40px;
  font-weight: 600;
  margin-left: 105px;
  padding-top: -20px;
  padding-bottom: 54px;
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';