.rr-actions-popover-wrapper {
    height: 100%;
}

.rt-td-sticky > div:has(.rr-actions-popover-wrapper) {
    height: 100%;

    & > div {
        height: 100%;
    }

    .rr-grid-table-actions-icon {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
            opacity: 0.8;
        }
    }
}

.rt-td-sticky .rr-iconsGroup-action {
    &:hover {
        opacity: 0.8;
    }
}

.rt-td-sticky div:has(> .rr-iconsGroupWrapper) {
    height: 100%;

    i {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.rt-td-sticky div:has(> .rr-grid-table-otherActions-icon),
.rt-td-sticky div:has(> .rr-grid-table-actions-icon) {
    height: 100% !important;

    i {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';