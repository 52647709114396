.product-composition-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 0;
    padding-top: 0;

    .title-container {
        display: flex;
        align-items: center;
        padding: 13px;
        color: #575962;
        font-size: 21px;
        font-weight: 700;
    }

    .button-container {
        display: flex;
        justify-content: space-between;
    }
}
@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';