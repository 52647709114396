@import '../../../../../assets/styles/vars';

.calendar-line-item {
    border-radius: 2px;
    display: flex;
    align-items: center;

    &.item {
        z-index: auto !important;
    }
    &.group {
        z-index: 10 !important;
    }
    &.left-boundary,
    &.right-boundary {
        &::before,
        &::after {
            top: 0;
            display: block;
            position: absolute;
            content: '';
            width: 25px;
            height: 100%;
            z-index: 1;
            pointer-events: none;
        }
    }
    &.left-boundary {
        &::before {
            background: linear-gradient(90deg, #fff, hsla(0, 0%, 100%, 0));
            left: 0;
        }
    }
    &.right-boundary {
        &::after {
            background: linear-gradient(90deg, hsla(0, 0%, 100%, 0), #fff);
            right: 0;
        }
    }
}

.calendar-line-border {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0 !important;
}

.calendar-item-icon-container {
    display: flex;
    align-items: center;
    position: absolute;
    margin-left: -32px;
    color: #575962;
    margin-top: 2px;

    .calendar-item-icon {
        font-size: 24px;
        height: 100%;
    }

    &.compact {
        margin-left: -26px;
        .calendar-item-icon {
            font-size: 18px;
        }
    }
}

.calendar-item-content {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0 !important;
    overflow: initial !important;
}

.line-main-block-container {
    display: flex;
    align-items: center;
    white-space: nowrap;
    height: 100%;

    .backward {
        position: absolute;
        color: @rr-colors-grey-1;
    }

    .line-main-block {
        display: flex;
        align-items: center;

        .line-main-block-content {
            display: flex;
            align-items: center;
            width: calc(100% - 2px);
            font-weight: 600;
            user-select: none;
        }
    }

    .giveawayBackward {
        .line-main-block-content {
            color: #fff;
            text-shadow: rgba(255, 255, 255, 0.8) 0 0 1px;
        }
    }
}

.calendar-item-kit-icon {
    font-size: 20px;
    margin-right: 3px;
    margin-left: -3px;
    margin-top: -2px;

    &.compact {
        font-size: 15px;
    }
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';