.container {
    margin: 0 8px;

    .description-row {
        padding: 32px 24px;
    }

    .right-block {
        & > * {
            background: #fff;
        }

        .prime-rate-col {
            margin: 0 -8px;
            border-bottom: 1px solid #eaeaea;

            @media only screen and (max-width: 1600px) {
                border-top: 1px solid #eaeaea;
            }

            .prime-rate-row {
                padding: 12px 32px;
                font-size: 21px;
            }
        }
    }


    .attachments-col {
        margin-left: -8px;
        margin-right: -8px;
    }

    .no-finances {
        @media only screen and (max-width: 1600px) {
            border-top: 1px solid #eaeaea;
        }
    }
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';