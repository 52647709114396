
.ant-pagination {
  font-feature-settings: normal;
  font-variant: normal;
}

.ant-pagination-item {
  font-family: inherit;
  border: 1px solid #fff;
  background-color: #fff;
  border-radius: 18px;
  margin-right: 5px;
}

.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  border-radius: 18px;
  background-color: #ebedf2;
  border: 1px solid #ebedf2;
  color: #B5B1DD;
}

.ant-pagination-item-active {
  font-weight: 400;
  background-color: #383f77;
  border-color: #383f77;
}

.ant-pagination-item-active a {
  color: #fff;
}

.ant-pagination-item:focus {
  border-color: #fff;
}

.ant-pagination-item:hover {
  border-color: #383f77;
}

.ant-pagination-item:focus a, .ant-pagination-item:hover a {
  color: #383f77;
}

.ant-pagination-item-active:focus, .ant-pagination-item-active:hover {
  border-color: #383f77;
}

.ant-pagination-item-active:focus a, .ant-pagination-item-active:hover a {
  color: #fff;
}

.ant-pagination-prev:focus .ant-pagination-item-link,
.ant-pagination-next:focus .ant-pagination-item-link,
.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link {
  border-color: #f4f5f8;
  color: #B5B1DD;
}

.ant-pagination-disabled a,
.ant-pagination-disabled:hover a,
.ant-pagination-disabled:focus a,
.ant-pagination-disabled .ant-pagination-item-link,
.ant-pagination-disabled:hover .ant-pagination-item-link,
.ant-pagination-disabled:focus .ant-pagination-item-link {
  color: #DFE2EA;
  border-color: #f4f5f8;
  background-color: #f4f5f8;
  cursor: default;
}

.ant-pagination-prev, .ant-pagination-jump-prev, .ant-pagination-jump-next {
  margin-right: 5px;
}

.ant-pagination-prev .ant-pagination-item-link .anticon {
  font-size: 15px;
}

.ant-pagination-jump-prev:focus .ant-pagination-item-ellipsis, .ant-pagination-jump-next:focus .ant-pagination-item-ellipsis, .ant-pagination-jump-prev:hover .ant-pagination-item-ellipsis, .ant-pagination-jump-next:hover .ant-pagination-item-ellipsis {
  opacity: .8;
}
