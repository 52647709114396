@import '../../../../assets/styles/vars';

.rr-page-product h3 {
    font-size: 24px;
    font-weight: bold;
    color: #575962;
    line-height: 29px;
}

.rr-vvv {
    border-radius: 16px;
    line-height: 30px;
    height: 30px;
    font-size: 14px;
    color: #ffffff;
    display: inline-block;
    padding: 0px 4px;
    min-width: 30px;
    text-align: center;
    margin-right: 9px;
    font-weight: 500;
    cursor: pointer;
}

.rr-vvv:last-child {
    margin-right: 0px;
}

.rr-icon {
    margin-right: 5px;
}

.rr-page-product .product-info-block .rr-value {
    font-size: 18px;
    font-weight: bold;
}

.rr-product-page__main-image {
    max-width: 100%;
    max-height: 400px;
    margin: 0;
    margin-bottom: 32px;
}

.rr-page-renter h3 {
    font-size: 24px;
    font-weight: bold;
    color: #575962;
    line-height: 29px;
}

.rr-product-page__main-left-col {
    border-right: 1px solid #eaeaea;
}

.product-info-advanced-setting {
    border-top: 1px solid #eaeaea;
}

////// Блок с основными характеристиками
.rr-product-page__main-params-block-1 {
    padding: 32px 32px 0;
}

.rr-product-page__main-params-block-2 {
    padding: 18px 32px 32px;
}

////// Блок с вычисляемыми показателями
.rr-product-page__rent-indicators-block {
    padding: 32px 20px 32px 32px;
}

.rr-product-page-phone-mode .rr-product-page__rent-indicators-block {
    border-top: 1px solid #eaeaea;
}

.rr-product-page-phone-mode .rr-abstract-entity-info-comment {
    border-top: 1px solid #eaeaea;
}

////// Блок с описанием
.rr-product-page__description-block {
    padding: 32px;
    border-top: 1px solid #eaeaea;
}

@media (max-width: 1199px) {
    .rr-product-page__description-block {
        border-bottom: 1px solid #eaeaea;
    }
}

.rr-product-page__description-block-item > div:first-child {
    margin-bottom: 6px;
}

.rr-product-page__description-block-item > div:last-child {
}

////// Блок с картинками
.rr-product-page__images-block {
    border-bottom: 1px solid #eaeaea;
    padding: 32px;
}

.rr-product-page-phone-mode .rr-product-page__images-block {
    height: unset;
}

.rr-product-page-phone-mode .rr-product-page__main-image {
    position: unset;
    transform: unset;
    max-height: 500px;
    max-width: 100%;
}

////// Блок с индикаторами
.rr-product-page__main-indicators-block .ant-card-body {
    padding: 32px;
}

.rr-product-page__main-indicators-block-item:not(:last-child) {
    margin-bottom: 26px;
}

.rr-product-page-phone-mode .rr-product-page__main-indicators-block-item {
    margin-bottom: 0;
}

.rr-product-page__main-indicators-block-item:nth-child(1) > div:last-child i {
    color: #525993;
}

.rr-product-page__main-indicators-block-item:nth-child(2) > div:last-child i {
    color: #ffb822;
}

.rr-product-page__main-indicators-block-item > div:last-child {
    margin-top: 6px;
}

.rr-product-page__main-indicators-block-item > div:last-child > i {
    font-size: 30px;
    color: #00ff00;
}

.rr-product-page__main-indicators-block-item > div:last-child > i + span {
    font-size: 24px;
    font-weight: 600;
    margin-left: 15px;
}

.rr-product-page__main-indicators-block-item:nth-child(3) > div:last-child {
    margin-top: 6px + 5px;
}

////// Блок с деньгами
.rr-product-page__price-block.ant-card-bordered {
    border-top: 1px solid #eaeaea;
}

.rr-product-page__price-block .ant-card-body {
    padding: 32px;
}

.rr-product-page__price-block-item:not(:last-child) {
    margin-bottom: 16px;
}

.rr-product-page-phone-mode .rr-product-page__price-block-item {
    margin-bottom: 0;
}

.rr-product-page__price-block-item > div:first-child {
    margin-bottom: 9px;
}

.rr-product-page__price-block-item > div:last-child {
    font-size: 24px;
    font-weight: 700;
}

// Ценовые схемы
.rr-product-page__pricing-scheme-block {
}

.rr-product-page__pricing-scheme-block > div:first-child {
    margin-bottom: 9px;
}

.rr-product-page__pricing-scheme-block > div:last-child {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.rr-product-page__pricing-scheme-block > div:last-child:hover {
    opacity: 0.8;
}

.rr-product-page__pricing-scheme-block > div:last-child > div:first-child {
    color: #3d64d4;
    font-size: 22px;
}

.rr-product-page__pricing-scheme-block > div:last-child > div:last-child {
    color: #525993;
    padding-left: 10px;
    font-weight: 600;
    word-break: break-word;
}

//////// Блок с файлами

.rr-product-page__files-block.ant-card-bordered {
    margin-top: 30px;
}

.rr-product-page__files-block .ant-card-body {
    padding: 32px;
}

.rr-product-page__files-block-item > div:first-child {
    margin-bottom: 6px;
}

.rr-product-page__files-block-item > div:last-child {
    font-size: 18px;
    font-weight: 700;
}

//////// Блок с картой
.rr-product-page__map-block.ant-card-bordered {
    margin-top: 30px;
}

//////// Блок с "Длительность смены"
.rr-product-page__shiftLengthInMinutes-block/*.ant-card-bordered*/ {
    margin-top: 30px;
}

.rr-product-page__shiftLengthInMinutes-block .ant-card-body {
    padding-right: 16px;
}

.rr-product-page__shiftLengthInMinutes-block-item {
}

//////// Блок с "Резерв времени между обязательствами"
.rr-product-page__requiredTimeIndentBetweenElementsInMinutes-block.ant-card-bordered {
    border-top: 1px solid #eaeaea;
}

.rr-product-page__requiredTimeIndentBetweenElementsInMinutes-block-item {
}

.rr-product-page__shiftLengthInMinutes-block-item > div:nth-child(1),
.rr-product-page__requiredTimeIndentBetweenElementsInMinutes-block-item > div:nth-child(1) {
    margin-bottom: 11px;
}

.rr-product-page__shiftLengthInMinutes-block-item > div:nth-child(2),
.rr-product-page__requiredTimeIndentBetweenElementsInMinutes-block-item > div:nth-child(2) {
    margin-bottom: 2px;
    font-size: 18px;
    font-weight: 700;
}

.rr-product-page__shiftLengthInMinutes-block-item > div:nth-child(3),
.rr-product-page__requiredTimeIndentBetweenElementsInMinutes-block-item > div:nth-child(3) {
    font-size: 11px;
    color: #aaaaaa;
}

@media (max-width: 1199px) {
    .rr-product-page__main-left-col {
        border-right: none;
    }
}

.rr-product-page__main-params-show-all,
.rr-product-page__main-params-show-all:hover {
    text-decoration: underline;
    color: #525993;
}

.product-page-description-link-icon {
    font-size: 30px;
}

.product-page-description-link {
    display: flex;
    cursor: pointer;
}

.product-page-description-link:hover {
    text-decoration: underline;
}

.product-page-description-link-span {
    font-size: 24px;
    font-weight: 600;
    margin-left: 4px;
    margin-top: -2px;
    display: block;
    color: #575962;
}

.button-three-points {
    min-width: 60px !important;
}

.rr-product-page__main-indicators-block-item1-container-offset {
    margin-bottom: 17px;
}

.rr-product-page__main-indicators-block-item1 {
    display: flex;
    align-items: center;
}

.rr-product-page__main-indicators-block-item1-label {
    flex-grow: 1;
}

.rr-product-page__main-indicators-block-item1-value {
    font-size: 18px;
    line-height: 1;
    font-weight: bold;
    margin-left: 10px;
    cursor: pointer;
}

.rr-product-page__main-indicators-block-item1-icon {
    font-size: 22px;
}

.rr-product-page__main-indicators-block-item1-icon-empty {
    color: #abacb0;
}

.rr-product-page__main-indicators-block-item1-icon-1 {
    color: @rr-color-rent;
}

.rr-product-page__main-indicators-block-item1-icon-2 {
    color: @rr-color-subrent;
}

.rr-product-page__main-indicators-block-item1-icon-3 {
    color: @rr-color-underMaintance;
}

.rr-product-page__main-indicators-block-item1-icon-4 {
    color: @rr-color-available;
}

.rr-product-page__main-indicators-block-item1-icon-5 {
    color: @rr-color-rent;
    font-size: 24px;
}

.rr-product-page__main-indicators-block-item1-schedule {
    width: 100%;
    text-align: right;
    font-size: 11px;
    color: #aaaaaa;
    font-weight: 400;

    span {
        font-weight: 700;
        color: #fc8c2f;
    }
}

.rr-product-page__main-indicators-link {
    :hover {
        opacity: 0.8;
        cursor: pointer;
    }
}

.change-custom-fields-button {
    top: 3px !important;

    svg {
        color: #8b5cb1;
    }
}

.edit-custom-fields-button {
    svg {
        color: @rr-colors-purple-4;
    }
}

@media (max-width: 1400px), (min-width: 1600px) and (max-width: 1800px) {
    .rr-product-page__main-indicators-block .rr-product-page__main-indicators-block-item1 {
        display: block;
    }

    .rr-product-page__main-indicators-block-item1-schedule {
        text-align: left;
    }

    .rr-product-page__main-indicators-block .rr-product-page__main-indicators-block-item1-label {
        margin-bottom: 6px;
    }
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';