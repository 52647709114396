.contact-drawer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.contact-title-block {
    display: flex;
    justify-items: center;
    align-items: center;

    .title {
        padding: 4px 0;
        line-height: normal;
        margin-right: 24px;
        margin-left: 6px;
    }

    .title-icon {
        height: 33px;

        svg {
            height: 33px;
            width: 33px;
        }
    }
}

.contact-main-block {
    display: flex;
    gap: 24px;

    .items-gap {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';