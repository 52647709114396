@import '../../../../CalendarLine';

.calendar-line-tail {
    &,
    * {
        height: 100%;
    }
    .boundaries {
        position: relative;
        .left-boundary,
        .right-boundary {
            top: 0;
            display: block;
            position: absolute;
            content: '';
            width: 25px;
            height: 100%;
            z-index: 1;
            pointer-events: none;
        }
        .left-boundary {
            background: linear-gradient(90deg, #fff, hsla(0, 0%, 100%, 0));
            left: 0;
        }
        .right-boundary {
            background: linear-gradient(90deg, hsla(0, 0%, 100%, 0), #fff);
            right: 0;
        }
    }
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';