.header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left-side {
        display: flex;
        align-items: center;
        padding: 13px;
        color: #575962;
        font-size: 21px;
        font-weight: 700;

        .icon {
            color: #979cbf;
        }
    }

    .right-side {
        .header-button {
            margin-right: 16px;
            display: flex;
            align-items: center;
        }
    }
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';