.rr-actions-popover {
    width: 285px;
}

.rr-actions-popover.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow,
.rr-actions-popover.ant-popover-placement-bottomLeft > .ant-popover-content > .ant-popover-arrow,
.rr-actions-popover.ant-popover-placement-bottomRight > .ant-popover-content > .ant-popover-arrow {
    border-top-color: #525a95;
    border-left-color: #525a95;
}

.rr-actions-popover-header {
    min-height: 7px;
    border-radius: 3px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    background-color: #525a95;
}

.rr-actions-popover-header > div {
    min-height: 54px;
    color: #fff;
    padding: 0px 16px;
    font-weight: 600;
    user-select: none;
    display: flex;
    align-items: center;
}

.rr-actions-popover-content .ant-btn {
    border-width: 0;
    border-radius: 0;
    text-align: left;
    padding: 0 20px;
    height: 45px;
    color: #575962;
    animation-duration: 0s !important;

    display: inline-flex;
    align-items: center;
}

.rr-actions-popover-content .ant-btn:not([disabled]):hover {
    background-color: #f4f5f8;
    color: #525a95;
}

.rr-actions-popover-content .ant-btn:not([disabled]):hover .anticon {
    opacity: .8;
}

.rr-actions-popover-content .ant-btn[disabled] {
    opacity: .5;
    color: #575962;
    background-color: unset;
}

.rr-actions-popover-content .ant-btn[disabled] .anticon {
    color: #575962 !important;
}

.rr-actions-popover-content .ant-btn .anticon {
    line-height: 0;
    font-size: 21px;
}

.rr-actions-popover-content .ant-btn > .anticon + span {
    margin-left: 22px;
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';