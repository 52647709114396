//TODO Тут 100% что-то не используется, тут хрень какая-то.

.rr-product-instance-accounting-div {
  flex: 130 0 auto;
  margin: 0px auto;
  width: 160px;
  max-width: 160px;
}

.rr-product-instance-accounting {
  display: inline-block;
  font-size: 11px;
  font-weight: 500;
  text-align: center;
  color: #fff;
  border-radius: 18px;
  padding: 2px 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  vertical-align: top;
  white-space: nowrap;
  width: 100%;
}

.rr-card.product-page-instances-header {
  border-bottom: 1px solid #cccccc;
}

.product-page-instances-header-icon {
  margin-right: 10px;
  font-size: 25px;
  margin-top: 5px;
  color: #979cbf;
}

.rr-product-instance-accounting-color-NEW {
  background-color: #04B0F1
}

.rr-product-instance-accounting-color-ACTIVE {
  background-color: #34bfa3;
 }

.rr-product-instance-accounting-color-TEMPORARY_WITHDRAWN{
  background-color: #f25572
}
.rr-product-instance-accounting-color-UNDER_MAINTENANCE{
  //background-color: #08004a
  background-color: #525993
}
.rr-product-instance-accounting-color-DECOMMISSIONED{
   background-color: #a0a0a0
}

.rr-product-instance-available {
  display: flex;
  justify-content: center;
  width: 100%;
}

.rr-product-instance-available-circle {
  width: 10px;
  height: 10px;
  background-color: #34BFA3;
  border-radius: 5px;
}

.rr-product-instance-available-occupied-circle {
  width: 10px;
  height: 10px;
  //background-color: #a0a0a0;
  background-color: #fc8c2f;
  border-radius: 5px;
}

.rr-product-instance-available-unavailable-circle {
  width: 10px;
  height: 10px;
  background-color: #a0a0a0;
  border-radius: 5px;
}


.rr-product-instance-available-shortage-circle {
  width: 10px;
  height: 10px;
  background-color: rgb(244, 81, 108);
  border-radius: 5px;
}

.rr-product-instance-unavailable {
  color: rgb(87, 89, 98);
  opacity: 0.4;
}


.rr-product-instance-circle-DECOMMISSIONED {
  width: 10px;
  height: 10px;
  background-color: #a0a0a0;
  border-radius: 5px;
}

.rr-product-instance-circle-UNDERMAINTENANCE {
  width: 10px;
  height: 10px;
  background-color: #525993;
  border-radius: 5px;
}

.rr-product-instance-circle-ACTIVE {
  width: 10px;
  height: 10px;
  background-color: #34bfa3;
  border-radius: 5px;
}

.rr-product-instance-circle-NEW {
  width: 10px;
  height: 10px;
  background-color: #04B0F1;
  border-radius: 5px;
}

.rr-product-instance-circle-SUPPLIER_ACTIVE {
  width: 10px;
  height: 10px;
  background-color: #FFFFFF;
  border-radius: 5px;
  border: 2px solid #fc8c2f;
}

.rr-product-instance-circle-SUPPLIER_DECOMMISSIONED {
  width: 10px;
  height: 10px;
  background-color: #FFFFFF;
  border-radius: 5px;
  border: 2px solid #a0a0a0;
}


.column-name-or-code {
  font-weight: 600;
  //color: #343f9a;
  text-align: left;
}


.rr-select-decommissioned {
  margin-left: auto;
}

.actions-UNDER_MAINTENANCE {
  color: #fff;
  border: 0px;
}

.actions-UNDER_MAINTENANCE:hover {
  color: #fff;
  border: 0px;
  background-color: #525993;
}

.rr-grid-instanceCount-cell-hover {
  cursor: pointer;
}

.rr-grid-instanceCount-cell-hover:hover {
  text-decoration: underline;
}
@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';