.rr-added-instances-block {
  display: inline-flex;
  line-height: 1;
  align-items: center;
  user-select: none;
  font-weight: 600;
  width: unset !important;
}

.rr-added-instances-block__whitout-added-instances {
  color: #abacb0;
  font-weight: 400;
}

.rr-added-instances-block__minus-button {
  font-size: 20px;
  color: #575962;
}

.rr-added-instances-block__minus-button:hover:not(.rr-added-instances-block__minus-button--disabled) {
  opacity: .7;
}

.rr-added-instances-block__minus-button--disabled {
  color: #e4e4e6;
}

.rr-added-instances-block__plus-button {
  font-size: 20px;
  color: #575962;
}

.rr-added-instances-block__plus-button:hover:not(.rr-added-instances-block__plus-button--disabled) {
  opacity: .7;
}

.rr-added-instances-block__plus-button--disabled {
  color: #e4e4e6 !important;
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';