.display-type-button-container {
    display: flex;
    justify-content: right;
    position: absolute;
    width: 190px;
    padding-right: 36px;

    .anticon {
        font-size: 22px !important;
        margin-right: -4px !important;
    }
}

.calendar-filters-container {
    display: flex;
    align-items: start;

    & > div {
        flex-grow: 1;
    }
    &.offset-left > div {
        margin-left: 24px;
        padding-top: 9px;
    }

    & > span {
        font-size: 17px;
        font-weight: 700;
        text-align: right;
        padding-right: 36px;
    }
}

.calendar-timeline {
    &.accessibility {
        margin-top: 24px !important;
    }
    &.not-found {
        margin-bottom: 48px;

        .rct-vertical-lines {
            display: none;
        }

        .no-items-block,
        .no-product-block {
            position: absolute;
            margin-top: 0;
            transform: translate(-50%, 0);
        }
    }
}

.summary-schedule-calendar {
    position: relative;
}

.summary-schedule-calendar-resize-handle {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 4px;
    z-index: 2;
    border-radius: 4px;
    cursor: col-resize;
    transition: background-color 0.3s ease;

    &:hover,
    &-resizing {
        background-color: #bccced;
    }
}

.calendar-resizing {
    * {
        user-select: none;
    }

    .rct-scroll {
        pointer-events: none;
    }
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';