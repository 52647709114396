@import '../../../../../../../../../assets/styles/vars';

@line-normal-height: 26px;
@line-compact-height: 20px;

.calendar-group-line {
    display: flex;
    align-items: center;
    white-space: nowrap;
    font-size: 20px;
    color: #383f77;
    margin-top: 0;

    .icon {
        display: flex;
        align-items: center;
        height: @line-normal-height;
        &.compact {
            height: @line-compact-height;
            font-size: 16px;
            padding-top: 1px;
        }
    }

    .title {
        color: @rr-colors-purple-1;
        margin-left: 12px;
        font-size: 14px;
        font-weight: 600;
        height: @line-normal-height;

        &.hovered,
        &.link {
            &:hover {
                opacity: 0.8;
                text-decoration: underline;
            }
        }

        &.compact {
            height: @line-compact-height;
            font-size: 11px;
            margin-left: 8px;
        }

        &.subtitle {
            font-weight: 400;
        }
    }
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';