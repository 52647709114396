.rr-businessAccount-delete-modal .rr-form-group {
  padding: 0 30px 0;
}

.rr-businessAccount-delete-modal .ant-modal-footer .ant-btn:first-child {
  height: 38px;
  line-height: 36px;
}

.rr-businessAccount-delete-modal .ant-modal-footer > div {
  display: flex;
  flex-direction: row-reverse;
}

.rr-businessAccount-delete-modal .ant-modal-footer > div > .ant-btn:nth-child(2) {
  margin-right: 8px;
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';