@import '../../../assets/styles/vars.less';

.rr-base-categories-select_dropdown-nested {
    max-height: 35vh !important;
}

.category-select-block {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 20px 24px;
    border-bottom: 1px solid @rr-default-input-border-color;
}

.category-action-block {
    display: flex;
    flex-direction: column;
    gap: 0;

    .action {
        display: flex;
        align-items: center;
        gap: 8px;
        padding: 27px 24px;
        cursor: pointer;

        > span {
            margin-left: 0 !important;
        }

        &:hover {
            background-color: #f4f5f8;
        }

        &.green {
            svg {
                color: @rr-status-active-color;
            }
        }

        &.red {
            svg {
                color: @rr-operation-status-correct-color;
            }
        }

        .icon {
            margin-right: 6px;
            margin-top: -2px;

            svg {
                width: 21px;
                height: 21px;
            }
        }

        .categories-count {
            color: @rr-default-input-placeholder-color;
        }
    }
}

.change-category-button {
    top: 3px !important;

    svg {
        color: #383f77;
    }
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';