.rr-link-btn-2.ant-btn {
    height: unset;
    line-height: unset;
    padding: 0;

    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.rr-link-btn-2 > .anticon {
    line-height: 0;
    margin-right: 14px;
}

.rr-link-btn-2.ant-btn > .anticon + span {
    margin-left: 0;
}

.rr-link-btn-2.ant-btn-link:not(:disabled):hover,
.rr-link-btn-2.ant-btn-link:not(:disabled):focus {
    color: #525a95;
    opacity: 0.8;
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';