@import '../../assets/styles/vars';

.button-state(@backgroundColor: none, @borderColor: transparent, @textColor: #ffffff) {
    color: @textColor;
    background: @backgroundColor;
    border-color: @borderColor;
}

// Круглая кнопка с одинаковым цветом фона и рамки. текст белый
.button(@color: none) {
    // Обычное состояние, Фокус
    &,
    &:focus {
        .button-state(@color, @color, #ffffff);
    }

    // Навели
    &:hover {
        .button-state(darken(@color, 10), darken(@color, 10), #ffffff);
    }

    // Нажали
    &:active {
        .button-state(fade(@color, 75), fade(@color, 75), #ffffff);
    }

    // Заблокирована
    &[disabled],
    &[disabled]:hover,
    &[disabled]:active,
    &.rr-round-btn-disabled,
    &.rr-round-btn-disabled:hover,
    &.rr-round-btn-disabled:active {
        .button-state(#c4c5d6, #c4c5d6, #ffffff);
        cursor: default;
    }
}

// Кнопка с радиусом 10, прозрачным фоном и с одинаковым цветом рамкой и текстом
.button2(@color: none) {
    // Обычное состояние, Фокус
    &,
    &:focus {
        .button-state(transparent, @color, @color);
        border-radius: 10px !important;
    }

    // Навели
    &:hover {
        .button-state(transparent, darken(@color, 10), darken(@color, 10));
    }

    // Нажали
    &:active {
        .button-state(transparent, fade(@color, 75), fade(@color, 75));
    }

    // Заблокирована
    &[disabled],
    &[disabled]:hover,
    &[disabled]:active,
    &.rr-round-btn-disabled,
    &.rr-round-btn-disabled:hover,
    &.rr-round-btn-disabled:active {
        .button-state(transparent, #c4c5d6, #c4c5d6);
        cursor: default;
    }
}

// Кнопка круглая с прозрачным фоном и с одинаковым цветом рамкой и текстом, и рамка только при наведении на кнопку
.button2a(@color: none) {
    // Обычное состояние, Фокус
    &,
    &:focus {
        .button-state(transparent, transparent, @color);
    }

    // Навели
    &:hover {
        .button-state(transparent, darken(@color, 10), darken(@color, 10));
    }

    // Нажали
    &:active {
        .button-state(transparent, fade(@color, 75), fade(@color, 75));
    }

    // Заблокирована
    &[disabled],
    &[disabled]:hover,
    &[disabled]:active,
    &.rr-round-btn-disabled,
    &.rr-round-btn-disabled:hover,
    &.rr-round-btn-disabled:active {
        .button-state(transparent, transparent, #c4c5d6);
        cursor: default;
    }
}

// Кнопка круглая с белым фоном и одинаковым цветом текста и рамки
.button3(@color: none) {
    // Обычное состояние, Фокус
    &,
    &:focus {
        .button-state(#ffffff, @color, @color);
    }

    // Навели
    &:hover {
        .button-state(#ffffff, darken(@color, 10), darken(@color, 10));
    }

    // Нажали
    &:active {
        .button-state(#ffffff, fade(@color, 75), fade(@color, 75));
    }

    // Заблокирована
    &[disabled],
    &[disabled]:hover,
    &[disabled]:active,
    &.rr-round-btn-disabled,
    &.rr-round-btn-disabled:hover,
    &.rr-round-btn-disabled:active {
        .button-state(#ffffff, #c4c5d6, #c4c5d6);
        cursor: default;
    }
}

// Кнопка круглая с белым фоном и рамкой 3px и с темным текстом
.button4(@color: none) {
    // Обычное состояние, Фокус
    .ant-btn& {
        border-width: 3px;
        line-height: 32px;
    }

    &,
    &:focus {
        .button-state(#ffffff, @color, @color);
    }

    // Навели
    &:hover {
        .button-state(#ffffff, darken(@color, 10), darken(@color, 10));
    }

    // Нажали
    &:active {
        .button-state(#ffffff, fade(@color, 75), fade(@color, 75));
    }

    // Заблокирована
    &[disabled],
    &[disabled]:hover,
    &[disabled]:active,
    &.rr-round-btn-disabled,
    &.rr-round-btn-disabled:hover,
    &.rr-round-btn-disabled:active {
        .button-state(#ffffff, #c4c5d6, #c4c5d6);
        cursor: default;
    }
}

.ant-btn-round.ant-btn-lg.rr-round-btn {
    height: 40px;
    padding: 0 22px;
    font-size: 14px;
    border-radius: 40px;
    font-weight: 500;
}

.ant-btn-round.ant-btn-lg.rr-round-btn .anticon {
    font-size: 22px;
    position: relative;
    top: 1px;
    vertical-align: middle;
    margin-left: -2px;
}

.ant-btn-round.ant-btn > .anticon + span,
.ant-btn > span + .anticon {
    margin-left: 7px;
}

.ant-btn-round.ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) {
    padding-left: 0px;
}

.ant-btn-round.ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) .anticon:not(:last-child) {
    margin-left: 14px;
}

// rr-round-btn-default ///////////////////////////////////////////////
.rr-round-btn-default {
    // Обычное состояние, Фокус
    &,
    &:focus {
        .button-state(#525a95, #525a95, #ffffff);
    }
    // Навели
    &:hover {
        .button-state(#383f77, #383f77, #ffffff);
    }
    // Нажали
    &:active {
        .button-state(fade(#383f77, 75), fade(#383f77, 75), #ffffff);
    }
    // Заблокирована
    &[disabled],
    &[disabled]:hover,
    &[disabled]:active {
        .button-state(#c4c5d6, #c4c5d6, #ffffff);
    }
}

// rr-round-btn-accent ///////////////////////////////////////////////
/*.rr-round-btn-accent {
  // Обычное состояние, Фокус
  &, &:focus {
    .button-state(#00c5dc, #00c5dc, #ffffff);
  }

  // Навели, Фокус
  &:hover {
    .button-state(#22b9ff, #22b9ff, #ffffff);
  }

  // Нажали
  &:active {
    .button-state(fade(#22b9ff, 75), fade(#22b9ff, 75), #ffffff);
  }

  // Заблокирована
  &[disabled], &[disabled]:hover, &[disabled]:active {
    .button-state(#c4c5d6, #c4c5d6, #ffffff);
  }
}*/

// rr-round-btn-success ///////////////////////////////////////////////
.rr-round-btn-success {
    // Обычное состояние, Фокус
    &,
    &:focus {
        .button-state(#34bfa3, #34bfa3, #ffffff);
    }

    // Навели
    &:hover {
        .button-state(#27a48b, #27a48b, #ffffff);
    }

    // Нажали
    &:active {
        .button-state(fade(#27a48b, 75), fade(#27a48b, 75), #ffffff);
    }

    // Заблокирована
    &[disabled],
    &[disabled]:hover,
    &[disabled]:active {
        .button-state(#c4c5d6, #c4c5d6, #ffffff);
    }
}

// Делался для кнопок создания сущностей при создании операции
.rr-round-btn-success2 {
    // Обычное состояние, Фокус
    &,
    &:focus {
        .button-state(#ffffff, #34bfa3, #34bfa3);
    }

    // Навели
    &:hover {
        .button-state(#ffffff, darken(#34bfa3, 10), darken(#34bfa3, 10));
    }

    // Нажали
    &:active {
        .button-state(#ffffff, fade(#34bfa3, 75), fade(#34bfa3, 75));
    }

    // Заблокирована
    &[disabled],
    &[disabled]:hover,
    &[disabled]:active {
        .button-state(#ffffff, #c4c5d6, #c4c5d6);
    }
}

// rr-round-btn-danger ///////////////////////////////////////////////
.rr-round-btn-danger {
    // Обычное состояние, Фокус
    &,
    &:focus {
        .button-state(#ffffff, #dc3545, #e35a67);
    }

    // Навели
    &:hover {
        .button-state(#f4516c, #f4516c, #ffffff);
    }

    // Нажали
    &:active {
        .button-state(fade(#f4516c, 75), fade(#f4516c, 75), #ffffff);
    }

    // Заблокирована
    &[disabled],
    &[disabled]:hover,
    &[disabled]:active {
        .button-state(#ffffff, #c4c5d6, #c4c5d6);
    }
}

.rr-round-btn-info {
    .button(#36a3f7);
}

.rr-round-btn-brandSecond {
    .button(#22b9ff);
}

// Кнопка "В архив"
.rr-round-btn-TO_ARCHIVE {
    .button(@rr-entity-to-archive-color); // был #c0bebe
}

// Кнопка "Из архива"
.rr-round-btn-FROM_ARCHIVE {
    //.button2(#00c5dc, #22b9ff);
    .button(@rr-entity-from-archive-color);
}

// Кнопка "Удалить"
.rr-round-btn-DELETE {
    .button(@rr-entity-delete-color);
}

.rr-round-btn-blocked {
    .button(#A0A0A0);
}

// TODO Это сделано только для кнопки "Задать количество"
.rr-round-btn-brandSecond2 {
    .button(#00afee);
}

//.rr-round-btn-inRent {
//  .button(#ffcd43);
//}
.rr-round-btn-inRent {
    .button(#FC8C2F;);
}

.rr-round-btn-roundIcon {
    width: 32px !important;
    height: 32px !important;
    padding: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.rr-round-btn-PROLONG {
    .button(@rr-operation-status-prolong-color);
}

.rr-round-btn-RETURN {
    .button(@rr-operation-status-return-color);
}

.rr-round-btn-RETURN_BROKEN {
    .button(@rr-operation-status-returnbroken-color);
}

.rr-round-btn-LOST_NO_RETURN {
    .button(@rr-operation-status-lostnoreturn-color);
}

.rr-round-btn-CORRECT {
    .button(@rr-operation-status-correct-color);
}

.rr-round-btn-RENT {
    .button(@rr-operation-status-rent-color);
}

.rr-round-btn-PLANNED {
    .button(@rr-operation-status-planned-color);
}

.rr-round-btn-CANCEL {
    .button(@rr-operation-status-cancel-color);
}

.rr-round-btn-ORDER {
    .button(@rr-operation-status-order-color);
}

.rr-round-btn-BOOK {
    .button(@rr-operation-status-book-color);
}

.rr-round-btn-UNDER_MAINTENANCE {
    background-color: rgba(82, 89, 147, 0.8);
}

.ant-btn.rr-round-btn-SUBRENT_DRAFT {
    .button3(#575962);
    border: 1px dashed #575962;
    //border-width: 3px;
    //line-height: 32px;
}

.ant-btn.rr-round-btn-SUBRENT_BOOK_SHIPMENT {
    .button4(@rr-operation-status-book-color);
    border-width: 3px;
    line-height: 32px;
}

.ant-btn.rr-round-btn-SUBRENT_ACCEPT_SHIPMENT {
    .button4(@rr-operation-status-rent-color);
    border-width: 3px;
    line-height: 32px;
}

.ant-btn.rr-round-btn-SUBRENT_RETURN_TO_SHIPPER {
    .button4(@rr-operation-status-return-color);
    border-width: 3px;
    line-height: 32px;
}

.ant-btn.rr-round-btn-SUBRENT_CANCEL {
    .button4(@rr-operation-status-cancel-color);
    border-width: 3px;
    line-height: 32px;
}

.ant-btn.rr-round-btn-SUBRENT_PROLONG {
    .button4(@rr-operation-status-prolong-color);
    border-width: 3px;
    line-height: 32px;
}

.ant-btn.rr-round-btn-join {
    .button3(@rr-operation-status-prolong-color);
    line-height: 32px;
    svg {
        font-size: 18px;
    }
}

.rr-round-btn-default4 {
    .button(#525a95);
    border-radius: 10px !important;
}

///////////////////////////////////
// Это кнопки с прозрачным фоном и рамкой
.rr-round-btn-defaultSecondary {
    .button2(#525a95);
}

.rr-round-btn-successSecondary {
    .button2(#34bfa3);
}

.rr-round-btn-dangerSecondary {
    .button2(#f4516c);
}
///////////////////////////////////

.rr-round-btn-DRAFT {
    .button3(#575962);
    border: 1px dashed #575962;
}

.rr-round-btn-default3 {
    .button3(#525a95);
}

.rr-round-btn-danger2 {
    .button2a(#f4516c);
}

.rr-round-btn-ACTION_ELEMENTS_SET_DATES {
    .button(@rr-operation-status-prolong-color);
}

.rr-round-btn-ACTION_ELEMENTS_SET_SHIFTS {
    .button(@rr-operation-status-book-color);
}

.rr-round-btn-ACTION_ELEMENTS_RECALCULATE_SHIFTS {
    .button3(@rr-operation-status-order-color);
    & span {
        color: #575962;
    }
}

.rr-round-btn-ACTION_ELEMENTS_SET_PRICE {
    .button(#36bfa3);
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';