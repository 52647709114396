.rr-rich-text-editor .ql-toolbar.ql-snow {
    border: 1px solid #dcdfe8;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.rr-rich-text-editor .ql-container.ql-snow {
    border-color: #dcdfe8;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.rr-rich-text-editor .ql-editor {
    min-height: 100px;
    cursor: text;
    font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
    font-size: 14px;
    color: rgba(87, 89, 98, 0.8);
}

.rr-rich-text-editor .ql-snow .ql-tooltip {
    z-index: 1;
}

.rr-rich-text-editor .ql-snow .ql-picker {
    line-height: 1;
}

.rr-rich-text-editor .ql-snow a, .rr-rich-text-editor .ql-snow a:hover {
    color: #06c !important;
    text-decoration: underline;
}

.rr-rich-text-editor .ql-snow .ql-tooltip[data-mode=link]::before {
    content: "";
    line-height: 0;
    display: block;
}

.rr-rich-text-editor .ql-snow .ql-tooltip::before {
    content: "";
    line-height: 0;
    display: block;
}

.rr-rich-text-editor .ql-snow .ql-tooltip a.ql-action::after {
    content: 'Изменить';
}

.rr-rich-text-editor .ql-snow .ql-tooltip.ql-editing a.ql-action::after {
    content: 'Сохранить';
}

.rr-rich-text-editor .ql-snow .ql-tooltip a.ql-remove::before {
    content: 'Удалить';
}

.rr-rich-text-editor .ql-snow .ql-tooltip input[type=text] {
    margin-left: 9px;
}

.rr-rich-text-editor .ql-snow .ql-tooltip .link-text-container input[type=text] {
    width: 252px;
    margin-left: 0;
    word-break: break-word;
}

.rr-rich-text-editor .ql-snow .ql-tooltip:not(.ql-editing) .link-text-container {
    display: none;
}

.rr-rich-text-editor .ql-snow .ql-tooltip:not(.ql-editing) .link-input-label {
    display: none;
}


.rr-rich-text-editor .ql-snow .ql-tooltip:not(.ql-editing) .ql-preview {
    margin-top: 6px;
}
@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';