.calendar-bottom-navigation {
    position: fixed;
    z-index: 200;
    right: 70px;
    background-color: #fff;
    transition: bottom .2s, opacity .2s, visibility .2s;

    &.visible {
        visibility: visible;
        bottom: 70px;
        opacity: 1;
    }

    &.hidden {
        bottom: 0;
        opacity: 0;
        visibility: hidden;
    }

    .collapsed {
        font-size: 18px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        color: #FFF;
        padding: 6px 16px;
        background-color: #525a95;

        &:hover {
            background-color: #525a95d9;
        }
    }

    .opened {
        background-color: #525A95;
        height: 90px;
        width: 100%;
        color: #fff;
        font-size: 18px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: 5px;
    }
}
@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';