.slider-range {
    position: relative;

    * {
        color: rgba(0, 0, 0, 0.65) !important;
    }

    .custom-mark {
        position: absolute;
        top: -37px;
        left: 6px;
    }
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';