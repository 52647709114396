.rr-page-header {
    background-color: #fff;
    box-shadow: 0 1px 15px 0 rgba(69, 65, 78, 0.1);
    height: 70px;
    position: relative;
    cursor: default;
    top: 0;
    margin: -30px -30px 30px -30px;
    display: flex;
    align-items: center;
    //min-width: 860px;
    padding-right: 32px;
    z-index: 1;
}

.rr-page-header-title {
    padding-left: 30px;
    padding-right: 20px;
    font-size: 21px;
    color: #525672;
    line-height: 70px;
    white-space: nowrap;
}

.rr-page-header-title .anticon {
    font-size: 22px;
    vertical-align: middle;
}

.rr-page-header-title {
    span,
    a {
        padding: 0 23px;
    }
}

.rr-page-header-name {
    display: inline-block;
    background-color: #979cbf;
    font-size: 21px;
    color: #fff;
    font-weight: 500;
    padding: 0 24px;
    min-width: 376px;
    text-align: center;
    line-height: 70px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.rr-page-header-right {
    display: flex;
    align-items: center;
    color: #525672;
    margin-left: 32px;
    line-height: 20px;
    max-height: 60px;
    overflow: hidden;
}

.rr-page-header-right .anticon {
    font-size: 22px;
    padding-right: 8px;
    position: relative;
    top: 1px;
}

.rr-page-header-right > div {
    line-height: 20px;
}

.rr-page-header-right > div:not(.anticon) > div:first-child {
    font-weight: 500;
}

.rr-page-header-right > div:not(.anticon) > div:not(first-child) {
    max-height: 50px;
}

@media (max-width: 1199px) {
    .rr-page-header-name {
        padding: 0 12px;
        min-width: 0;
    }
}

.dashboard-header-input-select .rr-custom-search.rr-input-gray.ant-input-affix-wrapper {
    border: 2px solid #383f77;
    border-radius: 5px;
}

.dashboard-header-input .rr-custom-search.rr-input-gray.ant-input-affix-wrapper {
    border: 0px solid #383f77;
}

.header-block-container {
    display: flex;
    align-items: center;
    gap: 40px;

    .calendar-navigation-container {
        background-color: #525a95;
        color: #fff;
        font-size: 18px;
        padding: 0;
        display: flex;
        align-items: center;
        height: 70px;
        width: 505px;
        margin-left: 40px;
    }

    .rr-grid-filters {
        margin-top: 6px;
        margin-bottom: initial;
    }
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';