.modal-form-header_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 32px;
}

.modal-form-header_filters-block {
    margin-right: 12px;
}

.rr-modal {
    &.fixed-header-height {
        .ant-modal-header {
            height: 76px !important;
            padding-top: 18px !important;
        }
    }
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';