.ant-card {
  font-feature-settings: normal;
  font-variant: normal;
  cursor: default;
  border-radius: 0;
  color: #575962;
}

.ant-card-bordered {
  border-width: 0;
}

.ant-card-wider-padding .ant-card-body {
  padding: 26px 30px;
}

.ant-card-body {
  padding: 32px;
}

.ant-card-head {
  color: #575962;
  font-size: 18px;
  font-weight: 400;
}

.ant-card-head-title {
  padding: 12px 0;
}

//////////////////////
.rr-card .ant-card-head {
  padding: 0px 40px;
  border-bottom: 1px solid #E4E5EC;
}

.rr-card .ant-card-body {
  padding: 24px 40px;
}