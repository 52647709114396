.calendar-timeline {
    position: relative;
    margin-top: 36px;

    .selected-row {
        background-color: #a5e6ff !important;
    }

    .group-background {
        background: rgba(0, 0, 0, 0) !important;
    }

    .kit-group-background {
        background-color: #d0d5e6 !important;
    }

    .fixed-header {
        position: fixed;
        z-index: 1000;
        top: 0;

        .rct-calendar-header {
            background-color: white !important;
            overflow: hidden;
        }
    }
}

.clickable-spin {
    .ant-spin-container {
        pointer-events: all !important;
    }
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';