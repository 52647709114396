.rr-status {
  display: inline-block;
  font-size: 11px;
  line-height: 16px;
  font-weight: 500;
  text-align: center;
  color: #fff;
  border-radius: 18px;
  padding: 2px 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  vertical-align: top;
  white-space: nowrap;
  user-select: none;
}
@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';