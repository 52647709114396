@normal: 13px;
@compact: 10px;

.setSize(@size) {
    width: @size !important;
    min-width: @size !important;
    height: @size !important;
    min-height: @size !important;
}

.icon-circle {
    border-radius: 50%;
    margin: 0 8px 0 8px;
    background-color: #fff;
    z-index: 100000;

    &.normal {
        .setSize(@normal);
        &.outlined {
            .setSize(calc(@normal - 2px));
        }
    }
    &.compact {
        .setSize(@compact);
        &.outlined {
            .setSize(calc(@compact - 2px));
        }
        margin: 0 6px 0 6px;
    }
    &.outlined {
        border-width: 1px !important;
    }
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';