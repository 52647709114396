.rr-element-popover-footer {
  border-top: 1px solid #e4e5ec;
  overflow: hidden;
}

.rr-element-popover-footer > .rr-form-item:not(:last-child) {
  margin-bottom: 0;
}

.rr-element-popover-footer > .ant-col {
  padding: 12px 8px 12px 32px;
}

.rr-element-popover-footer > .ant-col:nth-child(1) {
  margin-right: 10px;
}

.rr-element-popover-footer > .ant-col:nth-child(2) {
  border-left: 1px solid #e4e5ec;
}

.rr-element-popover-footer > .ant-col:nth-child(3) {
  background-color: #525A95;
  margin-left: 10px;
  margin-right: -30px;
}

.rr-element-popover-footer > .ant-col:nth-child(3) .rr-label, .rr-element-popover-footer > .ant-col:nth-child(3) .rr-value  {
  color: #fff;
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';