.description-custom-field-container {
    display: -webkit-box !important;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    flex-wrap: wrap;
    overflow: hidden;
    text-overflow: ellipsis;
    overflow-wrap: break-word;
    word-break: keep-all;

    &.grid-value {
        max-height: 42px !important;
    }

    & > *:not(:last-child)::after {
        content: ',';
        padding-right: 3px;
    }

    .description-custom-field {
        color: #525993;
        cursor: pointer;
        width: auto;
        max-width: 100%;

        display: inline-block;
        margin-right: 5px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &:hover {
            text-decoration: underline;
            opacity: 0.8;
        }
    }
}

.grid-checkbox {
    display: flex;
    justify-content: center;
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';