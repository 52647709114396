.rr-operationForm-variant-select-popover {
  z-index: 1000;
  min-width: 360px;
}

.rr-operationForm-variant-select-icon {
  color: #343f9a;
}

.rr-operationForm-variant-select-icon:hover {
  color: fade(#343f9a, 75);
}

.rr-operationForm-variant-select-popover .ant-select-selection,
.rr-operationForm-variant-select-popover .ant-select-selection:hover,
.rr-operationForm-variant-select-popover .ant-select-selection:focus,
.rr-operationForm-variant-select-popover .ant-select-selection:active,
.rr-operationForm-variant-select-popover .ant-select-focused .ant-select-selection {
  border-color: transparent;
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';