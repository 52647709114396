@import '../../../assets/styles/vars.less';

.rr-icon- {
  font-size: 21px;
}

a .rr-icon-:hover {
  opacity: .8;
}

.rr-icon-system-add {
  color: #34bfa3;
}

.rr-icon-system-standard {
  color: #343f9a;
}

.rr-icon-system-custom {
  color: #1cb3a6;
}

.rr-icon-system-financial {
  color: #ffcd43;
}

.rr-icon-system-hidden {
  color: #a0a0a0;
}

.rr-icon-system-unhidden {
  color: #FC8C2F;
}

.rr-icon-system-chartType_ClusteredColumnChart {
  color: #8b5cb1;
}

.rr-icon-system-chartType_ColumnChart {
  color: #8b5cb1;
}

.rr-icon-system-chartType_HighlightingChartSeries {
  color: #34BFA3;
}

.rr-icon-system-chartType_MultilevelTreeMap {
  color: #525993;
}

.rr-icon-system-chartType_PieChart {
  color: #04b0f1;
}

.rr-icon-system-delete {
  color: #F4516C;
}

.rr-icon-system-cancel {
  color: #F4516C;
}

.rr-icon-system-edit {
  color: #525891;
}

.rr-icon-system-download {
  color: #21bfa4;
}

.rr-icon-system-upload {
  color: #00afee;
}

.rr-icon-system-otherActions {

}

.rr-icon-system-actions {

}

.rr-icon-system-project {
  color: #525A95;
}

.rr-icon-system-subrent {
  color: #ffb822;
}

.rr-icon-system-rentElementActions {
  color: #34bfa3;
}

.rr-icon-system-attachment {
  color: @rr-colors-purple-3;
}

.rr-icon-system-contact {
  color: #00afee;
}

.rr-icon-system-crew {
  color: #21bfa4;
}

.rr-icon-system-legalAttributes {
  color: @rr-colors-purple-3;
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';