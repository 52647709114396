.rr-operationForm-element-create-popover {
  width: 640px;
}

.rr-operationForm-element-create-popover .ant-input-number input, .rr-operationForm-element-create-popover .ant-calendar-picker input, .rr-operationForm-element-create-popover .rr-range-picker.ant-input {
  font-size: 16px;
  font-weight: 600;
}

.rr-operation-element-create-popover-reset-icon {
  font-size: 20px;
  margin-left: 8px;//16px;
  vertical-align: middle;
  color: #383F77;
}

.rr-operation-element-create-popover-reset-icon:hover {
  color: fade(#383F77, 80);
}

.ant-input-number.rr-operation-element-create-popover-param-changed input {
  font-weight: 600;
}

.rr-operation-element-create-popover-param-changed > .ant-input.ant-calendar-picker-input input {
  font-weight: 600;
}

.rr-operation-element-create-popover-param-warning input {
  background-color: #ffe3a7;
}

.rr-operation-element-create-popover-param-error > .ant-input {
  background-color: rgba(244, 82, 109, 0.4);
}

.rr-operation-element-create-popover-param-error > .ant-calendar-picker-input.ant-input-disabled {
  background-color: rgba(244, 82, 109, 0.2);
}

.ant-input-number.rr-operation-element-create-popover-param-not-changed input {
  color: #aaa;
  font-weight: 400;
}

.rr-operation-element-create-popover-param-not-changed > .ant-input.ant-calendar-picker-input input {
  color: #aaa;
  font-weight: 400;
}

.rr-operation-element-create-popover-param-not-changed .rr-range-picker-input {
  color: #aaa;
  font-weight: 400;
}

.rr-operation-element-create-popover-param-changed .rr-range-picker-input {
  font-weight: 600;
}

.rr-range-picker.rr-operation-element-create-popover-param-error {
  background-color: rgba(244, 82, 109, 0.4);
}

.rr-operationForm-element-create-popover .rr-operationForm-element-create-popover_apply-button_with-warning.ant-btn.ant-btn-primary,
.rr-operationForm-element-create-popover .rr-operationForm-element-create-popover_apply-button_with-warning.ant-btn.ant-btn-primary:focus  {
  background-color: #FFFFFF;
  color: #ff8e43;
  border-color: #ff8e43;
  border-width: 1px;
}

.rr-operationForm-element-create-popover .rr-operationForm-element-create-popover_apply-button_with-warning.ant-btn.ant-btn-primary:hover {
  background-color: #ff8e43;
  color: #FFFFFF;
  border-color: #ff8e43;
  border-width: 1px;
}

.rr-operationForm-element-create-popover .rr-operationForm-element-create-popover_apply-button_with-warning.ant-btn.ant-btn-primary:active {
  opacity: .7;
}

.rr-operationForm-element-create-popover .rr-operationForm-element-create-popover_apply-button_with-problem.ant-btn.ant-btn-primary,
.rr-operationForm-element-create-popover .rr-operationForm-element-create-popover_apply-button_with-problem.ant-btn.ant-btn-primary:focus  {
  background-color: #FFFFFF;
  color: #f8516c;
  border-color: #f8516c;
  border-width: 1px;
}

.rr-operationForm-element-create-popover .rr-operationForm-element-create-popover_apply-button_with-problem.ant-btn.ant-btn-primary:hover {
  background-color: #f8516c;
  color: #FFFFFF;
  border-color: #f8516c;
  border-width: 1px;
}

.rr-operationForm-element-create-popover .rr-operationForm-element-create-popover_apply-button_with-problem.ant-btn.ant-btn-primary:active {
  opacity: .7;
}

.rr-operationForm-element-create-popover .rr-operationForm-param-reset-button {
  margin-left: 12px;
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';