@import '../../assets/styles/vars';

.rr-money-value-muted {
    color: @rr-color-muted;
}

.rr-money-value-highlighted {
    color: @rr-color-danger;
    font-weight: 600;
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';