.rr-inline-input {
    border: none;
    text-decoration: none;
}

.rr-inline-string-input {
    border-color: #575962 !important;

    input {
        padding: 0;
        height: 28px;
        line-height: 1;
    }
}

textarea.ant-input.rr-inline-string-input {
    padding: 4px 5px !important;
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';