.rr-concurrent-operations-list.rr-simple-table {

}

.rr-concurrent-operations-list.rr-simple-table .rr-simple-table-row:not(:last-child) {
  border-bottom: 1px solid #eaeaea;
}

.rr-concurrent-operations-list.rr-simple-table .rr-simple-table-row {
  padding: 28px 32px;
}

.rr-concurrent-operations-list .rr-concurrent-operations-list-user-link {
  color: #343f9a;
  text-decoration: underline;
}


@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';