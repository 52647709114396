.rr-resizable-panels {
    display: flex;
    position: relative;
}

.rr-resizable-panels-handle {
    display: block;
    width: 4px;
    z-index: 2;
    position: absolute;
    top: 5px;
    bottom: 5px;
    cursor: col-resize;
    border-radius: 4px;
    overflow: hidden;
    transition: background-color 0.3s ease;

    &-resizing,
    &:hover {
        background-color: #bccced;
    }
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';