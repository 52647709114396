.button-state(@backgroundColor: none, @borderColor: transparent, @textColor: #ffffff) {
  color: @textColor;
  background: @backgroundColor;
  border-color: @borderColor;
}

.rr-create-entity-btn.ant-btn {
  .anticon {
    font-size: 25px;
    line-height: 0;
  }

  & {
    display: inline-flex;
    align-items: center;
    padding: 0 8px;
  }

  // Обычное состояние, Фокус
  &, &:focus {
    .button-state(#ffffff, #34bfa3, #34bfa3);
  }

  // Навели
  &:hover {
    .button-state(#ffffff, darken(#34bfa3, 10), darken(#34bfa3, 10));
  }

  // Нажали
  &:active {
    .button-state(#ffffff, fade(#34bfa3, 75), fade(#34bfa3, 75));
  }

  // Заблокирована
  &[disabled], &[disabled]:hover, &[disabled]:active {
    .button-state(#ffffff, #c4c5d6, #c4c5d6);
  }
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';