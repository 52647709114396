.ant-form-inline .ant-form-item {
    margin-right: 30px;
}

.ant-form-item-label > label {
    color: #575962;
}

.ant-form-item-label {
    line-height: 38px;
    text-align: left;
    overflow: visible !important;
}

.ant-form-item-required::before {
    content: '';
    display: none;
}

.ant-form-item-label > label.ant-form-item-required::after {
    display: inline-block;
    margin-left: 1px;
    color: #f4516c;
    font-size: 16px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
}

.ant-form-vertical .ant-form-item-label,
.ant-col-24.ant-form-item-label,
.ant-col-xl-24.ant-form-item-label {
    padding-bottom: 6px;
}

.ant-form-item-control {
    line-height: 38px;
}

.has-error .ant-form-explain,
.has-error .ant-form-split {
    color: #f4516c;
}

.ant-form-explain,
.ant-form-extra {
    color: #a6a8b0; // TODO Правильный цвет подставить потом из гайдлайна!
    font-size: 11px;
    margin-top: 8px;
    min-height: 14px;
}

.ant-form-extra {
    margin-top: 4px;
    font-feature-settings: normal;
    font-variant: normal;
}

.ant-form-item {
    margin-bottom: 22px;
}

.ant-form-item-with-help {
    margin-bottom: 10px;
}

form textarea.ant-input {
    margin-bottom: 1px;
}

.is-validating.has-feedback .ant-form-item-children-icon {
    color: #525993;
}
