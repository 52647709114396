.ant-select-tree {
  font-feature-settings: normal;
  font-variant: normal;
}

li.ant-select-tree-treenode-disabled > span:not(.ant-select-tree-switcher), li.ant-select-tree-treenode-disabled > .ant-select-tree-node-content-wrapper, li.ant-select-tree-treenode-disabled > .ant-select-tree-node-content-wrapper span {
  cursor: default;
}

.ant-select-tree li .ant-select-tree-node-content-wrapper.ant-select-tree-node-selected {
  background-color: #383F77;
  color: #fff;
}

.ant-select-tree li .ant-select-tree-node-content-wrapper:hover {
  background-color: #383F77;
  color: #fff;
}

.ant-select-tree li.ant-select-tree-treenode-disabled > .ant-select-tree-node-content-wrapper:hover {
  background-color: unset;
  color: #ccc;
}

.ant-select-tree li span.ant-select-tree-switcher {
  width: 0;
  height: 0;
}

.ant-select-tree li span.ant-select-tree-iconEle {
  height: 0;
}

.ant-select-tree li .ant-select-tree-node-content-wrapper {
  padding: 0;
  width: 100%;
}

.ant-select-tree li .ant-select-tree-node-content-wrapper{
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  vertical-align:top; // https://stackoverflow.com/questions/23529369/why-does-x-overflowhidden-cause-extra-space-below
}
