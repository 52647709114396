.ant-tabs {
  font-feature-settings: normal;
  font-variant: normal;
}

.rr-tabs .ant-tabs-bar {
  //background-color: #bcc2e2;
}

.rr-tabs .ant-tabs-bar {
  border-bottom: none;
  margin: 0 0 30px 0;
}

.rr-tabs .ant-tabs-tab {
  height: 44px;
  line-height: 44px;
  border-radius: 0;
  border: none;
  min-width: 130px;
  text-align: center;
  background: #ffffff;
  color: #525993;
  font-size: 21px;
  //font-weight: 500;
}

.rr-tabs .ant-tabs-nav .ant-tabs-tab:hover {
  color: #525993;
}

.rr-tabs .ant-tabs-nav .ant-tabs-tab-active {
  font-weight: normal;
}

.rr-tabs .ant-tabs-nav .ant-tabs-tab-active, .rr-tabs .ant-tabs-nav .ant-tabs-tab-active:hover {
  background: #525993;
  color: #fff;
}

.rr-tabs .ant-tabs-nav .ant-tabs-tab {
  padding: 0 34px;
  margin: 0;
  transition: unset;
  user-select: none;
}

.rr-tabs .ant-tabs-nav .ant-tabs-tab:not(:first-child)::before {
  background-color: #c7c9d5;
}

.rr-tabs .ant-tabs-nav .ant-tabs-tab:not(:first-child)::before {
  position: absolute;
  top: 0;
  left: -1px;
  display: block;
  width: 1px;
  height: 100%;
  background-color: #d9d9d9;
  content: '';
}

.rr-tabs .ant-tabs-nav .ant-tabs-tab:last-child {
  margin-right: 16px;
}

.rr-tabs .ant-tabs-ink-bar {
  display: none !important;
}

.rr-tabs .ant-tabs-extra-content {
  line-height: 43px;
}

.rr-tabs .ant-tabs-nav-wrap {
  margin-bottom: 0;
}

.rr-tabs .ant-radio-button-wrapper-checked::before {
  background-color: #525993 !important;
  opacity: 0.1;
}

.rr-tabs .ant-tabs-nav .ant-tabs-tab-disabled, .rr-tabs .ant-tabs-nav .ant-tabs-tab-disabled:hover {
  cursor: default;
}

.rr-tabs .ant-tabs-nav .ant-tabs-tab-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
}

.rr-tabs .ant-tabs-nav .ant-tabs-tab-active {
  font-weight: 500;
}

//font-size: 21px;