.info-select-dropdown-item {
    height: 32px !important;
    line-height: 32px !important;
    padding-left: 12px !important;
    padding-right: 12px !important;

    > a:hover {
        color: #6f727d !important;
    }
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';