.rr-rich-text.ql-editor {
  padding: 0;
}

.rr-rich-text.ql-editor > * {
  cursor: default;
}

.rr-rich-text.ql-editor a, .rr-rich-text.ql-editor a:hover {
  color: #06c !important;
  text-decoration: underline;
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';