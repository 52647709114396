.rr-modal .ant-modal-header {
    border-radius: 0;
}

.rr-modal .ant-modal-footer {
    border-radius: 0;
}

.rr-modal .ant-modal-body {
    padding: 0 25px 5px 25px;
    background-color: #ffffff;
}

.rr-modal-confirm.rr-user-profile-mail-confirm .ant-modal-footer div {
    display: flex;
    flex-direction: row-reverse;
}

.rr-modal-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .hint-container {
        display: flex;
        align-items: center;
        margin-left: 10px;

        .icon {
            margin-right: 10px;

            svg {
                color: rgb(164, 164, 164);
                height: 18px;
                width: 18px;
            }
        }

        .link {
            color: #343f9a;
            text-decoration: underline;
            margin-left: 4px;
            transition: opacity 0.3s;
            animation-timing-function: ease-out;

            &.disabled {
                pointer-events: none;
                color: rgba(0, 0, 0, 0.65);
            }
        }
    }
}

.form-bookmark {
    display: flex;
    align-items: center;
    position: fixed;

    // margin-left: 775px;
    padding: 12px 0 12px 24px;
    border-top-right-radius: 32px;
    border-bottom-right-radius: 32px;

    background: white;
    min-height: 70px;

    z-index: -1;
    box-shadow: inset 5px 0 8px -2px rgba(0, 0, 0, 0.15);

    &.top {
        top: 20px;
    }
    &.bottom {
        bottom: 20px;
    }

    & > * {
        margin-right: 32px;
    }
}

@keyframes slideIn {
    from {
        transform: translateX(-50%);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slideOut {
    from {
        transform: translateX(0);
        opacity: 1;
    }
    to {
        transform: translateX(-50%);
        opacity: 0;
    }
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';