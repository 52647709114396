@import '../../assets/styles/vars.less';

.joinContacts {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 20px 24px;
    border-bottom: 1px solid @rr-default-input-border-color;
}

.category-action-block .action.join svg {
    color: #3d64d4;
    font-size: 18px;
}

.rr-join-contact-popover {
    z-index: 999 !important;
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';