.rr-recalculate-shifts-block > div:nth-child(1) {
  display: flex;
  align-items: center;
}

.rr-recalculate-shifts-block > div:nth-child(1) > *:nth-child(2) {
  margin-left: 10px;
}

.rr-recalculate-shifts-block > div:nth-child(2) {
  font-size: 12px;
  color: #aaaaaa;
  margin-top: 4px;
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';