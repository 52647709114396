.rr-download-document-button > span {
  color: #575962;
}

.rr-download-document-button:hover > span {
  color: darken(#575962, 10);
}

.rr-download-document-button[disabled] > span, .rr-download-document-button[disabled]:hover > span, .rr-download-document-button[disabled]:active > span {
  color: #c4c5d6;
}

.rr-download-contract-button {
  display: inline-flex;
  align-items: center;
  border-radius: 10px;
  border-style: solid;
  border-width: 1px;
  padding: 8px 22px;
  line-height: 1.2 !important;
  color: #34bfa3;
  border-color: #34bfa3;
  min-height: 40px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  user-select: none;
  cursor: pointer;
  margin-right: 32px;
}

.rr-download-contract-button:hover {
  color: #299781;
  border-color: #299781;
}

.rr-download-contract-button:active {
  color: rgba(52, 191, 163, 0.75);
  border-color: rgba(52, 191, 163, 0.75);
}

.rr-download-contract-button .anticon {
  font-size: 22px;
  margin-left: -2px;
}

.rr-download-contract-button > div {
  font-weight: 500;
  color: #575962;
}

.rr-download-contract-button:hover > div {
  color: darken(#575962, 10);
}

.rr-download-contract-button-disabled,
.rr-download-contract-button-disabled:active,
.rr-download-contract-button-disabled:hover,
.rr-download-contract-button-disabled:focus {
  cursor: default;
  color: #c4c5d6;
  border-color: #c4c5d6;
}

.rr-download-contract-button-disabled > div, .rr-download-contract-button-disabled:hover > div {
  color: #c4c5d6;
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';