@import '../../../../assets/styles/vars';

.rr-settings-modules-module {
  display: flex;
  padding: 32px @rr-card-padding-left-right 28px;
  border-bottom: 1px solid @rr-divider-color;
}

.rr-settings-modules-module-icon {
  min-width: 40px;
  margin-top: 3px;
}

.rr-settings-modules-module-icon .anticon {
  font-size: 22px;
  color: unset;
}

.rr-settings-modules-module-title {
  flex-grow: 1;
  max-width: 300px;
  width: 100%;
  min-width: 200px;
}

.rr-settings-modules-module-name {
  font-size: 18px;
  font-weight: 700;
}

.rr-settings-modules-module-status {
  font-size: 18px;
}

.rr-settings-modules-module-status-icon {
  font-size: 21px;
  margin-right: 12px;
}

.rr-settings-modules-module-status__disabled {
  color: @rr-color-danger;
}

.rr-settings-modules-module-status__enabled {
  color: @rr-color-available;
}

.rr-settings-modules-module-status__unavailable {
  color: @rr-color-disabled;
}

.rr-settings-modules-module-description {
  flex-grow: 1;
  color: @rr-info-text-color;
  font-size: @rr-info-text-size;
  padding-right: 60px;
  width: 100%;
  word-break: break-word;
  min-width: 200px;
  padding-top: 4px;

  display: flex;

  transition: all .15s;
}

.rr-settings-modules-module-description:hover {
  font-size: 14px;
  color: unset;
}

.rr-settings-modules-module-description .rr-settings-modules-module-description-icon {
  opacity: 0;
  font-size: 22px;
  color: #525993;
  padding-right: 5px;
  vertical-align: top;
  margin-top: -2px;
}

.rr-settings-modules-module-description:hover .rr-settings-modules-module-description-icon {
  opacity: 1;
}

.rr-settings-modules-module-actions {
  text-align: right;
}

.rr-settings-modules-module-actions button {
  width: 130px;
}

@media (max-width: 1000px) {
  .rr-settings-modules-module {
    flex-wrap: wrap;
  }

  .rr-settings-modules-module-title {
    width: unset;
  }

  .rr-settings-modules-module-description {
    width: 100%;
    padding-left: 40px;
  }

  .rr-settings-modules-module-actions {
    width: 100%;
    margin-left: 40px;
    text-align: left;
    margin-top: 12px;
  }
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';