.rr-category-delete-modal .ant-checkbox-wrapper:not(:last-child) {
  margin-bottom: 15px;
}

.ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0;
}

.ant-checkbox-wrapper {
  display: block;
}

.rr-category-delete-modal .rr-category-delete-modal__disabled_checkbox.ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #aaaaaa;
  border-color: #aaaaaa;
}

.rr-category-delete-modal .rr-category-delete-modal__disabled_checkbox.ant-checkbox-wrapper:hover .ant-checkbox-checked .ant-checkbox-inner {
  border-color: #aaaaaa;
}

.rr-category-delete-modal .rr-category-delete-modal__disabled_checkbox.ant-checkbox-wrapper:hover .ant-checkbox-checked::after {
  border-color: #aaaaaa;
}

.rr-category-delete-modal .rr-category-delete-modal__disabled_checkbox.ant-checkbox-wrapper .ant-checkbox .ant-checkbox-input {
  cursor: default;
}

.rr-category-delete-modal .rr-category-delete-modal__disabled_checkbox.ant-checkbox-wrapper {
  cursor: default;
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';