.rr-collapsible-block.ant-collapse {
    border: none;
    border-top: unset;/*1px solid #eaeaea;*/
    border-radius: 0;
    background-color: unset;
}

.rr-collapsible-block.ant-collapse .ant-collapse-item:last-child > .ant-collapse-content {
    border-radius: 0;
}

.rr-collapsible-block.ant-collapse .ant-collapse-item:last-child > .ant-collapse-content > .ant-collapse-content-box {
    padding: 32px 32px;
}

.rr-collapsible-block.ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding: 7px 32px;
    user-select: none;
}

.rr-collapsible-block.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    left: 29px;
    font-size: 26px;
    color: #575962;
}

.rr-collapsible-block-header {
    display: flex;
    align-items: center;
    min-height: 38px;
}

.rr-collapsible-block-header-icon, .rr-collapsible-block-header-icon--disabled {
    line-height: 0;
    width: 46px;
    display: flex;
    justify-content: center;
    margin-left: 5px;
}

.rr-collapsible-block-header-icon .anticon, .rr-collapsible-block-header-icon--disabled .anticon {
    font-size: 22px;
}

.rr-collapsible-block-header-icon--disabled  {
    width: unset;
    margin-left: 0;
    margin-right: 12px;
}

.rr-collapsible-block-header-icon--disabled .anticon {
    color: #CCCCCC;
}

.rr-collapsible-block-header-title {
    font-size: 18px;
    color: #575962;
}

.rr-collapsible-block.ant-collapse .ant-collapse-item-disabled .rr-collapsible-block-header-title, .rr-collapsible-block.ant-collapse .ant-collapse-item-disabled .rr-collapsible-block-header-icon {
    color: #aaaaaa;
}

.rr-collapsible-block-header-extra {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
}

.rr-collapsible-block.ant-collapse > .ant-collapse-item {
    border-bottom: none;
}

.rr-collapsible-block .ant-collapse-content {
    border-top: 1px solid #eaeaea;
}

.rr-collapsible-block.ant-collapse .ant-collapse-item-disabled > .ant-collapse-header,
.rr-collapsible-block.ant-collapse .ant-collapse-item-disabled > .ant-collapse-header > .arrow {
    cursor: default;
}

.rr-collapsible-block.ant-collapse > .ant-collapse-item:last-child, .rr-collapsible-block.ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
    border-radius: 0;
}