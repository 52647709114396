.rr-operation-indicators-block {
  margin-top: 30px;
  display: flex;
  justify-content: end;
  flex-flow: row wrap;
  cursor: default;
}

.rr-operation-indicators-block-item {
  background-color: #FFFFFF;
  padding: 12px 4px 12px 32px;
  width: 215px;
}

.rr-operation-indicators-block-item-total {
  background-color: #525a95;
}

.rr-operation-indicators-block-item-total .rr-operation-indicators-block-item-label,
.rr-operation-indicators-block-item-total .rr-operation-indicators-block-item-value {
  color: #ffffff;
}

.rr-operation-indicators-block .rr-operation-indicators-block-item:not(:last-child) {
  border-right: 1px solid #eaeaea;
}

.rr-operation-indicators-block-item-label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 1700px) {
  .rr-operation-indicators-block.rr-operation-indicators-block-6el .rr-operation-indicators-block-item {
    width: 190px;
  }
}

@media (max-width: 1300px) {
  .rr-operation-indicators-block.rr-operation-indicators-block-6el .rr-operation-indicators-block-item {
    width: 33.3333%;
  }

  .rr-operation-indicators-block.rr-operation-indicators-block-6el .rr-operation-indicators-block-item:nth-child(1),
  .rr-operation-indicators-block.rr-operation-indicators-block-6el .rr-operation-indicators-block-item:nth-child(2),
  .rr-operation-indicators-block.rr-operation-indicators-block-6el .rr-operation-indicators-block-item:nth-child(3) {
    border-bottom: 1px solid #eaeaea;
  }

  .rr-operation-indicators-block.rr-operation-indicators-block-6el .rr-operation-indicators-block-item:nth-child(3) {
    border-right: none;
  }
}

@media (max-width: 1020px) {
  .rr-operation-indicators-block.rr-operation-indicators-block-4el .rr-operation-indicators-block-item, .rr-operation-indicators-block.rr-operation-indicators-block-6el .rr-operation-indicators-block-item {
    width: 50%;
  }

  .rr-operation-indicators-block.rr-operation-indicators-block-4el .rr-operation-indicators-block-item:nth-child(odd),
  .rr-operation-indicators-block.rr-operation-indicators-block-6el .rr-operation-indicators-block-item:nth-child(odd) {
    border-right: 1px solid #eaeaea;
  }

  .rr-operation-indicators-block.rr-operation-indicators-block-4el .rr-operation-indicators-block-item:nth-child(even),
  .rr-operation-indicators-block.rr-operation-indicators-block-6el .rr-operation-indicators-block-item:nth-child(even) {
    border-right: none;
  }

  .rr-operation-indicators-block.rr-operation-indicators-block-4el .rr-operation-indicators-block-item,
  .rr-operation-indicators-block.rr-operation-indicators-block-6el .rr-operation-indicators-block-item {
    border-bottom: 1px solid #eaeaea;
  }

  .rr-operation-indicators-block.rr-operation-indicators-block-4el .rr-operation-indicators-block-item:nth-child(3),
  .rr-operation-indicators-block.rr-operation-indicators-block-4el .rr-operation-indicators-block-item:nth-child(4),
  .rr-operation-indicators-block.rr-operation-indicators-block-6el .rr-operation-indicators-block-item:nth-child(5),
  .rr-operation-indicators-block.rr-operation-indicators-block-6el .rr-operation-indicators-block-item:nth-child(6) {
    border-bottom: none;
  }
}

@media (max-width: 540px) {
  .rr-operation-indicators-block.rr-operation-indicators-block-4el .rr-operation-indicators-block-item,
  .rr-operation-indicators-block.rr-operation-indicators-block-6el .rr-operation-indicators-block-item {
    width: 100%;
  }

  .rr-operation-indicators-block.rr-operation-indicators-block-4el .rr-operation-indicators-block-item:nth-child(odd),
  .rr-operation-indicators-block.rr-operation-indicators-block-6el .rr-operation-indicators-block-item:nth-child(odd) {
    border-right: none;
  }

  .rr-operation-indicators-block.rr-operation-indicators-block-4el .rr-operation-indicators-block-item,
  .rr-operation-indicators-block.rr-operation-indicators-block-6el .rr-operation-indicators-block-item {
    border-bottom: 1px solid #eaeaea;
  }
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';