.accessibility-elements-calendar-card {
    margin-top: 2px;

    .buttons-panel {
        display: flex;
        justify-content: right;
        position: absolute;
        width: 190px;
        z-index: 1;

        .column {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            gap: 16px;

            & > * {
                padding-right: 36px;
            }

            .buttons {
                display: flex;
                justify-content: end;
                gap: 12px;

                .anticon {
                    font-size: 22px !important;
                    margin: 0 !important;
                }
            }

            .elements-count {
                display: flex;
                flex-direction: column;
                align-items: end;
            }

            .ant-form-vertical {
                padding-right: 24px !important;

                .ant-form-item {
                    padding-bottom: 0 !important;
                    margin-bottom: 12px !important;

                    .rr-custom-select {
                        max-width: 160px !important;
                    }
                }
            }

            .rr-grid-smart_filters-toggle-btn {
                margin: 0 !important;
                margin-bottom: 12px !important;
            }
        }
    }

    .no-product-block-container {
        display: flex;
        padding-bottom: 24px;

        .no-product-block {
            flex-grow: 1;

            &.medium-gap {
                .rr-grid-notfound-block-message {
                    margin-top: 32px;
                }
            }
        }
    }
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';