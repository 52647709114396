@import '~antd/lib/style/themes/default';

@checkbox-prefix-cls: ~'@{ant-prefix}-checkbox';

// TODO Проверить размер шрифта и цвет шрифта для обычного состояния и для заблакированного

.@{checkbox-prefix-cls} {

  &-wrapper {
    user-select: none;
    font-feature-settings: normal;
    font-variant: normal;
  }

  &-inner:after {
    margin-top: -1px;
    margin-left: 1px;
  }

  & &-inner {
    border-radius: 4px;
    width: 18px;
    height: 18px;
    background-color: #e2e5ec;
    border-color: #e2e5ec;
  }

  &-checked &-inner {
    background-color: #383f77;
    border-color: #383f77;
  }

  &-disabled &-inner {
    background-color: #EAECF1;
    border-color: #EAECF1 !important;
  }

  &-checked &-inner:after, &-disabled&-checked &-inner::after {
    border-color: #fff;
  }

  &-wrapper:hover &-inner, &:hover &-inner, &-input:focus + &-inner {
    border-color: #e2e5ec;
  }

  &-checked:hover &-inner, &-checked:hover &-inner, &-checked &-input:focus + &-inner, &-checked::after, &-wrapper:hover &-checked &-inner {
    border-color: #383f77;
  }

  &-disabled:hover &-inner, &-disabled:hover &-inner, &-disabled &-input:focus + &-inner, &-disabled::after, &-wrapper:hover &-disabled &-inner {
    border-color: #EAECF1;
  }

  &-indeterminate &-inner, &-indeterminate:hover &-inner {
    background-color: #383f77;
    border-color: #383f77;
  }

  &-indeterminate &-inner::after {
    background-color: #fff;
    margin-top: 0;
    margin-left: 0;
    border-radius: 1px;
  }

  &-checked::after {
    border-radius: 4px;
  }
}

/* Checkbox */

.checkbox-custom.ant-checkbox-wrapper + span, .ant-checkbox + span {
  padding-left: 12px;
}

.checkbox-custom .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #fff;
  border-color: #fff;
}

.checkbox-custom .ant-checkbox-inner {
  background-color: #fff;
}

.checkbox-custom .ant-checkbox-checked .ant-checkbox-inner:after {
  border-color: #383e75;
}

.checkbox-custom.ant-checkbox-wrapper {
  background-color: #383e75;
  padding: 4px 10px 5px 10px;
  border-radius: 2px;
  min-width: 150px;
  color: #fff;
}

.checkbox-custom-green.ant-checkbox-wrapper {
  background-color: #21bfa4;
  color: #fff;
}

.checkbox-custom-yellow.ant-checkbox-wrapper {
  background-color: #ffb939;
  color: #fff;
}

.checkbox-custom-gray.ant-checkbox-wrapper {
  background-color: #c4c5d5;
  color: #383e75;
}

.rr-checkbox-group-vertical .ant-checkbox-wrapper {
  display: inline-block;
  margin-right: 24px;
}
