
.testButtonsGroup .ant-btn {
  margin-right: 16px;
  margin-bottom: 12px;
}

.inputs-example {
  //width: 265px;
}

.inputs-example > div, .inputs-example > div > div {

}

.ant-card.inputs-example .ant-card-body > div, .ant-card.inputs-example .ant-card-body > div.has-error > div {
  margin-bottom: 8px;
}

.ant-card.inputs-example {
  margin-bottom: 16px;
}

.icons-example .anticon {
  margin-right: 8px;
  font-size: 24px;
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';