.rr-product-page-features-view-card,
.rr-product-page-features-edit-card {
    max-width: 1000px;
    margin: 0 auto;
}

.rr-product-page-features-view-card .ant-card-head,
.rr-product-page-features-edit-card .ant-card-head {
    padding: 2px 0 2px 36px;
}

.rr-product-page-features-view-card .ant-card-head-title,
.rr-product-page-features-edit-card .ant-card-head-title {
    font-size: 24px;
    color: #575962;
    font-weight: 700;
    padding: 10px 0;
}

.rr-product-page-features-view-card .ant-card-extra {
    padding-right: 16px;
}

.rr-product-page-features-edit-card .ant-card-extra {
    padding-right: 36px;
}

.rr-product-page-features-view-card .ant-card-body {
    min-height: 494px;
}

.rr-product-page-features-view-header-edit-button.ant-btn .anticon {
    font-size: 22px;
    vertical-align: middle;
}

.rr-product-page-features-view-header-edit-button > .anticon + span {
    margin-left: 14px;
}

.rr-product-page-features-view-group-title {
    font-size: 18px;
    border-bottom: 1px solid #e4e5ec;
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 10px;

    display: flex;
    align-items: center;

    user-select: none;
}

.rr-product-page-features-view-group-title-collapse-icon {
    font-size: 22px;
    margin-right: 15px;
}

.rr-product-page-features-view-group-title-collapse-icon:hover {
    opacity: 0.7;
    cursor: pointer;
}

.rr-product-page-features-view-group-block {
    padding: 25px 10px 35px 64px;
}

.rr-product-page-features-view-group:not(:last-child) .rr-product-page-features-view-group-block {
    border-bottom: 1px solid #e4e5ec;
}

.rr-product-page-features-view-item {
    display: flex;
}

.rr-product-page-features-view-item:not(:last-child) {
    margin-bottom: 10px;
}

.rr-product-page-features-view-item-name {
    width: 50%;

    margin-bottom: 7px;
    position: relative;
}

.rr-product-page-features-view-item-name .test {
    position: relative;
    padding-right: 10px;
    background: inherit;
    z-index: 1;
    display: inline-block;
}

.rr-product-page-features-view-item-name .test > span {
    background-color: #ffffff;
}

.rr-product-page-features-view-item-name .test > span::after {
    content: '';
    display: inline-block;
    width: 5px;
    background-color: #ffffff;
}

.rr-product-page-features-view-item-name::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    height: 1px;
    top: 15px;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAMAAAABCAAAAAA+i0toAAAAAnRSTlMA/1uRIrUAAAAMSURBVHheY7j1/z8ABY8C2UtBe8oAAAAASUVORK5CYII=)
        0 0 repeat-x;
}

.rr-product-page-features-view-item-value,
.rr-product-page-features-view-item-value-empty {
    width: 50%;
    padding: 0 2px;
    font-size: 16px;
    font-weight: 700;
    color: #575962;
    word-break: break-word;
}

.rr-product-page-features-view-item-value-empty {
    font-weight: 400;
    color: #aaaaaa;
}

.grrr {
    margin-left: 63px - 25px - 4px;
    margin-bottom: 46px - 22px - 8px;
    max-width: 900px;
}

.grrr:first-child {
    margin-top: 19px;
}

.grrr:last-child {
    margin-bottom: 54px;
}

.rr-product-page-features-edit-group-title {
    display: flex;
    align-items: center;
    font-size: 18px;
    margin-bottom: 8px;
}

rr-product-page-features-edit-group-name,
.rr-product-page-features-edit-group-name-editable {
    margin-top: 6px;
    margin-bottom: 4px;
    margin-left: 15px;
    border-bottom: 1px dotted transparent;
}

.rr-product-page-features-edit-group-title:hover .rr-product-page-features-edit-group-name-editable {
    margin-left: 15px;
}

.rr-product-page-features-edit-group-name-editable {
    cursor: text;
    border-bottom: 1px dotted transparent;
}

.rr-product-page-features-edit-group-name-editable:hover {
    border-bottom: 1px dotted #575962;
}

.rr-product-page-features-edit-group-title-move-icons {
    width: 70px;
    height: 38px;
    display: flex;
    align-items: center;
}

///// Кнопка перемещения группы вверх/вниз
.rr-product-page-features-edit-group-title-move-icon-up,
.rr-product-page-features-edit-group-title-move-icon-up-disabled,
.rr-product-page-features-edit-group-title-move-icon-down,
.rr-product-page-features-edit-group-title-move-icon-down-disabled {
    font-size: 24px;
    color: #525a95;
    display: none;
}

.rr-is-touch-device .rr-product-page-features-edit-group-title-move-icon-up,
.rr-is-touch-device .rr-product-page-features-edit-group-title-move-icon-up-disabled,
.rr-is-touch-device .rr-product-page-features-edit-group-title-move-icon-down,
.rr-is-touch-device .rr-product-page-features-edit-group-title-move-icon-down-disabled {
    display: inline-block;
}

.rr-product-page-features-edit-group-title-move-icon-up:hover,
.rr-product-page-features-edit-group-title-move-icon-down:hover {
    color: lighten(#525a95, 20);
}

.rr-product-page-features-edit-group-title:hover .rr-product-page-features-edit-group-title-move-icon-up,
.rr-product-page-features-edit-group-title:hover .rr-product-page-features-edit-group-title-move-icon-up-disabled,
.rr-product-page-features-edit-group-title:hover .rr-product-page-features-edit-group-title-move-icon-down,
.rr-product-page-features-edit-group-title:hover .rr-product-page-features-edit-group-title-move-icon-down-disabled {
    display: inline-block;
}

.rr-product-page-features-edit-group-title-move-icon-up,
.rr-product-page-features-edit-group-title-move-icon-up-disabled {
    margin-top: 2px;
    margin-left: 11px;
}

.rr-product-page-features-edit-group-title-move-icon-down,
.rr-product-page-features-edit-group-title-move-icon-down-disabled {
    margin-top: -3px;
    margin-right: 9px;
}

.rr-product-page-features-edit-group-title-move-icon-up-disabled,
.rr-product-page-features-edit-group-title-move-icon-down-disabled {
    color: #c8c8c8;
}

///// Кнопка добавления поля
.rr-product-page-features-edit-group-title .rr-product-page-features-edit-group-title-add-icon {
    font-size: 21px;
    color: #34bfa3;
    margin-left: 13px;
    display: inline-block;
}

.rr-product-page-features-edit-group-title .rr-product-page-features-edit-group-title-add-icon:hover {
    color: lighten(#34bfa3, 10);
}

///// Кнопка удаления группы
.rr-product-page-features-edit-group-title .rr-product-page-features-edit-group-title-delete-icon {
    font-size: 21px;
    color: #f4516c;
    margin-left: 10px;
    display: none;
}

.rr-is-touch-device .rr-product-page-features-edit-group-title .rr-product-page-features-edit-group-title-delete-icon {
    display: inline-block;
}

.rr-product-page-features-edit-group-title .rr-product-page-features-edit-group-title-delete-icon:hover {
    color: lighten(#f4516c, 10);
}

.rr-product-page-features-edit-group-title:hover .rr-product-page-features-edit-group-title-delete-icon {
    display: inline-block;
}

.rr-product-page-features-edit-item-delete-icon {
    font-size: 21px;
    color: #f4516c;
    position: relative;
    top: 3px;
    margin-left: 10px;
}

.rr-product-page-features-edit-item-delete-icon:hover {
    color: lighten(#f4516c, 10);
}

.rr-product-page-features-edit-item-delete-icon-disabled.anticon {
    cursor: default;
    color: #c8c8c8;
}

.rr-product-page-features-edit-group-block-item {
    padding-left: 114px + 16px - 40px + 13px;
    margin-bottom: 0px;
    padding-bottom: 7px;
    position: relative;
}

.rr-product-page-features-edit-group-block-item::before {
    top: 8px;
    left: 94px + 13px;
    display: block;
    position: absolute;
    content: '';
    width: 6px;
    height: 10px;
    background: url('../../../../../../assets/images/drag.png');
}

.rr-product-page-features-edit-group-block-item-fieldName {
    font-size: 18px;
    font-weight: 700;
    margin-left: 30px;
}

.rr-product-page-features-edit-group-block-item-fielType {
    margin-left: 15px;
}

.rr-product-page-features-edit-group-block-item .rr-product-page-features-edit-item-delete-icon {
    display: none;
}

.rr-is-touch-device .rr-product-page-features-edit-group-block-item .rr-product-page-features-edit-item-delete-icon {
    display: inline-block;
}

.rr-product-page-features-edit-group-block-item:hover .rr-product-page-features-edit-item-delete-icon {
    display: inline-block;
}

.rr-product-page-features-edit-group-title-move-icon-thumb {
    font-size: 24px;
    margin-right: 27px;
    margin-left: -36px;
    color: #abacb0;
    opacity: 0;
}

.rr-product-page-features-edit-group-title-main:hover .rr-product-page-features-edit-group-title-move-icon-thumb {
    opacity: 1;
}

.ant-card.rr-product-page-features-view-card.ant-card-bordered .ant-card-head .ant-card-head-wrapper .ant-card-head-title {
    margin-top: 3px;
}

.ant-modal-header {
    padding: 25px 40px 24px 30px;
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';