.rr-abstract-entity-info-card .ant-card-body {
  //border-right: 5px solid #525A95;
}

.rr-abstract-entity-info-card .ant-card-body {
  position: relative;
}

.rr-abstract-entity-info-card .ant-card-body:before {
  content: ' ';
  background-color: #525A95;
  position: absolute;
  width: 5px;
  height: 100%;
  top: 0;
  right: 0;
  display: block;
}
@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';