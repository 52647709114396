@import '../../../../../../assets/styles/vars';

.modal-form-filters-container {
    display: flex;
    gap: 16px;
    justify-items: center;
    align-items: center;

    .title-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: start;

        .title {
            color: @rr-link-default-color;
            font-size: 14px;
            font-weight: 600;
        }

        .subtitle {
            color: @rr-link-default-color;
            font-size: 14px;
            font-weight: 500;
            cursor: pointer;
            text-decoration: underline;

            &:hover {
                color: @rr-link-hover-color;
            }
        }
    }

    .icon-sliders {
        font-size: 21px;
        cursor: pointer;
    }
}

.modal-form-filters-popover {
    padding: 3px 2px;
    display: flex;
    flex-direction: column;

    .radio-group {
        display: flex;
        flex-direction: column;

        .radio {
            display: block;
            height: 30px;
            line-height: 30px;
        }
    }
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';