@import '../../../assets/styles/vars';

.grid-dashboard .rr-grid-header {
    padding-top: 15px;
}

.grid-dashboard .rr-grid-header-left {
    margin-top: 0;
}
.backgroundColorHover:hover {
    opacity: 0.8;
}

.grid-dashboard .ant-card-body {
    padding-top: 0;
}

.dashboard-span-hover:hover {
    text-decoration: underline;
}

.dashboard-span-hover2:hover {
    text-decoration: underline;
}

.dashboard-last-action {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    padding: 0 15px;
}

.dashboard-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #525993;
    color: #fff;
    padding: 0 15px;
}

.grid-dashboard .ant-pagination {
    width: 410px;
}

.dashboard-main-grid {
    display: flex;
    justify-content: space-between;
}

.dashboard-grid-right {
    margin: 0 0 0 15px;
    padding: 0;
    flex-grow: 1;
    width: 45%;
}

.dashboard-grid-left {
    margin: 0 15px 0 0;
    padding: 0;
    flex-grow: 1;
    width: 45%;
}

.format-column-data {
    font-weight: 700;
}

.rr-dashboard-info-panel-div-right {
    display: flex;
    margin-top: 20px;
}

.rr-widget-panel-xl {
    width: calc(50% - 16px);
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    & > * {
        width: calc((100% - 40px) / 3);
    }
}

.rr-widget-wrapper {
    & > * {
        height: 100%;
    }
}

.rr-dashboard-info-panel-div .rr-dashboard-info-panel-icon,
.rr-dashboard-info-panel-icon {
    font-size: 22px;
    margin-right: 7px;
    margin-top: -3.5px;
    vertical-align: middle;
}

.rr-dashboard-info-panel-link:hover {
    cursor: pointer;
    opacity: @rr-link-hover-color-opacity;
}

.ant-card.grid-dashboard .ant-col.rr-grid-footer-right.ant-col-md-24.ant-col-lg-12.ant-col-xl-12 {
    margin-left: auto;
    width: 100px;
    margin-top: 3px;
}

.ant-card.grid-dashboard .ant-col.rr-grid-footer-left.ant-col-md-24.ant-col-lg-12.ant-col-xl-12 {
    width: 370px;
}

.ant-card.grid-dashboard .ant-row.rr-grid-footer {
    display: flex;
    flex-wrap: wrap-reverse;
}

.ant-card.grid-dashboard .ant-card-body {
    padding-bottom: 0;
}

.dashboard-grid-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0;
}

.dashboard-header-avatar-button {
    display: flex;
    margin-left: 32px;
    align-items: center;
    cursor: pointer;
    padding: 10px 12px;
    border-radius: 10px;
}

.dashboard-header-avatar-button-left:hover {
    background-color: rgba(252, 140, 47, 0.05);
}

.dashboard-header-avatar-button-right:hover {
    background-color: rgba(52, 191, 163, 0.05);
}

.dashboard-grid-header.dashboard-header-input-select .ant-select-selection {
    background-color: #f4f5f8;
    border: 2px solid #383f77 !important;
    color: #383f77;
}

.dashboard-grid-header.dashboard-header-input-select .ant-select-selection .ant-select-selection__clear {
    background-color: #f4f5f8;
}
.dashboard-grid-header.dashboard-header-input-select .ant-select-selection .ant-select-selection__clear:hover {
    position: absolute;
    z-index: 2;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    color: rgba(0, 0, 0, 0.65);
    line-height: 0;
}

.dashboard-grid-header.dashboard-header-input .ant-select-selection {
    background-color: #f4f5f8;
    border: 2px solid #f4f5f8 !important;
}

.dashboard-grid-header .ant-select-dropdown-menu-item-selected,
.ant-select-dropdown-menu-item-selected {
    color: #fff;
}

.dashboard-grid-header .dashboard-filters-buttons .dashboard-filters-button {
}

.grid-dashboard .rr-grid-counterpartyShortName-cell {
    color: @rr-link-default-color;
}

.grid-dashboard .rr-grid-counterpartyShortName-cell a {
    color: @rr-link-default-color;
    text-decoration: none;
}

.rr-dashboard-rent-indicators .rr-dashboard-info-panel-div {
    height: unset;
}

.rr-dashboard-rent-indicators .rr-dashboard-rent-indicators-block-value span {
    white-space: unset !important;
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';