
.ant-modal {
  top: 30px;
}

@media (max-width: 816px) {
  .ant-modal {
    max-width: calc(100vw - 17px);
    margin: 8px auto;
  }
}

.ant-modal-mask {
  background-color: rgba(61, 59, 86, 0.6);
}

.ant-modal-content {
  cursor: default;
  font-feature-settings: normal;
  font-variant: normal;
  box-shadow: 0 1px 15px 0 rgba(69, 65, 78, 0.08);
}

.ant-modal-body {
  padding: 25px;
}

.ant-modal-header {
  padding: 27px 40px 26px 30px;
  border-bottom: 2px solid #ebedf2;
}

.ant-modal-title {
  color: #575962;
  font-weight: bold;
  font-size: 21px;
}

.ant-modal-close, .ant-modal-close:focus {
  color: #c4c5d6;
}

.ant-modal-close:hover, .ant-modal-close:focus:hover {
  color: #575962;
}

.ant-modal-close-x {
  width: 54px;
  height: 76px;
  line-height: 78px;
  font-size: 21px;
}

.ant-modal-footer {
  padding: 20px 20px 20px 20px;
  border-top: 1px solid #ebedf2;
  background-color: #fff;
}