.rr-dateTimePicker .ant-calendar-footer {
  background-color: #f4f5f8;
  border-top: none;
  padding: 10px 26px;
}

.rr-dateTimePicker .ant-calendar-footer-extra {
  display: flex;
  align-items: center;
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';