.lableValueTable-valueDescription {
    display: flex;
    align-items: baseline;
    justify-content: flex-end;
    flex-wrap: wrap;

    & > * {
        width: max-content;
    }
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';