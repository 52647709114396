.rr-operationForm-elements-top-block {
  margin-right: -30px;
  margin-left: -30px;
  margin-top: -30px;
  border-top: 1px solid #eaeaea;
}

.rr-operationForm-elements-top-block input {
  font-size: 16px;
  font-weight: 500;
}

.rr-operationForm-elements-top-block .ant-calendar-picker .ant-calendar-range-picker-separator {
  margin-top: 1px;
}

.rr-form-item .rr-operationForm-elements-top-block__reset {
  opacity: 0;
  color: #ff4d4f;
  font-size: 22px;
  vertical-align: middle;
}

.rr-form-item .rr-operationForm-elements-top-block__reset:hover {
  color: lighten(#ff4d4f, 10);
}

.rr-form-item:hover .rr-operationForm-elements-top-block__reset {
  opacity: 1;
}

.rr-is-touch-device .rr-form-item .rr-operationForm-elements-top-block__reset {
  opacity: 1;
}

.rr-form-item:hover .rr-lock-icon__unlocked {
  opacity: .8;
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';