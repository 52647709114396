.rr-calendar-picker .ant-popover-arrow {
  display: none;
}

.rr-calendar-picker  .ant-popover-inner {
  box-shadow: unset;
  background: unset;
}

.rr-calendar-picker .ant-popover-inner-content {
  padding: 0;
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';