.sort-block {
  display: flex;
  align-items: center;

  .rr-grid-settings-icon {
    color: #575962;
    font-size: 22px;
    padding-bottom: 4px;
    margin-right: 8px;

    &:hover {
      opacity: 1;
    }
  }

  .text-regular {
    color: #575962
  }

  .text-bold {
    color: #383F77;
    font-weight: 700;
    margin-left: 9px;
    cursor: pointer;
  }
}

.rr-categories-header_popover-content {
  padding: 6px 2px;
  display: flex;
  flex-direction: column;

  .popup-header {
    color: #383F77;
    font-weight: 700;
    margin-bottom: 10px;
  }

  .radio-group {
    display: flex;
    flex-direction: column;

    .radio {
      display: block;
      height: 30px;
      line-height: 30px;
    }
  }
}
@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';