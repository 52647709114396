@import '~antd/lib/style/themes/default';

@btn-prefix-cls: ~'@{ant-prefix}-btn';

// Кнопка-ссылка, Новый тип, добавлено в antd 3.17.0
.ant-btn-link {
  border-width: 0;
  color: #525A95;
}

.ant-btn-link:hover {
  color: #383F77;
}

.ant-btn-link:active, .ant-btn-link.active {
  color: #383F77;
}

.ant-btn-link:focus {
  color: #525A95;
}

.ant-btn.disabled, .ant-btn[disabled] {
  cursor: default;
}

//////////////////////////////////

.ant-btn-round {
  font-size: 14px;
  font-weight: 500;
}

.@{btn-prefix-cls} {
  height: 38px;
  line-height: 38px;
  box-shadow: none;
  padding: 0 20px;
}

.@{btn-prefix-cls} .anticon {
  font-size: 15px;
  font-weight: bold;
  //position: relative;
  //top: 2px;
  //margin-left: -1px;
}

.@{btn-prefix-cls}.ant-btn-lg .anticon {
  font-size: 21px;
  position: relative;
  top: 2px;
  margin-left: -1px;
}



/*
.@{btn-prefix-cls} {
  border-color: #383f77;
}

.ant-btn-round.ant-btn-lg {
  font-size: 14px;
  font-weight: 500;
  padding: 0 21px;
}

.ant-btn-round.ant-btn-lg + .anticon {
  padding: 0 40px;
}

.ant-btn.ant-btn-round.ant-btn-lg .anticon {
  //font-size: 19px;
}

.ant-btn > .anticon + span, .ant-btn > span + .anticon {
  margin-left: 6px;
}
*/




.ant-btn.ant-btn-primary {
  border-width: 0;

  // Обычное состояние, Фокус
  &, &:focus {
    .custom-button-state(#525a95, #525a95, #ffffff);
  }
  // Навели
  &:hover {
    .custom-button-state(#383f77, #383f77, #ffffff);
  }
  // Нажали
  &:active {
    .custom-button-state(fade(#383f77, 75), fade(#383f77, 75), #ffffff);
  }
  // Заблокирована
  &[disabled], &[disabled]:hover, &[disabled]:active {
    .custom-button-state(#c4c5d6, #c4c5d6, #ffffff);
  }
}

.rr-btn-default {
  border-width: 0;

  &[ant-click-animating-without-extra-node]:after {
    animation: none;
  }
  // Обычное состояние, Фокус
  &, &:focus {
    .custom-button-state(none, transparent, #525a95);
  }
  // Навели
  &:hover {
    .custom-button-state(none, transparent, #383f77);
  }
  // Нажали
  &:active {
    .custom-button-state(none, transparent, fade(#383f77, 80));
  }
  // Заблокирована
  &[disabled], &[disabled]:hover, &[disabled]:active {
    .custom-button-state(none, transparent, fade(#c4c5d6, 80));
  }
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////
.custom-button-state(@backgroundColor: none, @borderColor: transparent, @textColor: #ffffff) {
  color: @textColor;
  background: @backgroundColor;
  border-color: @borderColor;
}

// Кастомные кнопки ///////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////

.rr-btn-action-archive.ant-btn.ant-btn-round.ant-btn-lg .anticon,
.rr-btn-action-prolong.ant-btn.ant-btn-round.ant-btn-lg .anticon,
.rr-btn-action-give.ant-btn.ant-btn-round.ant-btn-lg .anticon,
.rr-btn-action-cancel.ant-btn.ant-btn-round.ant-btn-lg .anticon {
  font-size: 21px;
  position: relative;
  top: 2px;
  margin-left: -1px;
}

.rr-btn-blue {
  border-width: 0;

  &[ant-click-animating-without-extra-node]:after {
    animation: none;
  }
  // Обычное состояние, Фокус
  &, &:focus {
    .custom-button-state(#42acec, transparent, #ffffff);
  }
  // Навели
  &:hover {
    .custom-button-state(fade(#42acec, 80), transparent, #ffffff);
  }
  // Нажали
  &:active {
    .custom-button-state(fade(#42acec, 90), transparent, #ffffff);
  }
  // Заблокирована
  &[disabled], &[disabled]:hover, &[disabled]:active {
    .custom-button-state(#c4c5d6, #c4c5d6, #ffffff);
  }
}
