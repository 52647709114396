.contact-references {
    width: min-content;
    display: flex;
    align-items: center;
    gap: 9px;
    font-size: 18px;

    &:not(.rr-0-value) {
        font-weight: 700;
        color: #525993;
        cursor: pointer;
        &:hover,
        &:focus {
            color: #525993;
        }
    }
}

.contact-references-link {
    text-decoration: underline;
    font-size: 14px;
    font-weight: 400;
}

.contact-references-content {
    padding: 16px;
    margin: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.contact-references-item {
    display: flex;
    justify-content: space-between;
}

.contact-references-value {
    font-weight: 700;
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';