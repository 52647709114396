.rr-popover .ant-popover-inner-content {
  padding: 0;
}

.rr-popover-header {
  min-height: 7px;
  //min-height: 54px;
  border-radius: 3px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-color: #525a95;
  color: #fff;
  padding: 0 16px;
  font-weight: 600;
  //-webkit-user-select: none;
  //user-select: none;
  display: flex;
  align-items: center;
}

.rr-popover.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow,
.rr-popover.ant-popover-placement-bottomLeft > .ant-popover-content > .ant-popover-arrow,
.rr-popover.ant-popover-placement-bottomRight > .ant-popover-content > .ant-popover-arrow {
  border-top-color: #525a95;
  border-left-color: #525a95;
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';