.rr-product-images {
  overflow: hidden;
  height: 70px;
  position: relative;
  user-select: none;
}

.rr-product-images-prev-btn {
  position: absolute;
  top: 50%;
  left: 0;
  width: 30px;
  height: 100%;
  //line-height: 134px;
  //background-color: #FFB822;
  text-align: center;
  cursor: pointer;
  margin-top: -14px;
}

.rr-product-images-next-btn {
  position: absolute;
  top: 50%;
  right: 0;
  width: 30px;
  height: 100%;
  //line-height: 134px;
  //background-color: #FFB822;
  text-align: center;
  cursor: pointer;
  margin-top: -14px;
}

.rr-product-images .rr-product-images-container {
  margin-right: 30px;
  margin-left: 30px;
}

.rr-product-images .rr-product-images-container > div {
  width: 105px;
  height: 70px;
  display: inline-block;
  border: 1px solid #e4e5ec;
  margin-right: 15px;
  overflow: hidden;
  cursor: pointer;
}

.rr-product-images > div > img {
  height: 100%;
  width: 100%;
}

.slick-list img {
  object-fit: contain;
  height: 70px;
  width: 105px;
  border: 1px solid #e4e5ec;
  cursor: pointer;
}

.slick-list img.currentImg {
  filter: grayscale(100%);
  opacity: .6;
  cursor: default;
}

.slick-prev, .slick-next {
  color: #575962 !important;
  font-size: 27px !important;
  width: 32px !important;
  height: 34px !important;
}

.slick-prev {
  left: -29px !important;
}

.slick-next {
  right: -30px !important;
}

.slick-prev.slick-disabled, .slick-next.slick-disabled {
  color: fade(#575962, 30%) !important;
}

.slick-slide img {
  display: inline-block !important;
}

.slick-slide > div > div {
  text-align: center;
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';