.rr-ProductCategoriesSelect-TreeSelect .ant-select-tree-node-selected,
.rr-ProductCategoriesSelect-TreeSelect .tree-node-highlight .tree-node-suppress-highlight .ant-select-tree-node-selected {
  background: #a8acca !important;
  padding-left: 8px !important;

  &:hover {
    color: #fff !important;
  }
}

.rr-ProductCategoriesSelect-TreeSelect .ant-select-tree-node-content-wrapper-open,
.rr-ProductCategoriesSelect-TreeSelect .ant-select-tree-node-content-wrapper-normal {
  padding-left: 8px !important;

  &:not(.ant-select-tree-node-selected):hover {
    color: rgba(0, 0, 0, 0.65) !important;
    background: #ebedf2 !important;
  }
}

.rr-ProductCategoriesSelect-TreeSelect .ant-select-selection__choice,
.rr-ProductCategoriesSelect .ant-select-selection__choice {
  font-weight: 400 !important;
}

.rr-ProductCategoriesSelect-TreeSelect .rr-ProductCategoriesSelect .ant-select-selection__rendered > :first-child {
  font-weight: 600 !important;
}

.rr-ProductCategoriesSelect-TreeSelect .rr-ProductCategoriesSelect-TreeSelect {
  font-weight: 400;
}

.rr-ProductCategoriesSelect-TreeSelect .ant-select-dropdown .ant-select-tree-title {
  padding-left: 8px !important;
}

.rr-ProductCategoriesSelect-TreeSelect .tree-node-highlight .ant-select-tree-node-selected {
  background: #383F77 !important;
  padding-left: 8px !important;

  &:hover {
    color: #fff !important;
  }
}
@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';