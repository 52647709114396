@import '../../../../../../assets/styles/vars';

.radio-buttons-group-container {
    display: flex;
    align-items: center;
    gap: 6px;

    .radio-buttons-group {
        display: flex !important;
        .ant-radio-button-wrapper {
            height: 38px !important;
            display: flex;
            align-items: center;
            justify-content: center;
            outline: none !important;
            border: 1px solid #dcdfe8 !important;
            padding: 0 8px !important;
            color: @rr-default-input-border-focus-color !important;
            box-shadow: none !important;

            &:first-child {
                border-radius: 4px 0 0 4px !important;
            }
            &:last-child {
                border-radius: 0 4px 4px 0;
            }
            &:not(:last-child) {
                border-right-width: 0 !important;
            }
            &.ant-radio-button-wrapper-checked {
                border-right-width: 2px !important;
            }
            &.ant-radio-button-wrapper-checked + .ant-radio-button-wrapper {
                border-left-width: 0 !important;
            }
            &:not(.ant-radio-button-wrapper-checked) {
                &:hover {
                    opacity: 0.8;
                }
            }
            &::before {
                content: none !important;
            }
        }
        .ant-radio-button-wrapper-checked {
            border: 2px solid #383f77 !important;
            font-weight: 600;
        }
    }

    .icon-close {
        font-size: 18px;

        &:hover {
            opacity: 0.8;
        }
    }
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';