@import '../../../assets/styles/vars';

.rr-settings-page__content-wrapper {
  background-color: #FFF;
}

.rr-settings-page__content-wrapper-header {
  min-height: 72px;
  padding: 0px 40px;
  border-bottom: 1px solid @rr-divider-color;
  display: flex;
  align-items: center;
}

.rr-settings-page__content-wrapper-header-title {
  display: inline-block;
  flex: 1 1;
  font-size: 21px;
  font-weight: 700;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.rr-settings-page__content-wrapper-header-extra {
  float: right;
  margin-left: auto;
}

.rr-settings-page__content-wrapper-body {
  padding: 24px 40px;
}

@media (max-width: 1199px) {
  .rr-settings-page__content-wrapper-header-extra .ant-btn-round.ant-btn-lg {
    padding: 0 10px;
    width: 40px;
  }
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';