.login-header .container {
    max-width: 1370px;
    width: 100%;
    margin: 0 auto;
    padding: 0 25px;
    position: relative;
}

@media screen and (min-width: 768px) {
    .auth-page.register .login-main {
        position: relative;
    }

    .auth-page.register .login-main:after {
        content: '';
        width: 100%;
        height: 555px;
        background-image: -moz-linear-gradient(-34deg, #7831b5 0%, #21d3fd 100%);
        background-image: -webkit-linear-gradient(-34deg, #7831b5 0%, #21d3fd 100%);
        background-image: -ms-linear-gradient(-34deg, #7831b5 0%, #21d3fd 100%);
        position: absolute;
        top: 0;
        left: 0;
    }

    .auth-page.register .login-main .section-register {
        position: relative;
        z-index: 1;
    }

    .auth-page.register .login-main .section-register .container {
        position: relative;
        z-index: 2;
    }

    .auth-page.register .login-main .section-register .container:after {
        content: '';
        width: 257px;
        height: 311px;
        position: absolute;
        background-image: url('../../../../modules/companyRegistration/assets/images/register-img-1.png');
        bottom: 20px;
        right: 50%;
        transform: translateX(-119%);
    }

    .auth-page.register .login-main .section-register .container:before {
        content: '';
        width: 234px;
        height: 343px;
        position: absolute;
        background-image: url('../../../../modules/companyRegistration/assets/images/register-img-2.png');
        bottom: 20px;
        left: 50%;
        transform: translateX(136%);
    }

    .auth-page.register .login-main .section-register .container .form-wrap {
        position: relative;
        z-index: 1;
    }

    .auth-page.register .login-main .section-register:after {
        content: '';
        width: 338px;
        height: 293px;
        position: absolute;
        background-image: url('../../../../modules/companyRegistration/assets/images/register-triangle-1.png');
        top: 1px;
        right: 32px;
    }

    .auth-page.register .login-main .section-register .geometry-1 {
        content: '';
        width: 215px;
        height: 371px;
        position: absolute;
        background-image: url('../../../../modules/companyRegistration/assets/images/register-geometry-1.png');
        top: 400px;
        left: 0;
    }

    .auth-page.register .login-main .section-register .geometry-2 {
        content: '';
        width: 658px;
        height: 577px;
        position: absolute;
        background-image: url('../../../../modules/companyRegistration/assets/images/register-geometry-2.png');
        top: 150px;
        right: 0;
    }

    .auth-page.register {
        background-image: -webkit-linear-gradient(-34deg, #7831b5 0%, #21d3fd 100%) !important;
    }
}

@media screen and (max-width: 1600px) {
    .auth-page.register .login-main .section-register .geometry-1 {
        left: auto;
        right: 1400px;
    }

    .auth-page.register .login-main .section-register .geometry-2 {
        right: auto;
        left: 960px;
    }
}

.auth-page {
    background-color: #fff;
    -webkit-background-size: 100% auto;
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: center 90px;
}

.login-header .header_wrap {
    height: 90px;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    justify-content: center;
}

.login-main {
    position: relative;
}

.section-bg {
    position: absolute;
    width: 100%;
    height: 654px;
    z-index: -1;
}

.login-footer {
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #fff;
    text-align: center;
}

.login-footer .copyright {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    height: 160px;
    -ms-align-items: center;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    font-family: 'Montserrat', sans-serif;
    color: #383f4d;
}

.section-register {
    padding-top: 70px;
}

.section-register .section-title {
    text-align: center;
    font-size: 34px;
    color: #fff;
    font-weight: 600;
}

.section-register .form-wrap {
    background: #fff;
    width: 655px;
    max-width: 100%;
    margin-top: 30px;
    margin-right: auto;
    margin-left: auto;
    box-shadow: 0px 0px 27px 0px rgba(0, 0, 0, 0.13);
    padding: 70px 112px 56px;
    border-radius: 8px;
    margin-bottom: -20px;
    transition: all 0.5s ease;
}

.section-register .btn-green {
    display: block;
    border: none;
    background-color: rgb(61, 206, 142);
    color: #fff;
    height: 50px;
    width: 230px;
    border-radius: 25px;
    padding: 0;
    font-size: 16px;
    margin-right: auto;
    margin-left: auto;
    cursor: pointer;
    outline: none;
    font-family: 'Montserrat', sans-serif;
}

.section-register .btn-green.disabled {
    pointer-events: none;
    background-color: #b1b1b1;
}

.login-header {
    position: relative;
    z-index: 10;
}

@media screen and (max-width: 767px) {
    .login-main {
        padding-bottom: 0;
    }

    .login-footer {
        display: none;
    }

    .auth-page {
        background: #fff !important;
    }

    .login-header {
        border-bottom: 1px solid #d1d4da;
    }

    .login-header .header_wrap {
        height: 46px;
    }

    .login-header .logo {
        width: 134px;
    }

    .login-header .logo picture,
    .login-header .logo img {
        width: 100%;
        height: auto;
    }

    .section-register .section-title {
        font-size: 20px;
        color: #3a6bdc;
    }

    .section-register {
        padding-top: 48px;
    }

    .section-register .form-wrap {
        padding: 0 28px;
        box-shadow: none;
        margin-bottom: 42px;
    }

    .section-register .input-wrap {
        margin-bottom: 24px;
    }

    .section-register .btn-green {
        width: 100%;
        font-size: 14px;
    }
}

.rr-option-registration-menu {
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #383f4d;
    font-size: 16px;
    font-family: 'Montserrat', sans-serif;
}

.company-registration-dropdown-menu
    .ant-select-dropdown-menu.ant-select-dropdown-menu-root.ant-select-dropdown-menu-vertical
    .rr-option-registration-menu:hover {
    background-color: #5897fb;
    color: #fff;
}

.company-registration-dropdown-menu .ant-select-dropdown-menu-item-selected {
    background-color: #d1d4da;
    color: #383f4d;
}

.company-registration-dropdown-menu .ant-select-dropdown-menu-item-selected,
.company-registration-dropdown-menu .ant-select-dropdown-menu-item-selected:hover {
    color: #383f4d;
}

.company-registration-dropdown-menu {
    .ant-select-dropdown-menu-item-active {
        color: #fff;
    }
}

.company-registration-dropdown-menu .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
    background-color: #d1d4da;
}

.auth-page.register .rr-select-registration-open .ant-select.ant-select-enabled .ant-select-selection {
    border-bottom: 1px solid #fff;
    border-radius: 5px 5px 0px 0px;
}

.company-registration-dropdown-menu.ant-select-dropdown {
    box-shadow: none;
}

.auth-page.register .ant-select-selection-selected-value {
    display: flex;
    justify-content: center;
    width: 100%;
    color: #383f4d;
}

.auth-page.register .main-wrapper .ant-select-selection,
.auth-page.register .main-wrapper .ant-input {
    border-radius: 5px;
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';