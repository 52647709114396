@import "./../../assets/styles/vars";

.rr-barcode-popover-error-message {
  font-size: 11px;
  color: @rr-colors-red-1;
}

.rr-barcode-popover-image-code_128 {
  min-height: 240px + 40px;
}

.rr-barcode-popover-image-data_matrix {
  min-height: 240px + 40px;
}

.rr-barcode-popover-image-qr_code {
  min-height: 239px + 40px;
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';