.info-select-dropdown {
    user-select: none;
    padding-left: 21px;

    svg {
        width: 32px;
        height: 32px;
        color: #04b0f1;
    }

    &:hover {
        opacity: 0.8;
        cursor: pointer;
    }
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';