.ant-time-picker-panel-select ul {
    padding: 0 0 0;
    width: 68px;
}

.ant-time-picker-panel-select li {
    height: 28px;
    padding: 0 0 0 24px;
}

.ant-time-picker-panel-select {
    max-height: 224px;
    width: 68px;
}

.ant-time-picker-panel {
    width: 137px !important;
}

.ant-time-picker-panel-select {
}

.time-picker-icon {
    color: rgba(0, 0, 0, 0.25);
    right: 16px;
}

.rr-time-picker-panel .ant-time-picker-panel-inner {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
}
