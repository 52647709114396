.rr-statuses-filter {
    .ant-select-selection--multiple {
        cursor: default;
    }

    .calendar-multi-select {
        display: none;
    }

    // TODO Используется в списке смет, нужно мб какой-то отдельный компонент сделать
    .rr-state-badge-text {
        display: none;
    }
}

.ant-select-search__field {
    cursor: default;
}

.rr-statuses-filter .ant-select-selection--multiple > ul > li,
.rr-statuses-filter .ant-select-selection--multiple .ant-select-selection__rendered > ul > li {
    height: 24px;
    line-height: 24px;
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';