.rr-attachments-block .rr-simple-table-row {
    min-height: 36px;
    padding-top: 1px;
    padding-bottom: 1px;
}

.rr-attachments-block_attachment-name {
    font-weight: 600;
}

.rr-attachments-block_attachment-name-link a {
    color: #343f9a;
    font-weight: 600;
}

.rr-attachments-block_attachment-name-link a:hover {
    color: #343f9a;
    opacity: .8;
    text-decoration: underline;
}

.rr-attachments-block_attachment-error {
    font-size: 11px;
    color: #f4516c;
    margin-top: 2px;
}

.rr-attachments-block .rr-simple-table-cell > div {
    -webkit-line-clamp: unset;
}

.rr-attachments-block.rr-collapsible-block.ant-collapse .ant-collapse-item:last-child > .ant-collapse-content > .ant-collapse-content-box {
    padding-top: 22px;
    padding-bottom: 22px;
}
