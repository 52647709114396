.ReactTableV7.ReactTable {
    overflow: auto;
}

.ReactTableV7 .rt-table {
    overflow: unset;
}

.ReactTableV7 .resizer {
    display: inline-block;
    width: 8px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateX(50%);
    z-index: 1;
    touch-action: none;
    background: #bccced;
    border-right: 3px solid #f4f5f8;
    border-left: 3px solid #f4f5f8;
    opacity: 0.3;
}

.ReactTableV7 .rt-thead .rt-th:last-child .resizer {
    right: 3px;
}

.ReactTableV7 .rt-thead:hover .resizer {
    opacity: 0.75;
}

.ReactTableV7 .rt-thead:hover .resizer.isResizing,
.ReactTableV7 .resizer.isResizing {
    background: #bccced;
    opacity: 1;
}

.ReactTableV7 .rt-thead {
    border-left: 3px solid #f4f5f8;
    border-right: 3px solid #f4f5f8;
}

.ReactTableV7 .rt-tr-group .rt-tr.-odd {
    border-left: 3px solid #fff;
    border-right: 3px solid #fff;
}

.ReactTableV7 .rt-tr-group .rt-tr.-even {
    border-left: 3px solid #f8f9fa;
    border-right: 3px solid #f8f9fa;
}

.ReactTableV7 .rt-tr-group .rt-tr:hover {
    border-left: 3px solid #ebedf2;
    border-right: 3px solid #ebedf2;
}

//
.ReactTableV7 .rt-tr-group .rr-grid-warning-row,
.ReactTableV7 .rt-tr-group .rr-grid-warning-row.-odd {
    border-left: 3px solid #fff4de;
    border-right: 3px solid #fff4de;
}

.ReactTableV7 .rt-tr-group .rr-grid-warning-row.-even {
    border-left: 3px solid #ffeabe;
    border-right: 3px solid #ffeabe;
}

//
.ReactTableV7 .rt-tr-group .rr-grid-error-row,
.ReactTableV7 .rt-tr-group .rr-grid-error-row.-odd {
    border-left: 3px solid #fff3f4;
    border-right: 3px solid #fff3f4;
}

.ReactTableV7 .rt-tr-group .rr-grid-error-row.-even {
    border-left: 3px solid #fde4e6;
    border-right: 3px solid #fde4e6;
}

// .ReactTableV7 .rt-tr-group .rr-grid-warning-row
.ReactTableV7 .rt-tr-group.rt-tr-group-expanded .rt-tr {
    //border-left: 3px solid #ebedf2 !important;
    //border-right: 3px solid #ebedf2 !important;
    //border-left: 3px solid #f4516c;
    //border-right: 3px solid #f4516c;
    border-color: #bdc4d4;
}

.ReactTableV7 .rt-tr-group.rt-tr-group-expanded .rt-tr:first-child {
    border-top: 3px solid #bdc4d4;
}

.ReactTableV7 .rt-tr-group.rt-tr-group-expanded .rt-tr:last-child {
    border-bottom: 3px solid #bdc4d4;
}

.rt-table .rt-td.actions,
.rt-table .rt-th.actions {
    margin-left: auto;
}

.rt-table-scroll-left,
.rt-table-scroll-right {
    width: 50px;
    height: 100%;
    position: absolute;
    z-index: 1;
    opacity: 0;
    pointer-events: none;
    user-select: none;
    transition: opacity 0.4s;
}

.rt-table-scroll-left {
    left: 0;
}

.rt-table-scroll-right {
    right: 0;
}

.rt-table-scroll-left-shadow,
.rt-table-scroll-right-shadow {
    position: absolute;
    // height: 100%;
    width: 50%;
    top: 0;
    bottom: 10px;
}

.rt-table-scroll-left-shadow {
    background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
}

.rt-table-scroll-right-shadow {
    right: 0;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
}

.rt-table-pinned-columns-left {
    .rt-table-scroll-left-shadow {
        background: linear-gradient(90deg, #e7e7e7 0%, rgba(255, 255, 255, 0) 100%);
        width: 20%;
    }
}

.rt-table-pinned-columns-right {
    .rt-table-scroll-right-shadow {
        width: 20%;
        background: linear-gradient(90deg, rgba(255, 255, 255, 0), #e7e7e7);
    }
}

.rt-table-scroll-left-button,
.rt-table-scroll-right-button {
    position: absolute;
    width: 50px;
    height: 100px;
    background: #393f7738;
    color: #ffffff;
    font-size: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.3s; // , top .005s;
}

.rt-table-scroll-left:hover .rt-table-scroll-left-button,
.rt-table-scroll-right:hover .rt-table-scroll-right-button {
    background: #393f77c9;
}

.rt-table-scroll-left-button {
    left: 0;
    border-radius: 0 100px 100px 0;
}

.rt-table-scroll-right-button {
    right: 0;
    border-radius: 100px 0 0 100px;
}

.rt-table-scroll-left.rt-table-scroll-visible,
.rt-table-scroll-right.rt-table-scroll-visible {
    opacity: 1;
    cursor: pointer;
    pointer-events: unset;
}

.rt-table-scroll-left.rt-table-scroll-visible,
.rt-table-scroll-right.rt-table-scroll-visible {
    opacity: 1;
    cursor: pointer;
    pointer-events: unset;
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';