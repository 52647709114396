.rr-project-pay-modal .ant-input-number {
  width: 250px;
}

.ant-modal-wrap .rr-modal-pay.ant-modal .ant-modal-content .ant-modal-body {
  margin: 0px;
  padding: 0px;
}

.rr-operationForm-page-request input, .rr-operationForm-page-request .ant-select-selection-selected-value {
  font-size: 16px;
  font-weight: 500;
}

.rr-project-pay-modal .ant-input-number {
  width: 260px;
}

.rr-project-pay-modal .ant-input-number .ant-input-number-input {
  font-weight: 600;
  font-size: 16px;
}

.rr-modal-pay .ant-collapse-content {
  background-color: #f8f8f8;
}
@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';