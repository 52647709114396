.rr-add-btn.ant-btn {
  display: inline-flex;
  align-items: center;
  padding: 0;
  color: #575962;
}

.rr-add-btn.ant-btn:hover {
  color: #575962;
  opacity: .75;
}

.rr-add-btn.ant-btn:disabled,
.rr-add-btn.ant-btn:disabled:hover,
.rr-add-btn.ant-btn:disabled .anticon,
.rr-add-btn.ant-btn:disabled:hover .anticon {
  color: #aaaaaa;
  opacity: 1;
}

.rr-add-btn.ant-btn .anticon {
  color: #34bfa3;
  font-size: 26px;
  line-height: 0;
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';