@arrow-color: #6c6e86;
@error-color: rgba(244, 82, 109, 0.4);

// Выпадающий список /////////////////////////
.ant-select-dropdown {
    color: rgba(0, 0, 0, 0.65);
    //box-shadow: 0 2px 20px rgba(0, 0, 0, .3);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    //
    border-radius: 0;
}

.ant-select-dropdown-menu-item:first-child,
.ant-select-dropdown-menu-item:last-child {
    border-radius: 0;
}

.ant-select-dropdown-menu-item-active,
.ant-select-dropdown-menu-item:hover {
    background-color: #f4f5f8;
}

.ant-select-dropdown-menu-item-selected,
.ant-select-dropdown-menu-item-selected:hover {
    color: #ffffff;
    font-weight: 400;
    background-color: #525a95;
}

.ant-select-dropdown-menu-item-disabled {
    color: rgba(0, 0, 0, 0.25);
    cursor: default;
}

.ant-select-dropdown-menu-item-disabled:hover {
    color: rgba(0, 0, 0, 0.25);
    background-color: #fff;
    cursor: default;
}

.ant-select-dropdown-menu-item-selected.ant-select-dropdown-menu-item-disabled,
.ant-select-dropdown-menu-item-selected.ant-select-dropdown-menu-item-disabled:hover {
    color: #ffffff;
    font-weight: 400;
    background-color: #525a95;
}

.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item .ant-select-selected-icon {
    display: none;
}

.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item-selected .ant-select-selected-icon,
.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item-selected:hover .ant-select-selected-icon {
    //display: inline-block;
    display: none;
    color: #1890ff;
}

.ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
    background-color: #f4f5f8;
}

.ant-select-dropdown-menu-item-selected:hover:not(.ant-select-dropdown-menu-item-disabled) {
    background-color: #525a95;
}

.ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
    background-color: #f4f5f8;
}

.ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled).ant-select-dropdown-menu-item-selected {
    background-color: #525a95;
}

//  /////////////////////////

.ant-select {
    color: @rr-default-input-text-color;

    font-feature-settings: normal;
    font-variant: normal;
}

.ant-select-selection__placeholder,
.ant-select-search__field__placeholder {
    color: @rr-default-input-placeholder-color;
    font-weight: @rr-default-input-placeholder-font-weight;
}

.ant-select-disabled .ant-select-selection__placeholder {
    color: @rr-default-input-disabled-placeholder-color;
}

.ant-select-disabled {
    color: @rr-default-input-disabled-text-color;
}

.ant-select-disabled .ant-select-selection {
    cursor: default;
}

.ant-select-selection--single {
    height: 38px;
}

.ant-select-selection__rendered {
    line-height: 34px;
}

.ant-select-selection {
    border: 1px solid darken(#ebedf2, 5%);
}

.ant-select-selection--single .ant-select-selection__rendered {
    margin-right: 32px;
}

.ant-select-selection__clear {
    //background-color: transparent;
    font-size: 20px;
    color: #868aa8;
    width: 20px;
    height: 20px;
    //opacity: 1;
    margin-top: -10px;
    right: 8px;
}

.ant-select-selection--multiple .ant-select-selection__clear {
    top: 18px;
}

.ant-select-selection:hover {
    border-color: @rr-default-input-border-hover-color;
}

.ant-select-focused .ant-select-selection,
.ant-select-selection:focus,
.ant-select-selection:active {
    border-color: @rr-default-input-border-focus-color;
    box-shadow: none;
}

.ant-select-disabled .ant-select-selection:hover,
.ant-select-disabled .ant-select-selection:focus,
.ant-select-disabled .ant-select-selection:active {
    border-color: #f4f5f8;
    background-color: #f4f5f8;
}

.has-error .ant-select-selection__placeholder {
    color: rgba(244, 82, 109, 0.8);
}

.has-error .ant-select-search__field__placeholder {
    color: rgba(244, 82, 109, 0.8);
}

.has-error .ant-select .ant-select-selection {
    border: 1px solid @error-color;
    color: #f4516c;
}

.has-error .ant-select .ant-select-selection:hover {
    border-color: darken(@error-color, 20%);
}

.has-error .ant-select.ant-select-focused .ant-select-selection,
.has-error .ant-select.ant-select-focused:hover .ant-select-selection {
    border-color: #f4526d;
}

.has-error .ant-select-disabled .ant-select-selection:hover,
.has-error .ant-select-disabled .ant-select-selection:focus,
.has-error .ant-select-disabled .ant-select-selection:active {
    border-color: @error-color;
}

.has-error .ant-select-arrow {
    color: #f4516c;
}

// #F4526D

.has-error .ant-select-open .ant-select-selection,
.has-error .ant-select-focused .ant-select-selection {
    box-shadow: none;
}

.ant-select-arrow {
    color: @arrow-color;
    font-size: 15px;
    right: 10px;
    margin-top: -7px;
}

// multiple //////////////////////

.ant-select-selection--multiple {
    min-height: 38px;
}

.ant-select-selection--multiple > ul > li,
.ant-select-selection--multiple .ant-select-selection__rendered > ul > li {
    margin-top: 5px;
}

.ant-select-selection--multiple .ant-select-selection__choice {
    border-radius: 4px;
    color: rgba(87, 89, 98, 0.8); //#575962;
    border: 1px solid #ebedf2;
    background-color: #ebedf2;
    font-weight: 400;
    padding: 0 20px 0 7px;
}

.ant-select-selection--multiple > ul > li,
.ant-select-selection--multiple .ant-select-selection__rendered > ul > li {
    height: 28px;
    line-height: 28px;
    //margin-top: 7px;
    margin-right: 5px;
}

:root .ant-select-selection--multiple .ant-select-selection__choice__remove {
    font-size: 16px;
    top: 1px;
    color: rgba(87, 89, 98, 0.8);
}

.ant-select-disabled .ant-select-selection:hover,
.ant-select-disabled .ant-select-selection:focus,
.ant-select-disabled .ant-select-selection:active {
    border-color: #dcdfe8;
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Кастомный серый Селект //////////////////////////////////////////////////////////////////////////////////

.rr-select-round .ant-select-selection {
    border-radius: 18px;
}

/////////

.rr-select-gray .ant-select-selection {
    border: 1px solid #f4f5f8;
    background-color: #f4f5f8;
}

.rr-select-gray .ant-select-selection:hover {
    border-color: #f4f5f8;
    background-color: #f4f5f8;
}

.rr-select-gray.ant-select-focused .ant-select-selection,
.rr-select-gray.ant-select-selection:focus {
    border-color: #f4f5f8;
    box-shadow: none;
}

.rr-select-gray.ant-select-disabled .ant-select-selection,
.rr-select-gray.ant-select-disabled .ant-select-selection:hover,
.rr-select-gray.ant-select-disabled .ant-select-selection:focus,
.rr-select-gray.ant-select-disabled .ant-select-selection:active {
    border-color: fade(#f4f5f8, 70%);
    background-color: fade(#f4f5f8, 70%);
}

.has-error .rr-select-gray .ant-select-selection {
    border: 1px solid #f4516c;
    color: #f4516c;
}

.rr-select-gray .ant-select-selection__clear {
    background-color: #f4f5f8;
}

.rr-select-gray.ant-select-disabled .ant-select-arrow {
    color: fade(@arrow-color, 30%);
}
