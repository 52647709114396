.popover-problem-line {

  height: 40px;
  background-color: rgb(244, 81, 108);
  display: flex;
  align-items: center;

  color: #fff;
  flex-grow: 1;
  flex: 50%;
  padding-left: 33px;
  //cursor: pointer;
}

.popover-warning-line {
  margin-top: 4px;
  height: 40px;
  background-color: #FC8C2F;
  display: flex;
  align-items: center;
  flex: 50%;
  color: #fff;
  flex-grow: 1;
  padding-left: 33px;
  //cursor: pointer;
}


.popover-problem-line .icon, .popover-warning-line .icon {
  font-size: 20px;
  margin-bottom: 3px;
  margin-right: 6px;
}

.popover-problem-line .icon-cube, .popover-warning-line .icon-cube {
  font-size: 20px;
  margin-bottom: 3px;
  margin-right: 6px;
  margin-left: 18px;
}


.popover-problem-line .icon-cube2, .popover-warning-line .icon-cube2 {
  font-size: 20px;
  margin-bottom: 3px;
  margin-right: 6px;

}

.popover-problem-line span, .popover-warning-line span{
  font-size: 14px;
  color: #fff;
  margin: 0px 5px;
  margin-right: 0px;
}

.popover-problem-line .popover-problem-line-span-comma, .popover-warning-line .popover-problem-line-span-comma  {
  margin-left: -3px;
}

.popover-problem-line .popover-problem-line-span, .popover-warning-line .popover-problem-line-span {
  font-weight: 600;
}

.popover-problem-line .popover-problem-line-span:hover, .popover-warning-line .popover-problem-line-span:hover {
  text-decoration: none;
}

.popover-problem-line span:hover, .popover-warning-line span:hover  {
  text-decoration: underline;
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';