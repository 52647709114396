.rr-operationForm-elementsList .ReactTable .rt-tbody .rt-td .new-value {
  color: #343f9a;
}

.rr-operationForm-elementsList .rr-operationForm-elementsList-variant .anticon {
  opacity: 0;
  margin-left: 6px;
  font-size: 22px;
  vertical-align: middle;
  margin-top: -4px;
}

.rr-operationForm-elementsList .rr-operationForm-elementsList-variant:hover .anticon {
  opacity: 1;
}

.rr-operationForm-elementsList .rr-operationForm-elementsList-leftover {
  padding-left: 26px;
}

.rr-operationForm-elementsList .rr-operationForm-elementsList-leftover .anticon {
  opacity: 0;
}

.rr-operationForm-elementsList .rr-operationForm-elementsList-leftover:hover .anticon {
  opacity: 1;
}

.rr-operationForm-elementsList .rr-operationForm-elementsList-leftover:hover .anticon:hover {
  opacity: .8;
}

.rr-operationForm-elementsList .rr-operationForm-elementsList-leftover--removeIcon {
  font-size: 22px;
  vertical-align: middle;
  color: #F4516C;
  margin-left: 4px;
  cursor: pointer;
}

.rr-operationForm-elementsList .rr-operationForm-elementsList-leftover--leaveIcon {
  font-size: 22px;
  vertical-align: middle;
  color: #34BFA3;
  margin-left: 4px;
  cursor: pointer;
}

.rr-is-touch-device .rr-operationForm-elementsList .rr-operationForm-elementsList-leftover .anticon {
  opacity: 1;
}

.rr-operationForm-elementsList-filters-item {
  margin-right: 30px;
}
@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';