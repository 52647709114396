.tag-select-container {
    display: flex;
    align-items: center;
    gap: 12px;
    width: 100%;
    max-width: 500px;

    .tag-select {
        flex: 1;
        max-width: 350px;

        .ant-select-selection__choice {
            display: flex;
            align-items: center;

            .ant-select-selection__choice__remove {
                top: 0 !important;
                height: 100%;
            }
        }
    }

    .input {
        cursor: text;
    }
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';