.grid-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    .grid-card {
        padding-bottom: 0;
        padding-top: 0;

        .ant-card-head-title {
            padding: 6px 0 7px 0;
        }
    }

    .grid-footer {
        border-top: 1px solid #e8e8e8;
        width: 100%;

        .save-buttons-container {
            flex-grow: 1;

            .ant-card-body {
                display: flex;
                flex-direction: row-reverse;
            }
        }
    }

    .adaptive-width {
        width: 100%;

        @media only screen and (min-width: 1350px) {
            width: 1200px;
        }
    }
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';