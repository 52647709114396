.ant-popover-inner-content:has(.rr-simple-popover-content) {
    padding: 0;
    max-width: 400px;
}

.rr-simple-popver-button {
    cursor: pointer;
}

.rr-simple-popover-header {
    font-size: 18px;
    padding: 12px 24px;
    border-bottom: 1px solid #eaeaea;
    display: flex;
    align-items: center;
    gap: 16px;

    svg {
        font-size: 22px;
    }
}

.rr-simple-popover-list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    max-height: 400px;
    overflow: auto;
}

.rr-simple-popover-list-item {
    padding: 8px 16px;
    &:nth-child(2n) {
        background-color: #f8f9fa;
    }
}

.rr-pledge-popover-audit-info {
    display: flex;
    gap: 4px;
    & > span {
        display: block;
        width: max-content;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &:nth-child(1) {
            width: 100px;
        }
        &:nth-child(2) {
            width: 140px;
        }
        &:nth-child(3) {
            width: 100px;
            font-weight: 600;
        }
    }
}

.rr-pledge-popover-audit-description {
    font-weight: 600;
    word-break: break-all;

    &.rr-value-muted {
        font-weight: 400;
    }
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';