@import '../../../assets/styles/vars';

.download-document-popover-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 20px 24px;

    .content {
        display: flex;
        flex-direction: column;

        .title {
            position: relative;
            font-weight: 600;
            margin-bottom: 12px;

            .icon {
                position: absolute;
                font-size: 24px;
                color: #f4516c;
                margin-left: 6px;
                margin-top: -1px;
                cursor: pointer;
                user-select: none;
            }
        }

        .ant-btn {
            padding: 0;
            height: unset;
            line-height: 1;
            transition: all 0.3s;

            & > .anticon + span {
                margin-left: 10px;
            }
            &:hover {
                background-color: unset;
            }
            .anticon {
                transition: all 0.3s;
            }
            &:not([disabled]):hover .anticon {
                opacity: 0.8;
            }
        }

        .content-checkbox-group {
            width: 100%;

            .ant-checkbox-wrapper:not(:last-child) {
                margin-bottom: 8px;
            }

            .ant-checkbox-wrapper:hover {
                opacity: 0.85;
            }
        }

        .exports {
            display: flex;
            flex-direction: column;
            gap: 18px;
            margin-top: 4px;

            &.compact {
                flex-direction: row;
            }
        }

        .period {
            display: flex;
            align-items: center;

            .dates {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 24px;
                border: 1px solid #dcdfe8;
                border-radius: 4px;
                height: 38px;
                width: 100%;
                cursor: pointer;

                * {
                    user-select: none;
                }
            }

            .icon {
                font-size: 22px;
                padding-left: 12px;
                cursor: pointer;
                user-select: none;
            }
        }
    }
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';