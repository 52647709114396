@error-color: rgba(244, 82, 109, 0.4);
@icon-color: #6c6e86;
@gray-input-bg: #f4f5f8;

.ant-input {
    textarea& {
        min-height: 38px;
        padding: 8px 14px 6px 14px;
    }

    &,
    .ant-select-auto-complete.ant-select & {
        border: 1px solid @rr-default-input-border-color;
        height: 38px;
        color: @rr-default-input-text-color;
        padding: 4px 14px;
        font-feature-settings: normal;
        font-variant: normal;
        transition: background-color 0s;
    }

    &-affix-wrapper {
        line-height: 35px;
    }

    &-affix-wrapper &-suffix {
        right: 9px;
    }

    &::placeholder,
    .ant-select-auto-complete.ant-select &::placeholder {
        color: @rr-default-input-placeholder-color;
        font-weight: @rr-default-input-placeholder-font-weight;
    }

    &-affix-wrapper:hover &:not(&-disabled) {
        border-color: @rr-default-input-border-hover-color; // TODO Не понятно какой цвет должен быть
    }

    &:hover,
    .ant-select-auto-complete.ant-select &:hover {
        border-color: @rr-default-input-border-hover-color; // TODO Не понятно какой цвет должен быть
    }

    &:focus,
    .ant-select-auto-complete.ant-select &:focus {
        box-shadow: none;
        border-color: #575962; //@rr-default-input-border-hover-color;
    }

    &:focus:hover {
        //box-shadow: none;
        border-color: #575962; //@rr-default-input-border-hover-color;
    }

    &-disabled {
        cursor: default;
    }

    &[disabled] {
        color: @rr-default-input-disabled-text-color;
        background-color: #f4f5f8;
        cursor: default;
    }

    &[disabled]:hover,
    .ant-select-auto-complete.ant-select &[disabled]:hover {
        border-color: @rr-default-input-border-disabled-color;
    }

    &[disabled]::placeholder,
    .ant-select-auto-complete.ant-select &[disabled]::placeholder {
        color: @rr-default-input-disabled-placeholder-color;
        font-weight: @rr-default-input-placeholder-font-weight;
    }

    &-disabled:hover {
        border-color: @rr-default-input-border-disabled-color;
    }

    &-affix-wrapper &-prefix .anticon,
    &-affix-wrapper &-suffix .anticon {
        color: @icon-color;
    }

    &-affix-wrapper &-suffix .anticon.ant-input-clear-icon {
        color: #868aa8;
    }

    &-affix-wrapper &-prefix:hover .anticon,
    &-affix-wrapper &-suffix:hover .anticon {
        color: lighten(@icon-color, 10%);
    }

    &-affix-wrapper .ant-input-disabled + .ant-input-suffix .anticon {
        color: @rr-default-input-disabled-text-color;
    }

    // TODO Может в другое место перенести, в формы например
    .has-error &,
    .has-error &:hover {
        border-color: @error-color;
        color: rgba(244, 82, 109, 0.8);
    }

    .has-error &:focus {
        border-color: #f4526d;
    }

    .has-error &:not([disabled]):focus:hover {
        border-color: #f4526d;
    }

    .has-error &::placeholder {
        color: rgba(244, 82, 109, 0.8);
    }

    .has-error &-disabled,
    .has-error &-disabled:hover {
        border-color: @error-color;
        color: @error-color;
    }

    .has-error &-affix-wrapper &-prefix .anticon,
    .has-error &-affix-wrapper &-suffix .anticon {
        color: @error-color;
    }

    .has-error &-affix-wrapper &:not(&-disabled) + &-suffix .anticon:hover {
        color: lighten(@error-color, 10%);
    }

    .has-error &-affix-wrapper:hover &:not(&-disabled) {
        border-color: @error-color;
    }

    .has-error &:not([disabled]):hover {
        border-color: darken(@error-color, 20%);
    }

    .has-error &:focus {
        box-shadow: none;
    }

    &-search &-search-icon {
        transform: scale(-1, 1);
    }

    // При быстром нажатии на иконку происходит выбор текста
    &-search &-suffix {
        user-select: none;
    }

    // Убираем курсор руки на иконке на заблокированном поле
    &-disabled + &-suffix * {
        cursor: not-allowed;
    }

    &-clear-icon {
        //margin-right: 4px;
        font-size: 20px;
    }
}

.ant-select-auto-complete.ant-select .ant-select-selection,
.has-error .ant-select .ant-select-selection {
    height: 38px;
}

.has-error .ant-select-auto-complete.ant-select .ant-input {
    border: none !important;
}

// Кастомный инпут rr-input-gray
.ant-input {
    &.rr-input-gray,
    .rr-input-gray > & {
        background-color: @gray-input-bg;
        border: 1px solid @gray-input-bg;
    }

    &-affix-wrapper.rr-input-gray:hover &:not(&-disabled) {
        border-color: @gray-input-bg; // TODO Не понятно какой цвет должен быть
    }

    &.rr-input-gray:hover,
    .rr-input-gray > &:hover {
        border-color: @gray-input-bg; // TODO Не понятно какой цвет должен быть
    }

    &.rr-input-gray:focus,
    .rr-input-gray > &:focus {
        box-shadow: none;
        border-color: @gray-input-bg;
    }

    &-disabled.rr-input-gray:hover,
    .rr-input-gray > &-disabled:hover {
        border-color: @gray-input-bg; // TODO Этот ли цвет? Проверить
    }

    .has-error &.rr-input-gray,
    .has-error &.rr-input-gray:hover,
    .has-error .rr-input-gray > &,
    .has-error .rr-input-gray > &:hover {
        border-color: @error-color;
        color: @error-color;
    }

    .has-error &-affix-wrapper.rr-input-gray:hover &:not(&-disabled) {
        border-color: @error-color;
    }
}

.rr-input-gray .ant-calendar-picker-clear {
    background: @gray-input-bg;
}

.rr-input-gray.ant-calendar-picker:hover .ant-calendar-picker-input:not(.ant-input-disabled) {
    border-color: @gray-input-bg;
}

/////////////////////////////////////////////////////////////////////////
// Кастомное поле поиска, с большой иконкой слева
//.rr-custom-search .ant-input-prefix {
//right: 0;
//left: 9px;
//display: block;
//width: 0;
//}

.ant-input-affix-wrapper.rr-custom-search .ant-input {
    padding-left: 36px;
    padding-right: 28px;
}

.rr-custom-search .ant-input-prefix .anticon {
    font-size: 20px;
}

.rr-custom-search .ant-input:not(.ant-input-disabled) + .ant-input-suffix:hover .anticon {
    color: @icon-color;
}

.rr-custom-search .ant-input:not(.ant-input-disabled) + .ant-input-suffix:hover .anticon.ant-input-clear-icon {
    color: darken(#868aa8, 20%);
}

//.has-error .rr-custom-search .ant-input:not(.ant-input-disabled) + .ant-input-suffix:hover .anticon {
//  color: @error-color;
//}
