@import "../../assets/styles/calendar-vars";

.rr-range-calendar {
    .ant-calendar-body {
        overflow: hidden;
    }

    .ant-calendar-footer-extra {
        float: none;
        background-color: #f4f5f8;
        padding: 16px 12px;
        display: flex;
    }

    .ant-calendar-footer {
        padding: 0;
    }

    .ant-calendar-footer .ant-calendar-time-picker-btn,
    .ant-calendar-footer .ant-calendar-ok-btn {
        display: none;
    }

    .ant-calendar-footer {
        border-top: none;
    }

    .rr-input-number-light {
        margin-right: 12px;
    }
    .rr-input-number-light:nth-child(2n) {
        margin-right: 28px;
    }

    .rr-input-number-light:nth-child(4n) {
        margin-right: 0;
    }
}

.rr-range-calendar-with-intervals .rr-calendar-date-amount-badge {
    position: absolute;
    height: 16px;
    min-width: 16px;
    color: #ffffff;
    font-size: 11px;
    border-radius: 20px;
    padding: 0 2px;
    line-height: 14px;
    font-weight: 500;
    text-align: center;
    border: 1px solid #fff;
    transform: translateX(-50%);
    left: 0;
    top: -8px;
    z-index: 1;
}

.rr-range-calendar-with-intervals .rr-calendar-date-available,
.rr-range-calendar-with-intervals .rr-calendar-date-unavailable,
.rr-range-calendar-with-intervals .rr-calendar-date-shortage,
.rr-range-calendar-with-intervals .rr-calendar-date-zero,
.rr-range-calendar-with-intervals .rr-calendar-date-stock {
    color: fade(#000, 65%);
    border-radius: 2px;
}

.rr-range-calendar-with-intervals .rr-calendar-date-available + .rr-calendar-date-amount-badge {
    background-color: #21bfa4;
}

.rr-range-calendar-with-intervals .rr-calendar-date-rent + .rr-calendar-date-amount-badge {
    background-color: #ffb822;
}

.rr-range-calendar-with-intervals .rr-calendar-date-unavailable + .rr-calendar-date-amount-badge {
    background-color: #cdced0;
}

.rr-range-calendar-with-intervals .rr-calendar-date-shortage + .rr-calendar-date-amount-badge {
    background-color: #f4516c;
}

.rr-range-calendar-with-intervals .rr-calendar-date-stock + .rr-calendar-date-amount-badge {
    background-color: #383e75;
}

.rr-range-calendar-with-intervals .rr-calendar-date-zero + .rr-calendar-date-amount-badge {
    background-color: #cdced0;
}

.rr-range-calendar-with-intervals .rr-calendar-date-available {
    background-color: #99e6db;
    color: rgba(0, 0, 0, 0.65);
}

.rr-range-calendar-with-intervals .rr-calendar-date-rent {
    background-color: #fff1d5;
    color: rgba(0, 0, 0, 0.65);
}

.rr-range-calendar-with-intervals .rr-calendar-date-unavailable {
    background-color: #ededed;
    color: rgba(0, 0, 0, 0.65);
}

.rr-range-calendar-with-intervals .rr-calendar-date-shortage {
    background-color: #fcb9c4;
    color: rgba(0, 0, 0, 0.65);
}

.rr-range-calendar-with-intervals .rr-calendar-date-stock {
    background-color: #e2e6fd;
    color: rgba(0, 0, 0, 0.65);
}

.rr-range-calendar-with-intervals .rr-calendar-date-zero {
    color: rgba(0, 0, 0, 0.65);
}

.rr-range-calendar-with-intervals .ant-calendar-disabled-cell.ant-calendar-today .ant-calendar-date::before {
    display: none;
}

.rr-range-calendar-with-intervals .ant-calendar-date,
.rr-range-calendar-with-intervals .ant-calendar-disabled-cell .ant-calendar-date {
    width: 30px;
}

.rr-range-calendar-with-intervals .ant-calendar-disabled-cell.ant-calendar-today .ant-calendar-date {
    padding: 0;
}

.rr-range-calendar-with-intervals .ant-calendar-disabled-cell .ant-calendar-date {
    background-color: transparent;
}

.rr-range-calendar-with-intervals.ant-calendar-range .ant-calendar-in-range-cell::before {
    background: @rr-calendar-range-bg;
}

// Когда календарь в гриде или в списке экземпляров
.rr-range-calendar-with-intervals.ant-calendar-range.rr-range-calendar-without-selection .ant-calendar-in-range-cell::before {
    background: #cdcdcd;
}

.rr-range-calendar-with-intervals .ant-calendar-cell.ant-calendar-selected-end-date::before,
.rr-range-calendar-with-intervals .ant-calendar-cell.ant-calendar-selected-start-date::before {
    background: @rr-calendar-selected-date-bg;
}

// Когда календарь в гриде или в списке экземпляров
.rr-range-calendar-with-intervals.rr-range-calendar-without-selection .ant-calendar-cell.ant-calendar-selected-end-date::before,
.rr-range-calendar-with-intervals.rr-range-calendar-without-selection .ant-calendar-cell.ant-calendar-selected-start-date::before {
    background: #a4a4a4;
}

// Когда календарь в гриде или в списке экземпляров
.rr-range-calendar-with-intervals.rr-range-calendar-without-selection.ant-calendar-range
    .ant-calendar-selected-start-date
    .ant-calendar-date,
.rr-range-calendar-with-intervals.rr-range-calendar-without-selection.ant-calendar-range
    .ant-calendar-selected-end-date
    .ant-calendar-date {
    background: #a4a4a4;
}

.rr-range-calendar-with-intervals .ant-calendar-in-range-cell .rr-calendar-date-available,
.rr-range-calendar-with-intervals .ant-calendar-in-range-cell .rr-calendar-date-unavailable,
.rr-range-calendar-with-intervals .ant-calendar-in-range-cell .rr-calendar-date-shortage,
.rr-range-calendar-with-intervals .ant-calendar-selected-start-date .rr-calendar-date-available,
.rr-range-calendar-with-intervals .ant-calendar-selected-start-date .rr-calendar-date-unavailable,
.rr-range-calendar-with-intervals .ant-calendar-selected-start-date .rr-calendar-date-shortage,
.rr-range-calendar-with-intervals .ant-calendar-selected-end-date .rr-calendar-date-available,
.rr-range-calendar-with-intervals .ant-calendar-selected-end-date .rr-calendar-date-unavailable,
.rr-range-calendar-with-intervals .ant-calendar-selected-end-date .rr-calendar-date-shortage {
    color: #383e75;
}

.rr-range-calendar-with-intervals .ant-calendar-selected-start-date .ant-calendar-date:hover,
.rr-range-calendar-with-intervals .ant-calendar-selected-end-date .ant-calendar-date:hover {
    background: @rr-calendar-selected-date-bg1 !important;
}

.rr-range-calendar-with-intervals .ant-calendar-cell:not(.ant-calendar-disabled-cell) .ant-calendar-date:hover {
    background: none;
}

.rr-range-calendar-with-intervals .ant-calendar-today .rr-calendar-date-available,
.rr-range-calendar-with-intervals .ant-calendar-today .rr-calendar-date-unavailable {
    height: 26px;
}

.rr-range-calendar-with-intervals .ant-calendar-today .rr-calendar-date-shortage {
    height: 26px;
}

.rr-range-calendar-with-intervals .ant-calendar-disabled-cell.ant-calendar-selected-day .ant-calendar-date::before {
    display: none;
}

.rr-range-calendar-with-intervals:not(.rr-range-calendar-without-selection) .ant-calendar-disabled-cell .rr-calendar-date-available {
    color: rgba(0, 0, 0, 0.25);
}

.ant-calendar-my-select {
    line-height: 62px;
}

.ant-calendar-cell.ant-calendar-selected-end-date::before, .ant-calendar-cell.ant-calendar-selected-start-date::before {
    top: 0;
    bottom: 0;
}

.ant-calendar-range .ant-calendar-in-range-cell::before {
    top: 0 !important;
    bottom: 0 !important;
}

.ant-calendar-cell.ant-calendar-selected-end-date::before, .ant-calendar-cell.ant-calendar-selected-start-date::before {
    top: 0 !important;
    bottom: 0 !important;
}
@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';