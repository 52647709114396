.rr-users-list-invite-icon {
  font-size: 20px;
  color: #34bfa3;
}

.rr-users-list-remove-icon {
  font-size: 20px;
  color: #F4516C;
}

.rr-users-list-edit-icon {
  font-size: 21px;
  color: #525891;
}

.rr-users-list-invite-icon:hover, .rr-users-list-remove-icon:hover, .rr-users-list-edit-icon:hover {
  opacity: .8;
}
@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';