.rr-color-1 {
  color: #525a95;
}

.rr-icon-20 {
  font-size: 20px;
  vertical-align: middle;
  //margin-bottom: 2px;
}

.rr-icon-22 {
  font-size: 22px;
  vertical-align: middle;
  //margin-bottom: 2px;
}

.rr-icon-24 {
  font-size: 24px;
  vertical-align: middle;
}

.rr-icon-26 {
  font-size: 26px;
  vertical-align: middle;
}

.rr-icon-30 {
  font-size: 30px;
  vertical-align: middle;
}
