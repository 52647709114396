.rr-table-action-buttons-group {
  display: inline-flex;
  width: 100%;
}

.rr-table-action-buttons-group-cell {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';