.rr-input-number-inline.rr-input-number.ant-input-number .ant-input-number-input-wrap {
  width: unset;
}

.rr-input-number-inline.rr-input-number.ant-input-number .ant-input-number-handler-up-inner,
.rr-input-number-inline.rr-input-number.ant-input-number .ant-input-number-handler-down-inner {
  color: #575962;
}

.rr-input-number-inline.rr-input-number.ant-input-number .ant-input-number-handler:hover .ant-input-number-handler-up-inner,
.rr-input-number-inline.rr-input-number.ant-input-number .ant-input-number-handler:hover .ant-input-number-handler-down-inner {
  color: #575962;
}

.rr-input-number-inline.rr-input-number.ant-input-number .ant-input-number-input-wrap {
  //background-color: #CCCCCC;
}

.rr-input-number-inline.rr-input-number.ant-input-number .ant-input-number-handler-down-disabled .ant-input-number-handler-down-inner,
.rr-input-number-inline.rr-input-number.ant-input-number .ant-input-number-handler-up-disabled .ant-input-number-handler-up-inner,
.rr-input-number-inline.rr-input-number.ant-input-number .ant-input-number-handler-down-disabled:active .ant-input-number-handler-down-inner,
.rr-input-number-inline.rr-input-number.ant-input-number .ant-input-number-handler-up-disabled:active .ant-input-number-handler-up-inner,
.rr-input-number-inline.rr-input-number.ant-input-number .ant-input-number-handler-down-disabled:hover .ant-input-number-handler-down-inner,
.rr-input-number-inline.rr-input-number.ant-input-number .ant-input-number-handler-up-disabled:hover .ant-input-number-handler-up-inner {
  color: #575962;
  opacity: .2;
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';