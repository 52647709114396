.rr-categories-category-card {
    // overflow-y: hidden; //auto;
    border-left: 1px solid #f0f2f5;
    border-right: 1px solid #f0f2f5;
    border-bottom: 1px solid #f0f2f5;
}

.rr-categories-category-card .ant-card-body {
    padding: 32px;
    padding-top: 30px;
    padding-bottom: 25px;

    padding: 0;
    height: 100%;
}

.rr-categories-category-card .ant-card-body .ant-spin-nested-loading,
.rr-categories-category-card .ant-card-body .ant-spin-nested-loading .ant-spin-container {
    height: 100%;
}

.rr-categories-category-card .rr-form-group {
    //margin-left: 0px;
    //margin-right: 0px;
    padding-top: 0;
}

.ant-btn.rr-categories-edit-category-button,
.ant-btn.rr-categories-delete-category-button,
.ant-btn.rr-categories-add-subcategory-button {
    padding: 0;
    font-weight: 500;
    color: #575962;
}

.ant-btn.rr-categories-edit-category-button:disabled,
.ant-btn.rr-categories-delete-category-button:disabled,
.ant-btn.rr-categories-add-subcategory-button:disabled {
    color: #ccc;
}

.ant-btn.rr-categories-edit-category-button .anticon,
.ant-btn.rr-categories-delete-category-button .anticon,
.ant-btn.rr-categories-add-subcategory-button .anticon {
    font-size: 20px;
    vertical-align: middle;
}

.ant-btn.rr-categories-add-subcategory-button .anticon {
    color: #34bfa3;
}

.ant-btn.rr-categories-edit-category-button .anticon {
    color: #00c5dc;
}

.ant-btn.rr-categories-delete-category-button .anticon {
    color: #f4516c;
}

.ant-btn.rr-categories-add-subcategory-button:hover .anticon,
.ant-btn.rr-categories-edit-category-button:hover .anticon,
.ant-btn.rr-categories-delete-category-button:hover .anticon {
    opacity: 0.8;
}

.ant-btn.rr-categories-add-subcategory-button:disabled .anticon,
.ant-btn.rr-categories-edit-category-button:disabled .anticon,
.ant-btn.rr-categories-delete-category-button:disabled .anticon {
    opacity: 0.65;
}

.ant-btn.rr-categories-edit-category-button > .anticon + span,
.ant-btn > span + .anticon,
.ant-btn.rr-categories-delete-category-button > .anticon + span,
.ant-btn > span + .anticon,
.ant-btn.rr-categories-add-subcategory-button > .anticon + span,
.ant-btn > span + .anticon {
    margin-left: 16px;
}

.rr-categories-category-block-row {
    margin-bottom: 8px;
}

.rr-categories-header {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 36px;

    margin-bottom: 9px;
}

.rr-categories-info-block {
    display: flex;
    align-items: center;
    margin-left: -4px;
    margin-bottom: 12px;
    gap: 8px;
}

.rr-categories-exclamation-icon {
    color: #383f77;
    font-size: 26px;
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';