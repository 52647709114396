
.ant-upload-list-picture .ant-upload-list-item-progress {
  bottom: 16px;
  width: calc(100% - 44px - 8px);
  padding-left: 8px;
}

.ant-upload-list-picture {
  margin-right: -16px;
  margin-left: -16px;
  margin-top: -16px + 20px;
  margin-bottom: -16px;
  min-height: 16px+16px - 20px;
}

.ant-upload-list-picture .ant-upload-list-item, .ant-upload-list-picture-card .ant-upload-list-item {


}

.ant-upload-list-picture-card .ant-upload-list-item {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  border-width: 0;
}

.ant-upload.ant-upload-select-picture-card {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  //border-width: 0;
}


.ant-upload-list-picture .ant-upload-list-item, .ant-upload-list-picture-card .ant-upload-list-item {

  padding: 0;
  overflow: hidden;
  //height: 66px;
  padding-right: 22px;
  padding-left: 22px;
  margin-top: 22px;
  margin-bottom: 22px;

  border-radius: 4px;
  border-width: 0;
  height: 260px;
  float: left;
  width: 50%;


  height: 160px;
  width: 29%;
  //padding-right: 0;
  //padding-left: 0;
  //margin-top: 0;
  //margin-bottom: 0;

  padding-right: 0;
  padding-left: 0;
  margin-right: 16px;
  margin-left: 16px;
  margin-top: 16px;
  margin-bottom: 16px;
}

.ant-upload-list-picture .ant-upload-list-item-name, .ant-upload-list-picture-card .ant-upload-list-item-name {
  display: none;
}

.ant-upload-list-item-card-actions .anticon-download {
  display: none;
}

.ant-upload-list-item-card-actions.picture {
  top: 0;
}

:root .ant-upload-list-item .anticon-delete {
  font-size: 20px;
  background-color: #383e78;
  padding: 10px 20px;//15px 27px;
  border-radius: 0 6px 0 4px;
  padding-bottom: 10px;
  color: #ffffff;
}

///////
 .ant-upload-list-item-thumbnail,  .ant-upload-list-item-thumbnail img {
  position: static !important;
  display: block !important;
  width: 100% !important;
  height: 100% !important;
}

 .ant-upload-list-picture {
   width: 100%;
 }

.ant-upload-list-picture .anticon-close, .ant-upload-list-picture-card .anticon-close {
  top: -4px;
  right: -5px;
  color: #bcc2e2;
}

.ant-upload-list-item .anticon-close:hover {
  color: #bcc2e2;
}

.ant-upload-list-item-info {
  border-radius: 4px;
  overflow: hidden;
  background-color: #eaeaea;
}

.ant-upload-list-picture .ant-upload-list-item-thumbnail {
  opacity: 1;
  position: absolute !important;//unset !important;
  width: 100% !important;
  height: 100% !important;
  //top: 0;
 // left: 22px;
}

.ant-upload-list-item-thumbnail img {
  object-fit: cover;
}

.ant-upload-list-picture .ant-upload-list-item-thumbnail, .ant-upload-list-picture-card .ant-upload-list-item-thumbnail {
  top: 0;
  left: 0;
  border-radius: 4px;
}