.calendar-legend {
    .dot {
        width: 6px;
        height: 6px;
        border-radius: 3px;
        margin-top: 7px;
        margin-left: 6px;
        background-color: #fff;
    }

    .line {
        width: 32px;
        height: 20px;
        margin-right: 14px;
        border-radius: 2px;
    }

    .subrent-line {
        width: 32px;
        height: 20px;
        margin-right: 14px;
        border-radius: 10px;
    }

    .instance {
        margin: 0;
        padding-top: 24px;
        padding-bottom: 0;
    }

    .instance-row {
        margin: 0;
        padding: 24px 32px 0;
    }

    .variant {
        min-height: 325px;
    }
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';