@import '../../../assets/styles/vars';

.warning-list {
    .title {
        font-weight: 600;
        margin-bottom: 12px;
    }

    .warnings {
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding-top: 4px;
        line-height: 1.5;

        .warning-item {
            display: inline-block;

            i {
                position: relative;
                top: 2px;
                margin-right: 6px;

                svg {
                    width: 21px;
                    height: 21px;
                    color: @rr-color-warning;
                }
            }
        }
    }

    .warnings--danger .warning-item i svg {
        color: @rr-color-danger;
    }
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';