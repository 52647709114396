.rr-modal-concurrent-operations-confirm .rr-modal-confirm-content {
  padding: 0;
}

.rr-modal-concurrent-operations-confirm .rr-btn-concurrent-operations-confirm, .rr-modal-concurrent-operations-confirm .rr-btn-concurrent-operations-confirm:hover, .rr-modal-concurrent-operations-confirm .rr-btn-concurrent-operations-confirm:focus {
  border: 1px solid #f4516c;
  height: 38px;
  line-height: 36px;
  color: #f4516c;
}

.rr-modal-concurrent-operations-confirm .rr-btn-concurrent-operations-confirm:hover {
  opacity: .75;
}
@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';