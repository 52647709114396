.rr-base-categories-select .ant-select-selection__choice {
  font-weight: 400 !important;
}

.rr-base-categories-select_parent-category-description {
  color: #a6a8b0;
  font-size: 11px;
  line-height: 14px;
  margin-top: 8px
}
@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';