.rr-archive-badge {
  color: #fff;
  font-size: 11px;
  background-color: #c4c5d6;
  font-weight: 500;
  text-align: center;
  padding: 4px 16px;
  border-radius: 3px;
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';