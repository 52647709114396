.calendar-interval {
    display: flex;
    justify-content: center;
    margin: 10px 0;

    .rr-calendar-nav-button {
        font-size: 16px;
        border-radius: 0;
        border: none;
        padding: 5px 20px;
        height: 35px;
        cursor: pointer;
        background-color: #f4f5f8;
        color: #383f77;
    }

    .rr-calendar-nav-button.rr-calendar-nav-button-selected {
        background-color: #525a95;
        color: #fff;
        font-weight: 600;
    }
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';