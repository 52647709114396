@import './../../../../assets/styles/vars';

@telegram-primary-color: #229ED9;

.telegram-link-container {
    display: flex;
    gap: 10px;
    align-items: center;

    .telegram-link-icon {
        color: @telegram-primary-color;
        font-size: 28px;
        vertical-align: middle;
        margin-top: -2px;
    }

    .telegram-link-text {
        font-size: 21px;
    }

    .telegram-link {
        color: @telegram-primary-color;
        text-decoration: underline;

        &:hover {
            color: fade(@telegram-primary-color, @rr-link-hover-color-opacity-percents);
        }
    }
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';