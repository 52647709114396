.calendar-timeline {
    &.splitted {
        .rct-sidebar {
            .rct-sidebar-row {
                align-items: start !important;
            }
        }
        .react-calendar-timeline {
            .rct-sidebar {
                .rct-sidebar-row {
                    overflow: visible;
                }
            }
        }

        .rct-sidebar {
            .rct-sidebar-row {
                padding-right: 4px !important;
            }
        }

        .sidebar-group-container {
            width: 100%;
            background-color: #f2f3f8;
            padding: 2px 16px;
            border-radius: 2px;

            &.compact {
                .sidebar-group {
                    padding-top: 0;
                }
            }

            .sidebar-group {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 12px;
                padding-top: 4px;

                .titles-container {
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    flex-grow: 1;
                    max-width: 170px;

                    &.compact {
                        * {
                            height: 20px;
                            font-size: 11px;
                        }

                        .subtitle {
                            top: 20px;
                        }
                    }

                    * {
                        max-width: 100%;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }

                    span {
                        font-size: 14px;
                        line-height: 14px;
                    }

                    .title {
                        color: #383f77;
                        font-weight: 600;
                        line-height: 20px;

                        * {
                            color: #383f77;
                        }
                    }

                    .subtitle {
                        position: absolute;
                        top: 24px;
                        left: 0;
                    }

                    .link {
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }

                .calendar-group-item-collapse-icon {
                    margin-left: 0 !important;
                }
            }
        }
    }
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';