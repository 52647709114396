.rr-contactEdit-links {
  //background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);
}

.rr-contactEdit-links-link {
  font-size: 21px;

}

.rr-contactEdit-links-link-facebook {

}

.rr-contactEdit-links-link-vk {

}

.rr-contactEdit-links-link-instagram {

}

.rr-contactEdit-links-link-telegram {

}

.rr-contactEdit-links-link-site {

}


.rr-contactEdit-links-link .mmmm {
  background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);
  -webkit-background-clip: text;
  /* Also define standard property for compatibility */
  //background-clip: ;
  -webkit-text-fill-color: transparent;
}
@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';