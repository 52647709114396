.rr-modal-confirm .ant-modal-body {
  padding: 0;
}

.rr-modal-confirm .ant-modal-confirm-title {
  padding: 0;
  color: #575962;
  font-size: 21px;
  font-weight: bold;
}

.rr-modal-confirm .ant-modal-confirm-content {
  margin: 0;
}

.rr-modal-confirm .ant-modal-confirm-btns {
  margin-top: 0px;
  padding: 20px;
}

.rr-modal-confirm .rr-modal-confirm-title, .rr-modal-confirm .rr-modal-confirm-content {
  border-bottom: 1px solid #ebedf2;
  padding: 30px;
  display: block;
}

.rr-modal-confirm .rr-modal-confirm-title {
  padding: 23px 30px;
}

.rr-modal-confirm .rr-btn-cancel-secondary {
  border: 1px solid #525a95;
  line-height: 36px;
}

.rr-modal-confirm .rr-btn-cancel-secondary:hover {
  border: 1px solid #383f77;
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';