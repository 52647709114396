.sliders-range-manual {
    position: relative;

    .anchor {
        position: absolute;
        min-width: 30px;
        height: 20px;
        top: -28px;
        padding: 0 6px;

        cursor: text !important;
        z-index: 2;

        * {
            visibility: hidden;
        }

        &.left {
            opacity: 0.4;
            left: 0;
        }

        &.right {
            opacity: 0.4;
            right: 0;
        }
    }

    .input-container {
        position: absolute;
        top: -35px;
        width: 100%;
        height: 1px;
        padding: 0 6px;

        .inline-number-input {
            animation: 0.1s fadeIn ease-out;

            &.left {
                left: -21px;
            }

            &.right {
                right: -21px;
            }
        }
    }

    .ant-slider-mark-text {
        z-index: 1;
    }

    .ant-slider-dot {
        visibility: hidden;
    }
}

.disable-left-mark {
    .ant-slider-mark {
        > .ant-slider-mark-text:nth-child(1) {
            display: none !important;
        }
    }
}

.disable-right-mark {
    .ant-slider-mark {
        > .ant-slider-mark-text:nth-child(2) {
            display: none !important;
        }
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';