.rr-NomenclaturesInfo {
    position: fixed;
    width: 600px;
    height: 260px;
    background-color: #EFEFEF;
    border: 1px solid #999999;
    color: #000000;
    z-index: 9999999;
    bottom: 5px;
    right: 5px;
    font-size: 11px;
    padding: 5px;

    opacity: .95;
    display: flex;
    flex-direction: column;
}

.rr-NomenclaturesInfo-collapsed {
    width: 23px;
    height: 27px;
}

.rr-NomenclaturesInfo-collapsed.rr-NomenclaturesInfo-with-problems {
    animation: blink 1s infinite;
}

.rr-NomenclaturesInfo-close {
    position: absolute;
    right: 5px;
    top: 5px;
    pointer-events: auto;
    user-select: none;
}

.rr-NomenclaturesInfo-header {
    //pointer-events: none;
}

.rr-NomenclaturesInfo-body {
    overflow: auto;
}

.rr-NomenclaturesInfo-body td {
    padding: 2px;
}

@keyframes blink {
    0% {
        background: red;
    }
    50% {
        background: #EFEFEF;
    }
    100% {
        background: red;
    }
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';