@import '../../assets/styles/vars';

.rr-custom-select-loading-block {
    padding: 5px 12px;
    color: @rr-default-input-placeholder-color;
    font-weight: @rr-default-input-placeholder-font-weight;
    cursor: default;
    user-select: none;
}

.rr-custom-select-loading-block .ant-spin {
    color: @rr-default-input-disabled-placeholder-color;
}

.rr-custom-select-loading-block .ant-spin + span {
    margin-left: 12px;
}

.rr-custom-select-error-block {
    min-width: 155px;
    padding: 21px 12px;
    color: #f4516c;
    cursor: default;
    user-select: none;
}

.rr-select-dropdown-menu-item-seperator {
    padding: 0;
    margin-top: 5px;
    margin-bottom: 5px;
    border-top: 1px solid #cccccc;
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';