.rr-kit-indicators-block {
  display: flex;
  width: 1100px;
  margin-left: auto;
  background-color: #fff;
  justify-content: space-between;
  margin-top: 30px;
  flex-wrap: wrap ;
}
.rr-kit-indicators-block-item {
  display: flex;
  align-items: center;
  width: 220px;
  padding: 12px 0px;
  padding-left: 32px ;
}

@media (max-width: 1300px) {
  .rr-kit-indicators-block {
    width: 780px;
  }

  .rr-kit-indicators-block-item.rr-kit-indicators-item1, .rr-kit-indicators-block-item.rr-kit-indicators-item2, .rr-kit-indicators-block-item.rr-kit-indicators-item3 {
    width: 260px;
  }

  .rr-kit-indicators-block-item.rr-kit-indicators-item4, .rr-kit-indicators-block-item.rr-kit-indicators-item5 {
    width: 390px;
  }

  .rr-kit-indicators-block-item.rr-kit-indicators-item4 {
    border-top: 1px solid #eaeaea;
  }
}


@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';