@import '~antd/lib/style/themes/default';

@tooltip-bg: #868AA8;//rgba(56, 63, 119, 0.85);

@tooltip-prefix-cls: ~'@{ant-prefix}-tooltip';

.@{tooltip-prefix-cls} {

  max-width: 450px;

  &-inner {
    background-color: @tooltip-bg;
    border-radius: 3px;
    cursor: default;
  }

  &-placement-left &-arrow, &-placement-leftTop &-arrow, &-placement-leftBottom &-arrow {
    border-top-color: @tooltip-bg;
    border-right-color: @tooltip-bg;
  }

  &-placement-top &-arrow, &-placement-topLeft &-arrow, &-placement-topRight &-arrow {
    border-right-color: @tooltip-bg;
    border-bottom-color: @tooltip-bg;
  }

  &-placement-right &-arrow, &-placement-rightTop &-arrow, &-placement-rightBottom &-arrow {
    border-bottom-color: @tooltip-bg;
    border-left-color: @tooltip-bg;
  }

  &-placement-bottom &-arrow, &-placement-bottomLeft &-arrow, &-placement-bottomRight &-arrow {
    border-top-color: @tooltip-bg;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: @tooltip-bg;
  }

  .ant-tooltip-arrow::before {
    background-color: @tooltip-bg;
  }

  & &-inner p:last-child {
    margin-bottom: 0;
  }

}
