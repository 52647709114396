.ant-alert {
  background-color: #fff;
  border-radius: 0;
  font-variant: normal;
}

.ant-alert-with-description {
  padding: 20px 15px 30px 32px;
  border-radius: 0;
}

.ant-alert.ant-alert-closable {
  padding-right: 32px;
}

.ant-alert-description {
  font-size: 14px;
  font-weight: 500;
}

.ant-alert-with-description .ant-alert-message {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 23px;
}

.ant-alert-with-description .ant-alert-description {
  max-height: 300px;
  overflow-y: auto;
}



.ant-alert-with-description .ant-alert-icon {
  left: 30px;
  top: 23px;
  font-size: 20px;
}

.ant-alert-with-description .ant-alert-close-icon {
  top: 21px;
  right: 26px;
  font-size: 21px;
}

.ant-alert-icon + .ant-alert-message {
  padding-left: 32px;
}

.ant-alert-with-description.ant-alert-no-icon {
  padding: 15px;
}

.ant-alert-with-description.ant-alert-no-icon {
  padding: 20px 30px 30px 32px;
}

////////////////////////////////////
.ant-alert.ant-alert-error {
  border-color: #f4516c;
  //user-select: none;
}

.ant-alert-with-description.ant-alert-error .ant-alert-message,
.ant-alert-with-description.ant-alert-error .ant-alert-close-icon .anticon-close,
.ant-alert-with-description.ant-alert-error .ant-alert-icon {
  color: #f4516c;
}

.ant-alert-with-description.ant-alert-error .ant-alert-close-icon:hover .anticon-close {
  color: darken(#f4516c, 20);
}

////////////////////////////////////
.ant-alert.ant-alert-success {
  border-color: #34bfa3;
}

.ant-alert-with-description.ant-alert-success .ant-alert-message,
.ant-alert-with-description.ant-alert-success .ant-alert-close-icon .anticon-close,
.ant-alert-with-description.ant-alert-success .ant-alert-icon {
  color: #34bfa3;
}

////////////////////////////////////
.ant-alert.ant-alert-info {
  border-color: #1890ff;
}

.ant-alert-with-description.ant-alert-info .ant-alert-message,
.ant-alert-with-description.ant-alert-info .ant-alert-close-icon .anticon-close,
.ant-alert-with-description.ant-alert-info .ant-alert-icon {
  color: #1890ff;
}

////////////////////////////////////
.ant-alert.ant-alert-warning {
  border-color: #faad14;
}

.ant-alert-with-description.ant-alert-warning .ant-alert-message,
.ant-alert-with-description.ant-alert-warning .ant-alert-close-icon .anticon-close,
.ant-alert-with-description.ant-alert-warning .ant-alert-icon {
  color: #faad14;
}