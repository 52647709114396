@import '../../assets/styles/vars';

.rr-aggregations-popover {
    width: auto;

    .content {
        padding: 12px 16px 16px 16px;

        .header-container {
            display: flex;
            justify-content: flex-start;
            margin-bottom: 16px;

            > div:nth-child(1) {
                width: 18px;

                > span {
                    line-height: 1;
                    top: 0;
                }
            }

            .header {
                margin-left: 12px;

                > h4 {
                    font-size: 18px;
                    font-weight: 400;
                    margin-bottom: 1px;
                    letter-spacing: 0.2px;
                }

                > span {
                    vertical-align: top;
                    font-size: 10px;
                    letter-spacing: 0.3px;
                    color: @rr-default-input-placeholder-color;
                }
            }
        }

        .rows-container {
            .row {
                display: flex;
                justify-content: space-between;
                padding: 12px 16px;

                > span {
                    min-width: 200px;
                }

                > b {
                    margin-left: 24px;
                }

                &:nth-child(odd) {
                    background: #f8f9fa;
                }
                &:nth-child(even) {
                    background: #fff;
                }

                &.summary {
                    font-size: 18px;
                    background: @rr-color-rent;
                    color: #fff;
                }
            }
        }
    }
}

.rr-aggregations-popover-error {
    color:  @rr-colors-red-1;
}
@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';