.rr-locationsMap-wrapper {
    position: relative;
}

.rr-locationsMap-buttons {
    position: absolute;
    z-index: 400;
    bottom: 20px;
    left: 20px;

    display: flex;
    flex-direction: column;
    gap: 8px;
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';