.pricingScheme-popover-content {
  //max-width: 600px;
}

.pricingScheme-popover-content .rr-pricingScheme-step-arrow {
  font-size: 20px;
  color: #CCCCCC;
  margin-right: 46px;
  margin-left: 46px;
  width: 20px;
  margin-top: 1px;
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';