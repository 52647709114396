.rr-page-system-settings .rr-form-group h3 {
  //font-size: 18px;
  display: inline-block;
}

.rr-page-system-settings .rr-form-group:not(:last-child) {
  border-bottom: 1px solid #d8dce6;
}

.rr-page-system-settings .rr-form-group {
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
}

.rr-page-system-settings .ant-card-body {
  padding-top: 0px;
}

/*
.rr-page-system-settings-footer {
  background-color: #fff;
  margin-left: -32px;
  margin-right: -32px;
  margin-bottom: -32px;
  padding: 32px;
  border-top: 1px solid #d8dce6;
}

.rr-page-system-settings-footer::before {
  content: " ";
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, .0), rgba(255, 255, 255, .85));
  display: block;
  height: 60px;
  margin-bottom: 32px + 1px;
  margin-top: -(60px + 32px + 1px);
  margin-left: -32px;
  margin-right: -32px;
  pointer-events: none;
}
*/
@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';