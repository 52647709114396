@import '../../../../../../../../../../../../../assets/styles/vars';

.accessibility-item {
    font-weight: 600;
}

.accessibility-item-popover {
    .ant-popover-inner-content {
        padding: 0 !important;
    }

    .container {
        margin: 0 !important;
        width: 100%;
        display: flex;
        justify-items: start;
        flex-direction: column;

        .title {
            display: flex;
            align-items: center;
            border-bottom: 1px solid @rr-default-input-border-color;
            padding: 8px 12px;
            font-weight: 600;
            color: @rr-link-default-color;
            height: 38px !important;

            .circle {
                border-radius: 50%;
                width: 11px;
                max-width: 11px;
                height: 11px;
                max-height: 11px;
                margin-right: 8px;
            }
        }

        .dates {
            padding: 12px;
            display: flex;
            align-items: center;
            justify-content: center;

            .date {
                text-align: center;
                flex-grow: 1;
            }

            .arrow {
                font-size: 18px;
                margin-left: 12px;
                margin-right: 12px;
                line-height: 18px;
                margin-bottom: 8px;
            }
        }
    }
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';