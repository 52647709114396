.rr-price {
  display: inline-flex;
  align-items: center;

  .rr-price-pricingScheme-icon {
    font-size: 22px;
    color: #3d64d4;
    margin-right: 4px;
  }
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';