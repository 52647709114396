.iCalc-container {
    display: inline-flex;
    justify-items: center;
    align-items: center;
    cursor: pointer;
    user-select: none;
    margin-right: 17px;

    &:hover {
        opacity: 0.8;
    }

    > i {
        color: #21bfa4;
    }

    > span {
        margin-left: 6px;
        white-space: nowrap;
    }
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';