.rr-selectedIdsFilter {
  height: 38px;
  //line-height: 38px;
  border-radius: 4px;
  border: 1px solid #383F77;
  color: #383f77;
  padding-left: 11px;
  padding-right: 11px;
  display: flex;
  align-items: center;
  user-select: none;
}

.rr-selectedIdsFilter-clear-icon {
  font-size: 20px;
  cursor: pointer;
  margin-left: 4px;
  color: #868AA8;
}

.rr-selectedIdsFilter-clear-icon:hover {
  color: #383f77;
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';