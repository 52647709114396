@import '../../../../../assets/styles/vars';

.rr-barcode-print-icon {
    color: #525993;
}

.rr-barcode-print-icon:not(.rr-barcode-print-icon-disabled):hover {
    opacity: 0.8;
}

.rr-barcode-print-icon-disabled {
    color: @rr-colors-grey-2;
}

.ant-btn:hover,
.ant-btn:focus {
    .rr-icon-link {
        opacity: 0.8;
    }
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';