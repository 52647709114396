.rr-RequiredTimeIndentBetweenElementsInMinutesIcon__popover {
    z-index: 1051;
}

.rr-RequiredTimeIndentBetweenElementsInMinutesIcon__popover .ant-popover-inner-content {
    padding: 10px 26px;
}

.rr-RequiredTimeIndentBetweenElementsInMinutesIcon__popover .ant-popover-inner-content > div:nth-child(1) {
    font-size: 14px;
    padding: 0;
    text-align: center;
}

.rr-RequiredTimeIndentBetweenElementsInMinutesIcon__popover .ant-popover-inner-content > div:nth-child(2) {
    margin-top: 2px;
    font-size: 11px;
    text-align: center;
    color: #aaaaaa;
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';