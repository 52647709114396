@import '~antd/lib/style/themes/default';
@import '~antd/lib/style/mixins/index';

@slider-prefix-cls: ~'@{ant-prefix}-slider';

.@{slider-prefix-cls} {

  user-select: none;
  font-feature-settings: normal;
  font-variant: normal;
  padding: 0;
  margin: 14px 0px 10px;

  &-with-marks {
    //margin-top: 26px;
    //margin-bottom: 0;
    margin: 26px 6px 0;
  }

  &-rail {
    border-radius: 4px;
    position: absolute;
    width: 100%;
    background-color: #FFF;
    transition: background-color 0.3s;
    height: 6px;
  }

  &-disabled {
    cursor: default;
  }

  &-disabled &-mark-text, &-disabled &-dot {
    cursor: default !important;
  }

  &-disabled &-handle, &-disabled &-dot {
    cursor: default;
  }

  &:hover &-rail {
    background-color: #fff;
  }

  &-track {
    height: 6px;
    background-color: #525993;
  }

  &:hover &-track {
    background-color: #525993;
  }

  &-handle {
    //margin-left: -8px;
    //margin-top: -5px;
    width: 16px;
    height: 16px;
    //background-color: #525993;
    border: solid 2px #525993;
  }

  &-handle:focus {
    border-color: #525993;
    box-shadow: none;
  }

  &-mark {
    top: -28px;
  }

  &-mark &-mark-text:first-child {
    left: 0% !important;
    transform: translateX(0%) !important;
  }

  &-mark &-mark-text:last-child {
    left: 100% !important;
    transform: translateX(-100%) !important;
  }

  &-mark-text&-mark-text-active {
    padding: 0;
    top: 3px;
    line-height: 1;
  }

  &:hover &-handle:not(.ant-tooltip-open) {
    border-color: #525993;
  }

  .rr-slider-gray& &-rail {
    background-color: #f4f5f8;
  }

  .rr-slider-gray&:hover &-rail {
    background-color: #f4f5f8;
  }

  &.rr-slider-range-with-one-value &-handle-2 {
    left: 100% !important;
  }

  &.rr-slider-range-with-one-value &-track-1 {
    width: 100% !important;
  }

  &.rr-slider-range-with-one-value &-mark-text {
    color: rgba(0, 0, 0, 0.65);
  }

}