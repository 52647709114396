.rr-form-group {
  padding: 28px 30px 10px;
  margin-left: -25px;
  margin-right: -25px;
}

.rr-form-group-header {
  margin-left: -25px;
  margin-right: -25px;
  border-bottom: 1px solid #d8dce6;
  font-size: 18px;
  padding: 20px 28px;
  font-weight: 700;
  color: #575962;
}

.rr-form-group-title {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 25px;
}
.rr-form-group-title-info {
  position: absolute;
  right: 30px;
  z-index: 999;
  margin-top: 3px;
}

.rr-form-group-title-help {
  flex-grow: 1;
  text-align: right;
  font-size: 22px;
  color: #525A95;
}

.rr-form-group > div > h3, .rr-form-group > div > div > h3 {
  font-size: 14px;
  color: #575962; // TODO потом проверить цвет по гайдлайну
  font-weight: 600;
  margin-bottom: 0;
  display: inline-block;
}

.rr-form-group > div > h3 {
  margin-bottom: 30px;
}

.rr-form-group:not(:last-child) {
  border-bottom: 1px solid #d8dce6;
}

.rr-form-add-icon {
  font-size: 21px;
  color: #34bfa3;
  margin-left: 10px;
}

.rr-form-add-icon:hover {
  color: lighten(#34bfa3, 10);
}

.rr-form-delete-icon {
  font-size: 21px;
  color: #F4516C;
  margin-left: 10px;
}

.rr-form-delete-icon:hover {
  color: lighten(#F4516C, 10);
}

.rr-form-multiple-filed .ant-input {
  width: 91%;
}

.rr-form-multiple-filed .ant-calendar-picker-input {
  width: 100%;
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';