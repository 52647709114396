@import '../../../assets/styles/vars';

.rr-page-settings {
  margin: -30px;
}

.rr-page-settings-container {
  background-color: #E8E9F2;
  display: flex;
}

.rr-page-settings__menu {
  background-color: #F8F9FA;
  min-height: calc(100vh - 70px - 70px);
  width: 334px;
  user-select: none;
}

.rr-page-settings__menu .ant-menu {
  box-shadow: none;
  background-color: unset;
}

.rr-page-settings__menu .ant-menu.ant-menu-root {
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  height: 0;
  padding: 20px 0 20px 50px;
}

.rr-page-settings__menu .ant-menu .ant-menu-item {
  margin: 0;
  height: 38px;
  line-height: 38px;
}

.rr-page-settings__menu .ant-menu .ant-menu-item a {
  color: #575962;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
}

.rr-page-settings__menu .ant-menu .ant-menu-item.ant-menu-item-selected {
  font-weight: 600;
  background-color: unset;
}

.rr-page-settings__menu .ant-menu .ant-menu-item.ant-menu-item-selected a {
  color: @rr-link-default-color;
}

.rr-page-settings__menu .ant-menu .ant-menu-item:active,
.rr-page-settings__menu .ant-menu .ant-menu-submenu-title:active {
  background-color: unset;
}

.rr-page-settings__menu .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: unset;
}

.rr-page-settings__menu .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected .ant-menu-submenu-title a {
  font-weight: 600;
  color: @rr-link-default-color;
}

.rr-page-settings__menu .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected .ant-menu-submenu-title .anticon {
  color: @rr-link-default-color;
}

.rr-page-settings__menu .ant-menu > .ant-menu-submenu > .ant-menu-submenu-title a {
  font-weight: 600;
}

.rr-page-settings__menu .ant-menu > .ant-menu-submenu > .ant-menu-submenu-title {
  margin: 0;
  height: 38px;
  line-height: 38px;
  padding: 0;
}

.rr-page-settings__menu .ant-menu .ant-menu-item:hover,
.rr-page-settings__menu .ant-menu .ant-menu-item-active,
.rr-page-settings__menu .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.rr-page-settings__menu .ant-menu .ant-menu-submenu-active,
.rr-page-settings__menu .ant-menu .ant-menu-submenu-title:hover,
.rr-page-settings__menu .ant-menu .ant-menu-submenu-title a {
  color: #575962;
}

.rr-page-settings__menu .ant-menu-submenu .ant-anchor-wrapper {
  margin-left: 0;
  padding-left: 0;
  overflow: hidden;
  background-color: unset;
}

.rr-page-settings__menu .ant-menu-submenu .ant-anchor-wrapper .ant-anchor {
  padding-left: 0;
}

.rr-page-settings__menu .ant-menu-submenu .ant-anchor-wrapper .ant-anchor-link {
  padding: 11px 0 11px 26px;
  line-height: 1.143;
}

.rr-page-settings__menu .ant-menu-submenu .ant-anchor-wrapper .ant-anchor-link-title-active {
  color: @rr-link-default-color;
}

.rr-page-settings__menu .ant-menu-submenu .ant-anchor-wrapper {
  overflow: hidden;
  transition: max-height 0.3s;
  height: auto;
  max-height: 800px !important;
}

.rr-page-settings__menu .ant-menu-submenu.ant-menu-item-collapsed .ant-anchor-wrapper {
  max-height: 0 !important;
}

.rr-page-settings__menu .ant-menu-submenu .ant-menu-submenu-title .anticon {
  transition: all 0.2s;
}

.rr-page-settings__menu .ant-menu-submenu.ant-menu-item-collapsed .ant-menu-submenu-title .anticon {
  transform: rotate(180deg);
}

.rr-page-settings__content {
  padding: 0px 0px 30px 30px;
  flex-grow: 1;
  cursor: default;
  overflow: hidden;
  width: 100%;
}

.rr-page-settings__content .rr-settings-module-header {
  font-weight: bold;
  font-size: 22px;
}


.card-title {
  font-size: 21px;
  font-weight: 700
}
@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';