.rr-v {
  display: inline-flex;
  align-items: center;
}

.rr-v .anticon {
  font-size: 21px;
}

.rr-v > .anticon {
  margin-right: 12px;
}

.rr-v-empty {
  font-weight: 400;
  color: #aaaaaa;
  font-size: 18px;
}
@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';