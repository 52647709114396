.calendar-timeline {
    .rct-vertical-lines {
        .rct-vl {
            border-left: 1px solid #f2f3f8;
            z-index: 0 !important;
        }
        .rct-vl.rct-day-6,
        .rct-vl.rct-day-0 {
            background: none;
        }
        div[class*='6'].rct-vl,
        div[class*='0'].rct-vl {
            background-color: rgba(255, 243, 224, 0.2);
        }
    }

    .rct-horizontal-lines {
        .rct-vl {
            border-left: 1px solid #f2f3f8;
        }
        .rct-hl-even,
        .rct-hl-odd {
            border-bottom: 0 solid #bbb !important;
        }
        .rct-vl.rct-vl-first {
            border-left: 1px solid rgb(204, 204, 204);
        }
    }

    .rct-dateHeader {
        text-align: center;
        color: rgba(87, 89, 98, 0.9);
        border-right: 1px solid rgb(204, 204, 204) !important;
        border-bottom: 1px solid rgb(204, 204, 204) !important;
        border-left: none !important;
        background-color: unset;
        cursor: pointer;
        pointer-events: unset;
    }

    .rct-dateHeader-primary-week {
        border-right: none;
    }

    .rct-dateHeader-primary {
        border-right: 1px solid rgb(204, 204, 204) !important;
        border-top: 1px solid rgb(204, 204, 204) !important;
        border-left: none !important;
    }

    .rct-dateHeader-disabled {
        cursor: default;
    }

    .rct-scroll {
        margin-left: -1px !important;
        cursor: default !important;
    }

    .rct-calendar-header {
        border: 0 solid #bbb !important;
    }

    .rct-header-root {
        border-bottom: 0 solid #bbb !important;
        background: none !important;
    }

    .rct-sidebar {
        border: none !important;

        .rct-sidebar-row {
            background: transparent !important;
            border: none !important;
            display: flex;
            align-items: center;
            justify-content: end;
            padding-right: 10px !important;
        }
    }

    .isWeekDay.rct-dateHeader {
        background-color: #fff3e0;
    }
}

.ant-form-inline .rr-grid-filters .ant-form-item > .ant-form-item-label {
    vertical-align: inherit;
}

.calendar-filters .ant-select-selection--multiple .ant-select-search--inline .ant-select-search__field {
    background-color: #f4f5f8;
    width: 0;
}

.calendar-filters .ant-select-selection--multiple .ant-select-selection__rendered > ul > li {
    background-color: #fff;
}

.calendar-filters .ant-select-selection__rendered {
    display: flex;
    align-items: center;
}

.ant-select-selection .ant-select-selection--multiple {
    padding: 0;
}

.calendar-filters .ant-select-selection--multiple .ant-select-selection__rendered > ul > li {
    margin: 5px 3px 0;
}

.calendar-filters .ant-select-selection--multiple .ant-select-selection__rendered > ul > li:last-child {
    margin-bottom: 6px;
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';