@import '~antd/lib/style/themes/default';

@radio-prefix-cls: ~'@{ant-prefix}-radio';

// TODO Проверить размер шрифта и цвет шрифта для обычного состояния и для заблакированного

span.ant-radio + * {
    padding-left: 11px;
}

.@{radio-prefix-cls} {
    &-wrapper-disabled {
        cursor: not-allowed;
    }

    &-wrapper {
        user-select: none;
        color: @rr-default-input-text-color;
    }

    &-inner {
        width: 20px;
        height: 20px;
        background-color: #e2e5ec;
        border-color: #e2e5ec;
        border-width: 0;
    }

    &-inner::after {
        top: 6px;
        left: 6px;
        background-color: #ffffff;
    }

    &-checked &-inner {
        background-color: #383f77;
        border-color: #383f77;
    }

    &-checked::after {
        border: 1px solid #383f77;
    }

    &-disabled &-inner {
        background-color: #eaecf1;
    }

    &-input:focus + &-inner {
        box-shadow: none;
    }
}

//////////////////////////
.ant-radio-group {
    font-size: 21px;
}

.ant-radio-button-wrapper {
    color: #525993;
    padding: 0 34px;
}

.ant-radio-button-wrapper:hover {
    color: #525993;
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
    background: #525993;
}

.ant-radio {
    .ant-radio-inner {
        height: 18px;
        width: 18px;
        &::after {
            top: 5px;
            left: 5px;
        }
    }
}

.ant-radio-group .ant-radio-button-wrapper {
    height: 44px;
    line-height: 44px;
    border-radius: 0;
    border: none;
    min-width: 130px;
    text-align: center;
    background-color: #fff;
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    background: #525993;
    color: #fff;
}

.ant-radio-button-wrapper-checked::before {
    background-color: #525993 !important;
    opacity: 0.1;
}

.ant-radio-button-wrapper-disabled.ant-radio-button-wrapper-checked {
    background-color: lighten(#525993, 30%);
}

.ant-radio-group .ant-radio-button-wrapper:not(:first-child)::before {
    background-color: #c7c9d5;
}

//
.ant-radio-button-wrapper-checked {
    box-shadow: none;
}

.ant-radio-button-wrapper-checked:hover {
    box-shadow: none;
}

.ant-radio-button-wrapper-disabled {
    cursor: default;
}
