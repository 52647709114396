.crew-member-link-container {
    .crew-member-label-container {
        display: flex;
        padding-bottom: 8px;

        .crew-member-icon {
            display: flex;
            color: #34bfa3;
            font-size: 24px;
            user-select: none;

            svg {
                margin-top: -2px;
                align-self: center;
            }
        }

        .crew-member-label {
            font-size: 18px;
            margin-left: 9px;
        }
    }

    .crew-member-link {
        color: #525993;
        text-decoration: underline;
    }
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';