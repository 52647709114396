@import "../../../assets/styles/vars";

.rr-state-badge:not(.rr-state-badge-with-dot) {
  display: inline-block;
  height: 20px;
  line-height: 20px;
  font-size: 11px;
  font-weight: 500;
  text-align: center;
  color: #fff;
  border-radius: 10px;
  padding: 0 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: default;
  vertical-align: top;
}

.rr-state-badge.rr-state-badge-with-dot {
  background-color: unset;
  line-height: inherit;
  vertical-align: baseline;
  position: relative;
}

.rr-state-badge.rr-state-badge-with-dot .rr-state-badge-dot {
  position: relative;
  display: inline-block;
  width: 11px;
  height: 11px;
  border-radius: 11px;
  top: .5px;
  margin-right: 10px;
}

// Имя соответствует строке из enum'а с цветами Color
.stateBadge(@colorName, @color) {
  .rr-state-badge--@{colorName} {
    background-color: @color;
  }

  .rr-state-badge-with-dot.rr-state-badge--@{colorName} .rr-state-badge-dot {
    background-color: @color;
  }
}

.stateBadge(green, @rr-colors-green);

.stateBadge(white-grey-1, #FFFFFF);

.rr-state-badge--white-grey-1:not(.rr-state-badge-with-dot) {
  border: 1px dashed @rr-colors-grey-1;
  color: @rr-colors-grey-1;
}

.rr-state-badge-with-dot.rr-state-badge--white-grey-1 .rr-state-badge-dot {
  border: 1px dashed @rr-colors-grey-1;
  color: @rr-colors-grey-1;
}

.stateBadge(grey-2, @rr-colors-grey-2);

.stateBadge(grey-3, @rr-colors-grey-3);

.stateBadge(purple-1, @rr-colors-purple-1);

.stateBadge(purple-2, @rr-colors-purple-2);

.stateBadge(purple-3, @rr-colors-purple-3);

.stateBadge(purple-4, @rr-colors-purple-4);

.stateBadge(azure, @rr-colors-azure);

.stateBadge(orange-1, @rr-colors-orange-1);

.stateBadge(red-1, @rr-colors-red-1);

.stateBadge(cyan, @rr-colors-cyan);

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';