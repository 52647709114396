.rr-RecalculateShiftsCountButton__icon {
  color: #5ea5fa;
  font-size: 24px;
  user-select: none;
  //
  vertical-align: middle;
  margin-top: -1.5px;
}

.rr-RecalculateShiftsCountButton__icon:not(.rr-RecalculateShiftsCountButton__icon__without-hover):hover {
  color: fade(#5ea5fa, 80);
}

.rr-RecalculateShiftsCountButton__icon--disabled, .rr-RecalculateShiftsCountButton__icon--disabled:hover, .rr-RecalculateShiftsCountButton__icon--disabled:not(.rr-RecalculateShiftsCountButton__icon__without-hover):hover  {
  color: #CCCCCC;
}

.rr-RecalculateShiftsCountButton__popover {
  z-index: 1051; // т.к. у календаря z-index 1050 почему-то, то попап показывался за календарем
}

.rr-RecalculateShiftsCountButton__popover .ant-popover-inner-content {
  padding: 0;//12px 22px 10px;
  font-size: 16px;
  color: rgba(87, 89, 98, 0.8);
  font-weight: 500;
}

.rr-RecalculateShiftsCountButton__popover .ant-popover-inner-content > div:nth-child(2) {
  border-top: 1px solid #cccccc;
  font-size: 14px;
  padding: 8px 23px 8px 23px;
  text-align: center;
}

.rr-RecalculateShiftsCountButton__popover .ant-popover-inner-content > div:nth-child(2) > div:nth-child(2) {
  font-size: 11px;
  text-align: center;
  color: #aaaaaa;
}

.rr-RecalculateShiftsCountButton__popover .ant-popover-inner-content > div:nth-child(1) {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 20px;
}

.rr-RecalculateShiftsCountButton__popover__from {
  //width: 40px;
  text-align: center;
  flex-grow: 1;
}

.rr-RecalculateShiftsCountButton__popover__from--error {
  color: #f8516c;
}

.rr-RecalculateShiftsCountButton__popover__arrow {
  font-family: "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin-top: -2px;
  vertical-align: top;
  margin-left: 25px;
  margin-right: 19px;
  display: inline-block;
}

.rr-RecalculateShiftsCountButton__popover__to {
  //width: 40px;
  text-align: center;
  flex-grow: 1;
}

.rr-RecalculateShiftsCountButton__popover__to--highlited {
  color: #00b6f2;
  font-weight: 600;
}

.rr-RecalculateShiftsCountButton__popover__to--error {
  color: #f8516c;
  font-weight: 600;
}

//.rr-RecalculateShiftsCountButton__popover__shiftLengthInMinutes-block {
//  border-top: 1px solid #cccccc;
//  font-size: 15px;
//}
@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';