.rr-offer-price-block.rr-collapsible-block.ant-collapse .ant-collapse-item:last-child > .ant-collapse-content > .ant-collapse-content-box {
  padding: 0 ;
}

.rr-offer-price-block.rr-collapsible-block.ant-collapse .ant-collapse-item-disabled .rr-collapsible-block-header-title, .rr-collapsible-block.ant-collapse .ant-collapse-item-disabled .rr-collapsible-block-header-icon {
  color: #575962;
}

.rr-offer-price-block .rr-collapsible-block-header-icon--disabled .anticon {
  color: #34bfa3;
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';