.rr-notification-notice {
  cursor: default;
  padding: 0;
}

.rr-notification-notice .ant-notification-notice-icon {
  margin-left: 17px;
  margin-top: 22px;
  font-size: 22px;
  color: #FAFBFF;
}

.rr-notification-notice .ant-notification-notice-with-icon .ant-notification-notice-message {
  padding: 20px 52px;
  margin-left: 0;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  display: block;
  background-color: #383F77;
  margin-bottom: 0;
}

.rr-notification-notice.rr-notification-notice-error .ant-notification-notice-message {
  background-color: #F4516C;
}

.rr-notification-notice.rr-notification-notice-success .ant-notification-notice-message {
  background-color: #34BFA3;
}

.rr-notification-notice.rr-notification-notice-info .ant-notification-notice-message {
  background-color: #36a3f7;
}

.rr-notification-notice.rr-notification-notice-warning .ant-notification-notice-message {
  background-color: #FFB822;
}

.rr-notification-notice .ant-notification-notice-with-icon .ant-notification-notice-description {
  padding: 0;
  margin-left: 0;
}

.rr-notification-notice .ant-notification-notice-with-icon .rr-notification-notice-description {
  margin-left: 0;
  font-size: 14px;
  padding: 28px 30px;
  font-weight: 500;
}

.rr-notification-notice .ant-notification-notice-close {
  top: 22px;
  right: 23px;
  color: #FAFBFF;
  user-select: none;
}

.rr-notification-notice .ant-notification-close-icon {
  font-size: 21px;
}

.rr-notification-notice .ant-notification-notice-close:hover {
  color: #FAFBFF;
  opacity: .6;
}

.rr-notification-notice .ant-notification-notice-close:active {
  opacity: .8;
}

.rr-scanner-notice {
  cursor: default;
  padding: 0;
  margin-left: -116px;
  margin-bottom: 10px;
}

.rr-scanner-notice .ant-notification-notice-icon {
  margin-left: 17px;
  font-size: 22px;
  margin-top: 12px;
  color: #FAFBFF;
}

.rr-scanner-notice .ant-notification-notice-with-icon .ant-notification-notice-message {
  font-size: 14px;
  font-weight: 400;
  color: #FAFBFF;
  margin-bottom: 0;
  padding: 12px 52px 12px 0;
}

.rr-scanner-notice .ant-notification-notice-close {
  top: 15px;
}

.rr-scanner-notice .ant-notification-notice-close:hover {
  opacity: .6;
}

.rr-scanner-notice .ant-notification-notice-close:active {
  opacity: .8;
}

.rr-scanner-notice .ant-notification-close-icon {
  font-size: 19px;
  color: #FAFBFF;
}

.rr-scanner-notice.rr-scanner-error-notice .ant-notification-notice-description {
  margin-left: 0;
  border: 1px solid #F4516C;
  border-top: none;
  padding: 18px 26px;
  max-height: calc(75vh - 48px);
  overflow-y: auto;
  background: #FFFFFF;
}

.rr-scanner-notice.rr-scanner-success-notice {
  background: #34BFA3;
}

.rr-scanner-notice.rr-scanner-error-notice {
  background: #F4516C;
}

.rr-scanner-error-notice .ant-notification-close-x {
  display: flex;
  align-items: center;
  color: #FAFBFF;
}

.rr-scanner-error-notice .ant-notification-close-x .ant-notification-close-icon {
  margin-left: 4px;
}

.rr-scanner-error-notice .ant-notification-close-x::before {
  content: "Закрыть все";
  opacity: 0;
}

.rr-scanner-error-notice-multiple .ant-notification-close-x::before {
  opacity: 1;
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';