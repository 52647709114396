// Это сборище стилей, которые были не там объявлены(((

.popover-link-hover:hover {
    text-decoration: underline;
    cursor: pointer;
}

.rr-operation-status-point {
    //width: 10px;
    //height: 10px;
    background-color: #34bfa3;
    //border-radius: 5px;
    display: inline-block;
    margin-right: 7px;
}

.popover-header-name:hover {
    text-decoration: underline;
    cursor: pointer;
}

.grid-column-variant {
    font-weight: 400;
    cursor: pointer;
}

.rr-elements-grid-number:hover {
    cursor: pointer;
    text-decoration: underline;
}

.rr-elements-grid-activity-bold {
    font-weight: 600;
}

.rr-elements-grid-activity-link {
    display: flex;
}

.rr-elements-grid-activity-link .rr-elements-grid-activity-icon {
    font-size: 18px;
    color: #21bfa4;
    margin-right: 4px;
}

.hidden-form {
    min-height: 0;
}
