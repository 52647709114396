.calendar-header {
    display: flex;
    justify-content: space-between;
    margin-top: -17px;

    .visible-items-count {
        font-weight: 500;
    }

    .actions-block {
        display: flex;
    }
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';