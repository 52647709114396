.rr-history-elements-popup-wrapper {
    padding-top: 2px;
    padding-bottom: 2px;
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 6px;
    min-width: 90px;
    line-height: 1.3;

    .value {
        font-size: 14px;
        font-weight: 400;
        color: #575962;
    }

    .signature {
        font-size: 11px;
        font-weight: 400;
        color: #a6a8b0;
    }

    .ant-popover-arrow {
        top: 20px !important;
    }
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';