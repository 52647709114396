.rr-icon-inventoryMovement-STOCKTAKING {
  color: #34bfa3;
}

.rr-icon-inventoryMovement-ACQUISITION {
  color: #34bfa3;
}

.rr-icon-inventoryMovement-DAMAGE {
  color: #ffb822;
}

.rr-icon-inventoryMovement-LOSS {
  color: #f8516c;
}

.rr-icon-inventoryMovement-MAINTENANCE {
  color: #525993;
}

.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item-selected .rr-icon-inventoryMovement-MAINTENANCE {
  color: #FFFFFF;
}

.rr-icon-inventoryMovement-MAINTENANCE_COMPLETION {
  color: #34bfa3;
}

.rr-icon-inventoryMovement-WRITE_OFF {
  color: #a0a0a0;
}

.rr-icon-inventoryMovement-SALE {
  color: #a0a0a0;
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';