.range-picker-gray {
    &.border-none {
        .ant-calendar-picker-input {
            border: none !important;
        }
    }
    .ant-calendar-picker-input {
        background-color: #f4f5f8 !important;
    }
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';