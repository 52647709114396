@import '../../../../../../../assets/styles/vars';

.sidebar-group-title {
    font-size: 14px;
    text-align: right;
    font-weight: 400;

    &.bold {
        font-weight: 600;
    }

    .value {
        font-weight: 700;
        font-size: 18px;
    }
}

.sidebar-group-title-helper {
    text-align: right;
    font-size: 10px;
    color: @rr-default-input-disabled-text-color;
}

.sidebar-group-available-container {
    display: flex;
    flex-direction: column;
    height: auto;
    line-height: normal;
    gap: 2px;
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';