.rr-page-renter h3 {
  font-size: 24px;
  font-weight: bold;
  color: #575962;
  line-height: 29px;
}

.rr-page-renter-categories a {
  text-decoration: underline;
  color: #575962;
}

.rr-page-renter-categories {
  font-size: 14px;
  font-weight: 600;
}

@media  (min-width: 1550px)  {
  .smartFilterRenter  .ant-form-inline {
    display: grid;

    grid-template-columns: repeat(2, 1fr) !important;
  }

  .smartFilterRenter .rr-grid-smart_filters .ant-form-inline .ant-form-item:nth-child(2) {
    border: none !important;
  }
  .smartFilterRenter .rr-grid-smart_filters .ant-form-inline .ant-form-item:nth-child(4) {
    border: none !important;
  }
}

.rr-page-renter-data-block:not(:last-child) {
  border-bottom: 1px solid #eaeaea;
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';