.product-instance-popover-status {
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2px ;
  margin-right: 5px;
}

.tab-popap-instance {
    min-height: 325px;
}
.tab-popap-instance.tab-popap-variant {
  min-height: 325px;
}

.tab-popap-instance .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
  height: 4px;
}
.product-instance-shortage-count  {
  font-size: 18px;
  font-weight: bold;
  margin-left: 8px;
  color: rgb(244, 81, 108);
}

.product-instance-not-problem {
  color: rgb(52, 191, 163);
  font-weight: 400;
}

.product-instance-is-problem {
  color: rgb(244, 81, 108);
}

.product-instance-is-problem-сommitments {
  text-decoration: underline;
  margin-left: 5px;
  cursor: pointer;
}

.tab-popap-instance-row {
  padding: 18px 32px;
  padding-top: 30px;
  padding-bottom: 32px;
}

.tab-popap-instance-header-span-icon {
  margin-right: 22px;
  font-size: 18px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tab-popap-instance-header-icon {
  font-size: 22px;
  margin-right: 10px;
  margin-left: 10px;
  margin-bottom: -12px;
  margin-top: 4px;
}


.tab-popap-instance .tab-popap-instance-product-value {
  color: #575962;
}

.tab-popap-instance .tab-popap-instance-product-value:hover {
  color: #6f727d;
  text-decoration: underline;
}

.tab-popap-instance-product-icon {
  margin-right: 7px;
  font-size: 21px;
}


.tab-popap-instance .ant-tabs-nav .ant-tabs-tab {
  font-size: 14px;
  padding: 0px;
  margin: 0px;
  margin-right: 34px;
}

.tab-popap-instance-header-div {
  display: flex;
  align-items: center;
}

.tab-popap-instance .tab-popap-instance {
  min-width: 0px;
}

.tab-popap-instance .ant-tabs-nav .ant-tabs-tab:hover {
  color: #575962;
  font-size: 14px;
}

.tab-popap-instance .ant-tabs-tab-active.ant-tabs-tab, .tab-popap-instance .ant-tabs-tab-active.ant-tabs-tab:hover {
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 14px;
  font-weight: 600;
}

.tab-popap-instance .popover-last-second-row {
  margin-bottom: 0px !important;
}

.tab-popap-instance .ant-tabs-nav.ant-tabs-nav-animated .ant-tabs-tab-active,
.tab-popap-instance .ant-tabs-nav.ant-tabs-nav-animated .ant-tabs-tab-active:hover {
  font-size: 14px ;
}

.rr-grid-actions-popover-header .actions-popover-header-status {
  margin-right: 16px;
  //overflow: unset;
  //cursor: pointer;
}

.tab-popap-instance .ant-tabs-bar {
  margin-bottom: 0;
}
.tab-popap-instance .ant-tabs-tab-active.ant-tabs-tab {
  padding-top: 0px !important;
  padding-bottom: 0px !important ;
}

.tab-popap-instance .ant-tabs-nav.ant-tabs-nav-animated div{
  display: flex;
}
@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';