.rr-today-btn {
  color: #525a95;
  cursor: pointer;
  display: flex;
  white-space: nowrap;
  align-items: center
}

.rr-today-btn:hover {
  opacity: .8;
}

.rr-today-btn .anticon {
  color: #00afee;
  font-size: 21px;
  margin-right: 5px;
}

.rr-today-btn-disabled {
  cursor: default;
  opacity: .4;
  pointer-events: none;
}


@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';