.rr-operation-page-top-counters-block {
  border-left: 1px solid #eaeaea;
}

.rr-operation-page-top-counters-block .rr-form-item:not(:last-child) {
  margin-bottom: 0;
  border-right: 1px solid #eaeaea;
}

.rr-operation-page-top-counters-block .rr-form-item {
  padding: 23px 4px 23px 32px;

  border-left: 1px solid #eaeaea;
  padding-top: 9px;
  padding-bottom: 8px;
}

.rr-operation-page-top-counters-block .rr-form-item .rr-label, .rr-operation-page-top-counters-block .rr-form-item .rr-value {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}



//@media (min-width: 1600px) {
//
//  .rr-operationForm-elements-top-block_without-comments .rr-operation-page-top-counters-block .rr-form-item:nth-child(2) {
//    display: none;
//  }
//  .rr-operationForm-elements-top-block_without-comments .rr-operation-page-top-counters-block .rr-form-item:nth-child(3) {
//    display: none;
//  }
//}

@media (min-width: 1200px) {
  .rr-operation-page-top-counters-block .rr-form-item {
    border-left: 1px solid #eaeaea;
    padding-top: 9px;
    padding-bottom: 8px;
  }

  .rr-operation-page-top-counters-block .rr-form-item:not(:last-child) {
    border-right: none;
  }

  .rr-operation-page-top-counters-block .rr-form-item:first-child {
    padding-top: 20px;
  }

  .rr-operation-page-top-counters-block .rr-form-item:nth-child(3) {
    padding-bottom: 24px;
  }

  .rr-operation-page-top-counters-block .rr-form-item:last-child {
    padding-top: 26px;
    padding-bottom: 26px;
  }

  .rr-operationForm-elements-top-block_without-comments .rr-operation-page-top-counters-block .rr-form-item:last-child {
    padding-top: 17px;
    padding-bottom: 17px;
  }

  .rr-operationForm-elements-top-block_without-comments .rr-operation-page-top-counters-block .rr-form-item:first-child {
    padding-top: 17px;
    padding-bottom: 17px;
  }
}

@media (max-width: 1199px) {
  //.rr-operation-page-top-counters-block .rr-form-item {
  //  border-top: 1px solid #eaeaea;
  //}
}

@media (max-width: 767px) {
  .rr-operation-page-top-counters-block .rr-form-item:nth-child(odd) {
    border-right: 1px solid #eaeaea;
  }

  .rr-operation-page-top-counters-block .rr-form-item:nth-child(even) {
    border-right: none;
  }
}

//////
//.rr-operation-page-comment-block {
//  border-left: 1px solid #eaeaea;
//}
//
//@media (max-width: 991px) {
//  .rr-operation-page-comment-block {
//    border-left: none;
//    border-top: 1px solid #eaeaea;
//  }
//}

@media (min-width: 1200px) and (max-width: 1299px) {
  .rr-operationForm-elements-top-block:not(.rr-operationForm-elements-top-block_without-comments) .rr-operationForm-elements-top-block__params-block {
    width: 68%;
  }
}

@media (min-width: 1300px) and (max-width: 1399px) {
  .rr-operationForm-elements-top-block:not(.rr-operationForm-elements-top-block_without-comments) .rr-operationForm-elements-top-block__params-block {
    width: 61%;
  }
}

@media (min-width: 1400px) and (max-width: 1499px) {
  .rr-operationForm-elements-top-block:not(.rr-operationForm-elements-top-block_without-comments) .rr-operationForm-elements-top-block__params-block {
    width: 56%;
  }
}

@media (min-width: 1500px) and (max-width: 1599px) {
  .rr-operationForm-elements-top-block:not(.rr-operationForm-elements-top-block_without-comments) .rr-operationForm-elements-top-block__params-block {
    width: 52%;
  }
}

.rr-input-error > .ant-input {
  background-color: rgba(244, 82, 109, 0.4);
}

.rr-operation-page__operation-created-message a {
  color: #FFFFFF;
  text-decoration: underline;
}

.rr-operation-page__operation-created-message a:hover {
  text-decoration: underline;
  color: #FFFFFF;
}

.rr-grid-archive-row .rr-added-instances-block__minus-button:not(.rr-added-instances-block__minus-button--disabled) {
  color: #575962 !important;
}

.rr-grid-archive-row .rr-added-instances-block__plus-button:not(.rr-added-instances-block__plus-button--disabled) {
  color: #575962 !important;
}

.rr-grid-archive-row .rr-operationForm-elementsList-variant {
  color: #abacb0;
}

.rr-operationForm-elementsList .rr-grid-archive-row .rr-added-instances-block {
  color: #575962;
}

.rr-operationForm-elementsList .rr-grid-archive-row .rr-added-instances-block.rr-added-instances-block__whitout-added-instances {
  color: #abacb0;
}

.rr-grid-disabled-row .rr-added-instances-block {
  color: #575962;
}

.rr-grid-disabled-row .rr-added-instances-block.rr-added-instances-block__whitout-added-instances {
  color: #abacb0;
}

.rr-operationForm-tabs.rr-tabs .ant-tabs-tab {
  font-size: 18px;
  font-weight: 500;
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';