.rr-contacts-block .rr-simple-table-cell {
  padding-top: 2px;
  padding-bottom: 2px;
}

.rr-contacts-block.rr-collapsible-block.ant-collapse .ant-collapse-item:last-child > .ant-collapse-content > .ant-collapse-content-box {
  padding-top: 22px;
  padding-bottom: 22px;
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';