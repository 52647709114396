@import "../../../../../../assets/styles/calendar-vars";

.rr-calendar .ant-calendar-footer, .rr-calendar .ant-calendar-month-panel-footer, .rr-calendar .ant-calendar-year-panel-footer, .rr-calendar .ant-calendar-decade-panel-footer {
  line-height: 1;

  background-color: #f4f5f8;
  border-top: none;
  padding: 10px 26px;
}

.rr-calendar .ant-calendar-footer-btn {
  text-align: left;
}

.rr-calendar .ant-calendar-footer-btn, .rr-calendar .ant-calendar-month-panel-footer, .rr-calendar .ant-calendar-year-panel-footer, .rr-calendar .ant-calendar-decade-panel-footer {
  display: flex;
}

.rr-single-calendar .ant-calendar-selected-date {
  background: @rr-calendar-selected-date-bg;
}

.rr-single-calendar .ant-calendar-selected-date .ant-calendar-date {
  border: 1px solid transparent !important;
}

.rr-single-calendar .ant-calendar-selected-date.ant-calendar-selected-day:not(.ant-calendar-disabled-cell) .ant-calendar-date:hover {
  background-color: @rr-calendar-selected-date-bg1 !important;
}

.rr-single-calendar .ant-calendar-disabled-cell.ant-calendar-today .ant-calendar-date {
  color: rgba(0, 0, 0, 0.25);
}

.rr-single-calendar .ant-calendar-disabled-cell.ant-calendar-today .ant-calendar-date:hover {
  background-color: transparent;
}

.rr-single-calendar .ant-calendar-selected-date.ant-calendar-selected-day.ant-calendar-today .ant-calendar-date {
  border: 2px solid #8d5bae !important;
}

.rr-single-calendar .ant-calendar-selected-date.ant-calendar-today .ant-calendar-date,
.rr-single-calendar .ant-calendar-selected-date.ant-calendar-today .ant-calendar-date:hover {
  border: 2px solid #8d5bae !important;
  background-color: @rr-calendar-selected-date-bg1 !important;
  color: #FFF;
}

.rr-single-calendar .ant-calendar-selected-date.ant-calendar-selected-day .ant-calendar-date {
  background-color: @rr-calendar-selected-date-bg1;
}
@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';