.app-cursor-pointer {
  cursor: pointer;
}

.app-text-underline {
  text-decoration: underline;
}

.app-link {
  cursor: pointer;
}

.app-link-underline {
  cursor: pointer;
}

.app-link-underline:hover {
  text-decoration: underline;
}

.app-link-hover:hover {
  opacity: @rr-link-hover-color-opacity;
}