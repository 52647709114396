@import '../../../../../../../../../../assets/styles/vars';

.actions-delete-icon {
    font-size: 20px;
    color: #f4516c;
}

.actions-reset-icon {
    font-size: 20px;
    color: @rr-operation-status-cancel-color;
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';