@tree-border-color: #868aa8;

.ant-tree {
  font-feature-settings: normal;
  font-variant: normal;
}

.ant-tree li {
  padding: 2px 0;
}

.ant-tree.ant-tree-show-line li:not(:last-child)::before {
  width: 13px;
  height: 100%;
  border-top: 1px solid @tree-border-color;
  border-left: 1px solid @tree-border-color;
  margin: 7px 0;
  left: 7px;
}

.ant-tree.ant-tree-show-line li::before {
  content: ' ';
  position: absolute;
  left: 12px;
  width: 1px;
  height: 100%;
  border-left: 1px solid @tree-border-color;
  border-top: 1px solid @tree-border-color;
  margin: 12px 0;
}

.ant-tree.ant-tree-show-line li:last-child::before {
  left: 7px;
  margin: 7px 0;
  width: 13px;
  height: 1px;
}

.ant-tree.ant-tree-show-line li:first-child:not(:last-child)::before {
  height: calc(100% - 5px);
}

.ant-tree.ant-tree-show-line li span.ant-tree-switcher-noop > i {
  display: none;
}

.ant-tree.ant-tree-show-line li .ant-tree-node-content-wrapper {
  height: 15px;
  line-height: 15px;
  margin-left: 5px;

  // TODO По другому ну ни как не ставит ...
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  width: -moz-available;
  width: -webkit-fill-available;
  width: stretch; // с fill-available было предупреждение:  Replace fill-available to stretch, because spec had been changed
}

.ant-tree.ant-tree-show-line li .ant-tree-node-content-wrapper:hover {
  background-color: unset;
  color: rgba(0, 0, 0, 0.4);
}

.ant-tree.ant-tree-show-line li .ant-tree-node-content-wrapper.ant-tree-node-selected {
  background-color: transparent;
  color: #383F77;
  font-weight: bold;
}

.ant-tree.ant-tree-show-line li .ant-tree-node-content-wrapper.ant-tree-node-selected:hover {
  color: #383F77;
}

.ant-tree.ant-tree-show-line::before, .ant-tree.ant-tree-show-line ul::before {
  position: relative;
  height: 20px;
  border-left: 1px solid @tree-border-color;
  content: ' ';
  width: 1px;
  //margin: -2px 0;
  left: 7px;
  margin-bottom: -15px;
  display: block;
}

.ant-tree.ant-tree-show-line ul::before {
  left: 7px;
}

.ant-tree.ant-tree-show-line li span[draggable], .ant-tree.ant-tree-show-line li span[draggable='true'] {
  line-height: 14px;
}

// Иконка +/-
.ant-tree.ant-tree-show-line li span.ant-tree-switcher {
  color: @tree-border-color;
  font-size: 15px;
  width: 13px;
  height: 13px;
  line-height: 13px;
  margin-left: 1px;
  margin-top: 1px;
}

.ant-tree.ant-tree-show-line li span.ant-tree-switcher .anticon {
  left: -1px;
  top: -1px;
  position: relative;
}

.ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher-noop {
  background: transparent;
}

// Без этого, когда обязательство выбран, то жирные 3 точки ... не влезают
.ant-tree li .ant-tree-node-content-wrapper {
  padding-right: 8px;
}