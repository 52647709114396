.crew-member-container {
    display: flex;
    justify-content: center;

    .crew-member-icon {
        color: #34bfa3;
        font-size: 21px;
        user-select: none;
    }

    .crew-member-link {
        width: auto;
        padding-right: 8px;
    }
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';