.rr-app-footer {
  padding: 24px;
  padding-left: 32px;
  padding-right: 32px;
  background: #fff;
  display: flex;
  position: relative;
  cursor: default;
  color: #6f727d;
}

.rr-app-footer-copyrights {
  flex: auto;
  align-self: center;
  white-space: nowrap;
}

.rr-app-footer-links {
  flex: 0 1 auto;
  align-self: center;
}

.rr-app-footer-links a {
  margin-left: 12px;
  white-space: nowrap;
}

.rr-app-footer a {
  color: #6f727d;
}

.rr-app-footer a:hover {
  color: darken(#6f727d, 10%);
  text-decoration: underline;
}

@media (min-width: 0px) and (max-width: 767px) {
  .rr-app-footer {
    flex-direction: column;
    padding: 12px;
  }

  .rr-app-footer-links > * {
    display: block;
    margin-left: 0;
    text-align: center;
  }
}
@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';