.rr-tree-node-sibling:not(.root-node) {
    .ant-tree-iconEle {
        display: none !important;
    }
}

.root-node > .ant-tree-node-content-wrapper {
    padding-left: 2px !important;

    .ant-tree-iconEle {
        position: relative !important;
        width: 18px;
        height: 18px;
        .anticon {
            left: 0;
            position: absolute !important;
        }
    }
}
.rr-tree-node-sibling:not(.root-node) > .ant-tree-node-selected {
    * {
        cursor: grab !important;
    }
    display: inline-flex !important;
    align-items: center !important;
    align-content: center !important;
    width: auto !important;
    position: relative !important;

    .ant-tree-iconEle {
        height: 14px;
        width: 20px;
        position: absolute;
        right: 5px;
        display: inline-block !important;

        .anticon {
            position: absolute;
        }
    }

    svg {
        height: 14px;
        color: rgba(56, 63, 119, 0.3);
    }

    .ant-tree-title {
        position: relative;
    }
}

.rr-tree-node-sibling > .ant-tree-node-content-wrapper {
    padding-right: 21px !important;
    height: 18px !important;
}

.rr-tree-node-sibling {
    .ant-tree-node-content-wrapper {
        overflow: visible !important;
        width: auto !important;
        border-top: 2px transparent solid !important;
        border-bottom: 2px transparent solid !important;
    }

    &.drag-over:not(.drag-over-disabled) > span[draggable] {
        color: rgba(0, 0, 0, 0.65) !important;
        background: #a8acca !important;
    }
    &.drag-over > span[draggable] {
        color: rgba(0, 0, 0, 0.65) !important;
        background: none !important;
    }

    &.drag-over-gap-top > span[draggable] {
        border-top-color: #a8acca !important;
    }

    &.drag-over-gap-bottom > span[draggable] {
        border-bottom-color: #a8acca !important;
    }
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';