.rr-OperationsScheme__popover-content-container .ant-popover-inner-content {
    padding: 0;
}

.rr-OperationsScheme__popover-content {
    font-size: 14px;
    color: #575962;
    font-weight: 400;
}

.rr-OperationsScheme__popover-content > div:nth-child(1) {
    font-size: 18px;
    padding: 12px 24px;
    border-bottom: 1px solid #eaeaea;
}

.rr-OperationsScheme__popover-content > div:nth-child(2) {
    padding: 10px 12px;
}

.rr-OperationsScheme__popover-content > div:nth-child(2) > div {
    display: flex;
}

.rr-OperationsScheme__popover-content > div:nth-child(3) {
    padding: 16px;
    border-top: 1px solid #eaeaea;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.rr-OperationsScheme__popover-content > div:nth-child(3) > * {
    cursor: pointer;
    text-decoration: underline;
    font-size: 14px;
    font-weight: 400;
}

.rr-OperationsScheme__popover-content-link {
    color: #525993;
}

.rr-OperationsScheme__popover-content-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    padding: 8px 0px;
    flex-wrap: nowrap;
}

.rr-OperationsScheme__popover-content-item > div {
    flex: 1;
}

.rr-OperationsScheme__popover-balance-change-amount {
    font-weight: 600;
    text-align: right;
    white-space: nowrap;
}

.financialIcon {
    font-size: 21px;
    color: #ffcd43;
    margin-right: 16px;
}

.rr-dot {
    width: 11px;
    height: 11px;
    border-radius: 11px;
    display: inline-block;
}

.rr-status-bg-instance-ACTIVE {
    background-color: #34bfa3;
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';