.rr-round-btn-group {
    display: inline-block;
}

.rr-round-btn-group button:first-child:not(:last-child),
.rr-round-btn-group .ant-btn-round.ant-btn-lg.rr-round-btn:first-child:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.rr-round-btn-group button:not(:first-child),
.rr-round-btn-group .ant-btn-round.ant-btn-lg.rr-round-btn:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.rr-round-btn-group button:not(first-child):not(:last-child),
.rr-round-btn-group .ant-btn-round.ant-btn-lg.rr-round-btn:not(:first-child):not(:last-child) {
    border-radius: 0;
}

.rr-round-btn-group button:not(:first-child),
.rr-round-btn-group .ant-btn-round.ant-btn-lg.rr-round-btn:not(:first-child) {
    border-left: 1px solid #FFFFFF;
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';