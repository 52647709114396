@import '../../../../../../assets/styles/vars.less';

.rr-simpleOrderPage-topBlock .ant-select-selection-selected-value, .rr-simpleOrderPage-topBlock input {
  font-size: 16px;
  font-weight: 500;
}

.rr-simpleOrderPage-topBlock .ant-select-selection__placeholder {
  font-size: 16px;
}

.rr-simpleOrderPage-topBlock-counterparty-select .ant-select-selection__placeholder {
  color: @rr-color-danger;
  font-weight: 500;
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';