.header-navigation-block-container {
    display: flex;
    flex-direction: column;
    padding-left: 30px;
    padding-right: 20px;
    color: #525672;
    overflow: hidden;
    white-space: nowrap;
    min-width: 180px;

    .top-section {
        display: flex;
        font-size: 21px;
        gap: 18px;
        align-items: center;
        font-weight: 700;

        .icon {
            color: #343f9a;
            font-size: 21px;
        }

        .title {
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .bottom-section {
        padding-left: 39px;
        font-size: 14px;
        overflow: hidden;
        text-overflow: ellipsis;

        & > * {
            margin-right: 8px;
        }

        .bold {
            font-weight: 600;
        }

        .link {
            color: #343f9a;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';