
.rr-spin {
  line-height: 1;
}

.rr-spin .anticon {
  font-size: 30px;
}

.rr-spin.ant-spin-sm .anticon {
  font-size: 20px;
}

.rr-spin.ant-spin-lg .anticon {

}

.ant-spin-nested-loading.rr-spin-wrapper {
  min-height: 40px;
}

.rr-spin-wrapper .ant-spin-blur {
  opacity: 1;
}

.rr-spin-wrapper .ant-spin-blur::after {
  //opacity: 0;
}
@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';