.rr-entity-drawer {
    z-index: 1000 !important;
    cursor: default;
}

.rr-entity-drawer .ant-drawer-content,
.rr-entity-drawer .ant-drawer-wrapper-body {
    overflow: unset;
}

.rr-entity-drawer-header-bg {
    position: absolute;
    width: 10px;
    background-color: #ffffff;
    top: 0;
    bottom: 2px;
    z-index: 11;
}

.rr-entity-drawer .ant-drawer-close {
    top: 17px;
    background-color: #ffffff;
    width: 50px;
    height: 40px;
    line-height: 40px;
    font-size: 21px;
    display: none;
}

.rr-entity-drawer.rr-entity-drawer-right .ant-drawer-close {
    left: -50px;
    border-radius: 20px 0 0 20px;
    box-shadow: -2px 0 8px rgba(0, 0, 0, 0.25);
}

.rr-entity-drawer.rr-entity-drawer-right .ant-drawer-close .anticon {
    margin-left: 6px;
}

.rr-entity-drawer.rr-entity-drawer-right.ant-drawer-open .ant-drawer-close {
    display: block;
}

.rr-entity-drawer.rr-entity-drawer-right .rr-entity-drawer-header-bg {
    left: 0;
}

.rr-entity-drawer.rr-entity-drawer-left .ant-drawer-close {
    right: -50px;
    border-radius: 0px 20px 20px 0px;
    box-shadow: 2px 0 8px rgba(0, 0, 0, 0.25);
}

.rr-entity-drawer.rr-entity-drawer-left .ant-drawer-close .anticon {
    margin-left: 6px;
}

.rr-entity-drawer.rr-entity-drawer-left.ant-drawer-open .ant-drawer-close {
    display: block;
}

.rr-entity-drawer.rr-entity-drawer-left .rr-entity-drawer-header-bg {
    right: 0;
}

.rr-entity-drawer .ant-drawer-header {
    padding: 0;
    border-bottom: unset;
}

.rr-entity-drawer .ant-drawer-header .ant-drawer-title {
    color: #575962;
    font-weight: bold;
    font-size: 21px;
    line-height: unset;
}

.rr-entity-drawer .ant-drawer-body {
    padding: 0;
    position: absolute;
    top: 77px;
    bottom: 0;
    width: 100%;
    overflow-y: auto;
    //filter: blur(6px);
}

.rr-entity-drawer-header {
    height: 77px;
    display: flex;
    align-items: center;
    border-bottom: 2px solid #ebedf2;
    padding: 0 22px 0 30px;
}

.rr-entity-drawer-header-icon {
    margin-right: 8px;
    font-size: 33px;
}

.rr-entity-drawer-header-title {
    font-size: 21px;
}

.rr-entity-drawer-header-code {
    font-weight: 400;
    margin-left: 8px;
}

.rr-entity-drawer-header-archive {
    margin-left: 8px;
}

.rr-entity-drawer-header-state {
    margin-left: 8px;
    line-height: 0;
}

.rr-entity-drawer-header-actions {
    flex-grow: 1;
    display: flex;
    align-items: end;
    flex-direction: column;
}

.entity-drawer-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title-block {
        display: flex;
        justify-items: center;
        align-items: center;

        .title {
            padding: 4px 0;
            line-height: normal;
            margin-right: 12px;
            margin-left: 8px;
        }

        .title-icon {
            height: 33px;

            svg {
                height: 33px;
                width: 33px;
            }
        }
    }
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';