.auth-page.register .ant-input, .auth-page.register  .ant-select-selection  {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: block;
  border-style: solid;
  border-width: 1px;
  border-color: rgb(209, 212, 218);
  background-color: rgb(255, 255, 255);
  height: 52px;
  border-radius: 5px;
  /* line-height: 50px; */
  text-align: center;
  width: 100%;
  outline: none;
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
}

 .auth-page.register  .ant-select-selection__placeholder {
  display: flex;
  align-items: center;
}

.auth-page.register .ant-select-selection__rendered {
  height: 52px;
}

.auth-page.register input::placeholder {
  color: #3a6bdc;
  font-weight: 400;
}

.auth-page.register .ant-select-selection-selected-value {
  height: 49px;
  display: flex !important;
  align-items: center;
}

.auth-page.register .nameErrClass {
  background-color: crimson;
}

.auth-page.register .section-register .btn-green {
  background-color: rgb(61, 206, 142);
}

.auth-page.register .section-register .btn-gray {
  background-color: #d1d4da;
  cursor: default;
}

.tooltip {
  display: flex;
  justify-content: center;
  background-color: #3a6bdc;
  border-radius: 5px;
  padding: 0px;
  line-height: 1.8;
  color: #fff;
  width: 70%;
}

.auth-page.register .ant-select.ant-select-enabled {
  width: 100% ;
}

.auth-page.register .rr-form-item {
  margin-bottom: 18px;
}

.rr-form-select-placeholder {
  display: flex;
  justifyContent: center;
  alignItems: center;
  color: #3a6bdc;
  font-weight: 400;

}

.industriesValueOk .ant-select-selection.ant-select-selection--single {
  border-color: rgb(140, 221, 185);
  background-color: rgb(212, 248, 232);
  font-weight: 400;
}

.rr-select-registration-icon {

    width: 100%;
    height: 100%;
    background-image: url('../../../../modules/companyRegistration/assets/images/arrow-down.svg');
    background-position: center;
    background-repeat: no-repeat;
    /* border-color: #3a6bdc transparent transparent transparent; */
    border: none;
    top: 0;
    left: 0;
    margin: 0;
}
.rr-select-registration-icon-up {
  transform: rotate(180deg);
}


.auth-page.register .main-wrapper .ant-input {
  color: #383f4d;
}



.auth-page.register .ant-select-selection-selected-value {
  padding-left: 21px;
}

.auth-page.register .rr-form-select-placeholder {
  padding-left: 31px;
}

.btn-registration {
  margin-top: 10px;
  font-family: 'Montserrat',sans-serif;
  text-shadow: none;
}

.registration-validate-error-message {
  color: #f45872;
  display: flex;
  justify-content: center;
  margin-top: 10px;
  font-family: 'Montserrat', sans-serif;
  align-items: center;
}

.registration-validate-error-message div {
  display: flex;
  justify-content: center;
  align-items: center;

  margin-bottom: 3px;
}

.rr-company-registration-exit {
  border: 1px solid rgb(58, 107, 220);
  color: #3a6bdc;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  height: 47px;
  font-family: 'Montserrat',sans-serif;
  transition: none;
  font-size: 16px;
}

.ant-btn.rr-company-registration-exit span.rr-company-registration-exit-icon {
  display: none;
}

.rr-company-registration-exit.ant-btn:focus, .rr-company-registration-exit.ant-btn:hover {
  border: 1px solid rgb(58, 107, 220);
  color: #3a6bdc;
}

.rr-company-registration-logo-img {
  height: 33px;
}


.container.rr-company-registration-logo-div {
  display: flex;
  justify-content: center;
  align-items: center;
}





@media screen and (max-width: 767px) {
  .rr-company-registration-exit {
    padding: 0 0 0 22px;

    background-repeat: no-repeat;
    background-position: 0 50%;
    font-size: 10px;
    color: #95989d;
    border: 0px solid rgb(58, 107, 220);
    display: flex;
    align-items: center;
    transition: none;
    height: 30px;
  }

  .ant-btn.rr-company-registration-exit span.rr-company-registration-exit-icon {
    display: flex;
    margin-bottom: 2px;
    margin-right: 5px;

  }

  .rr-company-registration-exit.ant-btn:focus, .rr-company-registration-exit.ant-btn:hover {
    border: 0px solid rgb(58, 107, 220);
    color: #95989d;
  }



  .rr-company-registration-logo-img {
    height: 22px;
  }

  .container.rr-company-registration-logo-div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }


}

.rr-company-registration-license {
  font-size: 13px;
  font-family: 'Montserrat', sans-serif;
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

.rr-company-registration-license span span{
  text-decoration: underline;
}



@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';