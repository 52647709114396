.rr-operationForm-page-request .ant-card-body {
  padding-bottom: 32px - 8px;
}

.rr-operationForm-page-request__create-operation-buttons {
  text-align: center;
  padding: 19px 12px 0px;
  border-top: 1px solid #ebedf2;
}

.rr-operationForm-page-request__create-operation-buttons > * {
  margin: 0 12px 19px;
}

.rr-operationForm-page-request__create-operation-buttons > .ant-btn {
  min-width: 190px;
}

// Увеличиваем шрифт на инпутах
.rr-operationForm-page-request input, .rr-operationForm-page-request .ant-select-selection-selected-value {
  font-size: 16px;
  font-weight: 500;
}

.rr-operationForm-page-request .ant-select-selection__placeholder {
  font-size: 16px;
}

.rr-operationForm-page-request .ant-calendar-range-picker-separator {
  margin-top: 1px;
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';