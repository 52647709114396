@import '~antd/lib/style/themes/default';

@calendar-prefix-cls: ~'@{ant-prefix}-calendar';

// текстовое поле
.@{calendar-prefix-cls}-picker {
    min-width: 140px;
    //width: 140px;

    &-input {
        outline: none;
        border: none;
        /*height: 36px;*/
        color: #383e75;
        background-color: #fff;
    }

    &-input.ant-input {
        border: 1px solid @rr-default-input-border-color;
    }

    &-input.ant-input:focus {
        border-color: @rr-default-input-border-focus-color;
    }

    &:hover &-input:not(.ant-input-disabled) {
        border-color: @rr-default-input-border-hover-color;
    }

    &-input:focus {
        box-shadow: none;
    }

    &-icon,
    &-clear {
        color: #383e75;
    }

    &-clear:hover {
        color: #383e75;
    }

    &-input.ant-input-disabled + &-icon {
        cursor: not-allowed;
        color: lighten(#ccc, 8%);
    }

    &-input.ant-input-disabled {
        background-color: #fbfbfb;
    }

    &-input.ant-input-disabled::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: @rr-default-input-disabled-placeholder-color;
        opacity: 1;
    }
}

/////////////////////////////////
// Панель календаря

.ant-calendar {
    //top: 60px;
    border: none;
    width: 360px;
    user-select: none;
    font-feature-settings: normal;
    font-variant: normal;
    cursor: default;
}

.ant-calendar-picker-container-placement-topLeft .ant-calendar {
    //top: -60px;
}

.ant-calendar-picker-container-placement-topLeft .ant-calendar {
    //margin-bottom: 50px;
}

.ant-calendar-picker-container-placement-bottomLeft .ant-calendar {
    //margin-top: 50px;
    //margin-bottom: 0;
}

.ant-calendar-input-wrap {
    display: none;
}

.ant-calendar-header,
.ant-calendar-year-panel-header,
.ant-calendar-decade-panel-header,
.ant-calendar-month-panel-header {
    height: 60px;
    line-height: 60px;
    background-color: #f4f5f8;
    border-bottom: none;
    position: unset;
}

.ant-calendar-header .ant-calendar-century-select,
.ant-calendar-header .ant-calendar-decade-select,
.ant-calendar-header .ant-calendar-year-select,
.ant-calendar-header .ant-calendar-month-select {
    color: #575962;
    font-size: 16px;
}

.ant-calendar-header a:hover {
    color: #575962;
    opacity: 0.75;
}

.rr-range-calendar-my-select-disabled .ant-calendar-header .ant-calendar-my-select a {
    cursor: default;
}

.rr-range-calendar-my-select-disabled .ant-calendar-header .ant-calendar-my-select a:hover {
    opacity: 1;
}

.ant-calendar-header .ant-calendar-prev-year-btn,
.ant-calendar-header .ant-calendar-next-year-btn {
    display: none;
}

.ant-calendar-header .ant-calendar-prev-month-btn {
    left: 21px;
}

.ant-calendar-header .ant-calendar-next-month-btn {
    right: 21px; //13px;
}

.ant-calendar-header .ant-calendar-prev-century-btn,
.ant-calendar-header .ant-calendar-next-century-btn,
.ant-calendar-header .ant-calendar-prev-decade-btn,
.ant-calendar-header .ant-calendar-next-decade-btn,
.ant-calendar-header .ant-calendar-prev-month-btn,
.ant-calendar-header .ant-calendar-next-month-btn,
.ant-calendar-header .ant-calendar-prev-year-btn,
.ant-calendar-header .ant-calendar-next-year-btn {
    font-family: inherit;
    font-size: 34px;
    line-height: 58px;
}

.ant-calendar-header .ant-calendar-prev-month-btn::before,
.ant-calendar-header .ant-calendar-prev-month-btn::after,
.ant-calendar-header .ant-calendar-next-month-btn::before,
.ant-calendar-header .ant-calendar-next-month-btn::after {
    width: 14px;
    height: 14px;
    border-width: 2px 0 0 2px;
    border-color: #6c6e86;
}

.ant-calendar-header .ant-calendar-prev-month-btn:hover::before,
.ant-calendar-header .ant-calendar-prev-month-btn:hover::after,
.ant-calendar-header .ant-calendar-next-month-btn:hover::before,
.ant-calendar-header .ant-calendar-next-month-btn:hover::after {
    border-color: #a2a4b4;
}

.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-century-btn::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-decade-btn::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-year-btn::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-century-btn::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-decade-btn::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-year-btn::after {
    width: 14px;
    height: 14px;
    border-width: 2px 0 0 2px;
    border-color: #6c6e86;
}

.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-century-btn:hover::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-decade-btn:hover::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-year-btn:hover::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-century-btn:hover::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-decade-btn:hover::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-year-btn:hover::after {
    border-color: #a2a4b4;
}

.ant-calendar-year-panel-header .ant-calendar-year-panel-next-century-btn::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-century-btn::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn::after {
    width: 14px;
    height: 14px;
    border-width: 2px 0 0 2px;
    border-color: #6c6e86;
}

.ant-calendar-year-panel-header .ant-calendar-year-panel-next-century-btn:hover::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn:hover::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn:hover::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-century-btn:hover::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn:hover::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn:hover::after {
    border-color: #a2a4b4;
}

.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-century-btn::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-decade-btn::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-year-btn::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-century-btn::after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-decade-btn::after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-year-btn::after {
    width: 14px;
    height: 14px;
    border-width: 2px 0 0 2px;
    border-color: #6c6e86;
}

.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-century-btn:hover::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-decade-btn:hover::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-year-btn:hover::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-century-btn:hover::after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-decade-btn:hover::after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-year-btn:hover::after {
    border-color: #a2a4b4;
}

.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-century-btn::after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-decade-btn::after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-year-btn::after {
    left: -6px;
}

.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-century-btn::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-decade-btn::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-year-btn::before {
    left: 6px;
}

.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-century-btn::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-decade-btn::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-year-btn::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-century-btn::after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-decade-btn::after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-year-btn::after {
    width: 14px;
    height: 14px;
    border-width: 2px 0 0 2px;
    border-color: #6c6e86;
}

.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-century-btn:hover::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-decade-btn:hover::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-year-btn:hover::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-century-btn:hover::after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-decade-btn:hover::after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-year-btn:hover::after {
    border-color: #a2a4b4;
}

.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-century-btn::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-decade-btn::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-year-btn::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-century-btn::after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-decade-btn::after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-year-btn::after {
    width: 14px;
    height: 14px;
    border-width: 2px 0 0 2px;
    border-color: #6c6e86;
}

.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-century-btn:hover::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-decade-btn:hover::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-year-btn:hover::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-century-btn:hover::after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-decade-btn:hover::after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-year-btn:hover::after {
    border-color: #a2a4b4;
}

.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-century-btn::after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-decade-btn::after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-year-btn::after {
    left: -6px;
}

.ant-calendar-month-panel-header .ant-calendar-month-panel-next-century-btn::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-decade-btn::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn::before {
    left: 6px;
}

.ant-calendar-month-panel-header .ant-calendar-month-panel-next-century-btn::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-decade-btn::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-century-btn::after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-decade-btn::after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn::after {
    width: 14px;
    height: 14px;
    border-width: 2px 0 0 2px;
    border-color: #6c6e86;
}

.ant-calendar-month-panel-header .ant-calendar-month-panel-next-century-btn:hover::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-decade-btn:hover::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn:hover::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-century-btn:hover::after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-decade-btn:hover::after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn:hover::after {
    border-color: #a2a4b4;
}

.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-century-btn::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-decade-btn::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-year-btn::after {
    left: -6px;
}

.ant-calendar-year-panel-header .ant-calendar-year-panel-next-century-btn::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn::before {
    left: 6px;
}

.ant-calendar-month-select {
    text-transform: capitalize;
}

.ant-calendar-date {
    width: 30px;
    height: 30px;
    line-height: 28px;
}

.ant-calendar-cell {
    height: 30px;
    padding: 1px 0;
}

.ant-calendar-column-header .ant-calendar-column-header-inner {
    text-transform: uppercase;
    font-weight: 200;
}

.ant-calendar-column-header {
    width: 33px;
    padding: 6px 0;
    line-height: 18px;
    text-align: center;
}

.ant-calendar-body {
    padding: 8px 12px;
}

.ant-calendar-range {
    width: 700px;
}

.ant-calendar-range-middle {
    display: block;
    padding: 0;
}

.ant-calendar-range-middle::before {
    width: 2px;
    height: 208px;
    margin-top: 73px;
    margin-bottom: 110px;
    display: block;
    background-color: #edeef0;
    margin-left: 3px;
    content: '';
}

.ant-calendar-selected-day .ant-calendar-date {
    background: transparent;
}

.ant-calendar-selected-date .ant-calendar-date,
.ant-calendar-selected-start-date .ant-calendar-date,
.ant-calendar-selected-end-date .ant-calendar-date {
    // background: #FFB822;
}

.ant-calendar-selected-date .ant-calendar-date:hover,
.ant-calendar-selected-start-date .ant-calendar-date:hover,
.ant-calendar-selected-end-date .ant-calendar-date:hover {
    // background: #FFB822;
}

// текущий день

.ant-calendar-today .ant-calendar-date {
    border: 1px solid transparent;
}

.ant-calendar-today .ant-calendar-date,
.ant-calendar-today .ant-calendar-date:hover {
    //color: #FFB822;
    //font-weight: bold;
    //border-color: #FFB822;
    //background-color: transparent;

    background-color: #f7edfe;
    color: #383e75;
    font-weight: bold;
    //border: unset;
    border: 2px solid #8d5bae;
}

.ant-calendar-today .ant-calendar-date {
    line-height: 26px;
}

.ant-calendar-last-month-cell .ant-calendar-date,
.ant-calendar-next-month-btn-day .ant-calendar-date,
.ant-calendar-last-month-cell .ant-calendar-date:hover,
.ant-calendar-next-month-btn-day .ant-calendar-date:hover {
    color: rgba(0, 0, 0, 0.25);
    background: transparent;
    border-color: transparent;
}

.ant-calendar-cell:not(.ant-calendar-disabled-cell) .ant-calendar-date:hover {
    background: #e7f4fe;
    color: #3ea9f5;
    font-weight: bold;
}

.ant-calendar-cell:not(.ant-calendar-disabled-cell) .ant-calendar-date:active {
    background: #e7f4fe;
    color: #3ea9f5;
    font-weight: bold;
}

.ant-calendar-range .ant-calendar-in-range-cell::before {
    background: #e7f4fe;
    top: 1px;
    bottom: 1px;
}

.ant-calendar-cell.ant-calendar-selected-end-date::before,
.ant-calendar-cell.ant-calendar-selected-start-date::before {
    position: absolute;
    left: 0;
    display: block;
    border: 0;
    border-radius: 0;
    content: '';
    background: #e7f4fe;
    top: 1px;
    bottom: 1px;
    right: 0;
}

.ant-calendar-cell.ant-calendar-selected-end-date > div,
.ant-calendar-cell.ant-calendar-selected-start-date > div {
    position: relative;
    z-index: 1;
}

.ant-calendar-cell.ant-calendar-selected-end-date,
.ant-calendar-cell.ant-calendar-selected-start-date {
    position: relative;
    border-radius: 0;
}

.ant-calendar-in-range-cell .ant-calendar-date {
    color: #2ca2f4;
    font-weight: bold;
}

//.ant-calendar-date:active {
//  color: #fff;
//}

.ant-calendar-range-picker-separator {
    font-family: 'Proxima Nova', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.ant-calendar-range-picker-input::placeholder {
    color: @rr-default-input-placeholder-color;
    font-weight: @rr-default-input-placeholder-font-weight;
}

.ant-calendar-picker:focus .ant-calendar-picker-input:not(.ant-input-disabled) {
    border-color: @rr-default-input-border-focus-color;
    //border-width: 0;
    //border-right-width: 0 !important;
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.ant-calendar-range .ant-calendar-year-panel,
.ant-calendar-range .ant-calendar-month-panel,
.ant-calendar-range .ant-calendar-decade-panel {
    top: 0;
}

/*.ant-calendar-year-panel-header .ant-calendar-year-panel-century-select, .ant-calendar-year-panel-header .ant-calendar-year-panel-decade-select, .ant-calendar-year-panel-header .ant-calendar-year-panel-year-select, .ant-calendar-year-panel-header .ant-calendar-year-panel-month-select {
  height: 60px;
  line-height: 60px;
  background-color: #f4f5f8;
}*/

.ant-calendar-year-panel-header,
.ant-calendar-decade-panel-header {
    //height: 60px;
    //line-height: 60px;
    // background-color: #f4f5f8;
}

.ant-calendar-year-panel-header .ant-calendar-year-panel-century-select,
.ant-calendar-year-panel-header .ant-calendar-year-panel-decade-select,
.ant-calendar-year-panel-header .ant-calendar-year-panel-year-select,
.ant-calendar-year-panel-header .ant-calendar-year-panel-month-select {
    color: #575962;
    font-size: 16px;
}

.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-century-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-century-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-decade-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-month-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-month-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-year-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn {
    line-height: 62px;
}

.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-century-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-decade-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-year-btn {
    left: 21px;
}

.ant-calendar-year-panel-header .ant-calendar-year-panel-next-century-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn {
    right: 21px;
}

.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-century-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-decade-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-year-btn {
    left: 21px;
}

.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-century-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-decade-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-year-btn {
    right: 21px;
}

.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-century-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-century-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-decade-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-decade-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-month-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-month-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-year-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-year-btn {
    line-height: 62px;
}

.ant-calendar-decade-panel-century {
    color: #575962;
    font-size: 16px;
    font-weight: 500;
}

.ant-calendar-month-panel-header .ant-calendar-month-panel-century-select,
.ant-calendar-month-panel-header .ant-calendar-month-panel-decade-select,
.ant-calendar-month-panel-header .ant-calendar-month-panel-year-select,
.ant-calendar-month-panel-header .ant-calendar-month-panel-month-select {
    color: #575962;
    font-size: 16px;
    font-weight: 500;
}

.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-century-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-decade-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-year-btn {
    left: 21px;
}

.ant-calendar-month-panel-header .ant-calendar-month-panel-next-century-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-decade-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn {
    right: 21px;
}

.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-century-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-century-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-decade-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-decade-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-month-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-month-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-year-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn {
    line-height: 62px;
}

.ant-calendar-year-panel-tbody tr,
.ant-calendar-month-panel-table tr,
.ant-calendar-decade-panel-table tr {
    line-height: 58px;
}

.ant-calendar-year-panel-year,
.ant-calendar-month-panel-month,
.ant-calendar-decade-panel-decade {
    height: 30px;
    line-height: 30px;
}

.ant-calendar-month-panel-selected-cell .ant-calendar-month-panel-month,
.ant-calendar-year-panel-selected-cell .ant-calendar-year-panel-year,
.ant-calendar-decade-panel-selected-cell .ant-calendar-decade-panel-decade {
    background: #2ca2f4;
    font-weight: bold;
}

.ant-calendar-year-panel-year:hover,
.ant-calendar-month-panel-month:hover {
    background-color: #e7f4fe;
}

.ant-calendar-selected-date.ant-calendar-selected-day .ant-calendar-date {
    background-color: #2ca2f4;
    color: #fff;
    font-weight: bold;
    border: unset;
}

.ant-calendar-selected-date.ant-calendar-selected-day.ant-calendar-today .ant-calendar-date {
    color: #fff;
    border: 2px solid #8d5bae;
}

.ant-calendar-selected-date.ant-calendar-selected-day.ant-calendar-today .ant-calendar-date:hover {
    color: #2ca2f4;
}

.ant-calendar-selected-date.ant-calendar-selected-day:not(.ant-calendar-disabled-cell) .ant-calendar-date:hover {
    background-color: #1890ff;
    color: #fff;
}

.ant-calendar-today.ant-calendar-in-range-cell .ant-calendar-date,
.ant-calendar-today.ant-calendar-in-range-cell .ant-calendar-date:hover {
    background-color: transparent;
    border: 2px solid #8d5bae;
}

.ant-calendar-cell.ant-calendar-selected-end-date::before,
.ant-calendar-cell.ant-calendar-selected-start-date::before {
    background: #8bcaf9;
}

.ant-calendar-range .ant-calendar-selected-start-date .ant-calendar-date,
.ant-calendar-range .ant-calendar-selected-end-date .ant-calendar-date {
    font-weight: bold;
    background: #1890ff;
    color: #ffffff;
}

.ant-calendar-range .ant-calendar-selected-start-date .ant-calendar-date:hover,
.ant-calendar-range .ant-calendar-selected-end-date .ant-calendar-date:hover {
    font-weight: bold;
    background: #1890ff;
    color: #ffffff;
}

.ant-calendar-range .ant-calendar-today.ant-calendar-selected-start-date.ant-calendar-selected-day .ant-calendar-date,
.ant-calendar-range .ant-calendar-today.ant-calendar-selected-end-date.ant-calendar-selected-day .ant-calendar-date {
    border: 2px solid #8d5bae;
}

.ant-calendar-range .ant-calendar-body,
.ant-calendar-range .ant-calendar-month-panel-body,
.ant-calendar-range .ant-calendar-year-panel-body,
.ant-calendar-range .ant-calendar-decade-panel-body {
    border-top: none;
}

.ant-calendar-range-picker-input[disabled] {
    cursor: default;
}

.ant-calendar-disabled-cell .ant-calendar-date {
    cursor: default;
}

.ant-calendar-disabled-cell-last-of-row .ant-calendar-date {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.ant-calendar-disabled-cell-first-of-row .ant-calendar-date {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.ant-calendar-picker .ant-input-disabled .ant-calendar-range-picker-input {
    opacity: 0.3;
}

.ant-calendar-picker .ant-input-disabled .ant-calendar-range-picker-separator {
    opacity: 0.5;
}

.ant-calendar-picker .ant-input-disabled .ant-calendar-picker-icon {
    opacity: 0.5;
    cursor: default;
}

.ant-calendar-time-picker {
    top: 60px;
}

.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-century-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-decade-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-year-btn {
    height: unset;
}

.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-century-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-decade-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-year-btn {
    height: unset;
}

.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-century-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-decade-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-year-btn {
    height: unset;
}

.ant-calendar-month-panel-header .ant-calendar-month-panel-next-century-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-decade-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn {
    height: unset;
}

.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-century-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-decade-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-year-btn {
    height: unset;
}

.ant-calendar-year-panel-header .ant-calendar-year-panel-next-century-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn {
    height: unset;
}
