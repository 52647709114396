@import '../../../assets/styles/vars';

.rr-select.rr-select-multiple-search-off .ant-select-selection--multiple {
  cursor: default;
}

.rr-select.rr-select-multiple-search-off .ant-select-selection--multiple .ant-select-search--inline .ant-select-search__field {
  width: 0;
}

.rr-select-dropdown .rr-select-error {
  //padding: 5px 12px;
  color: #F4516C;
  cursor: default;
  user-select: none;
  background-color: #FFFFFFD6;
  padding: 4px;
  border-radius: 4px;
}

.rr-select-dropdown .rr-select-loading {
  //padding: 5px 12px;
  color: @rr-default-input-placeholder-color;
  font-weight: @rr-default-input-placeholder-font-weight;
  cursor: default;
  user-select: none;
  background-color: #FFFFFFD6; //aliceblue;//#FFF;
  padding: 4px;
  border-radius: 4px;
}

.rr-select-dropdown .rr-select-loading .ant-spin-text {
  display: inline;
  margin-left: 12px;
}

//.rr-select-dropdown > div + .rr-select-loading {
//    position: absolute;
//    bottom: 0;
//    left: 0;
//    right: 10px;
//}

.rr-select-info {
  display: flex;
  justify-content: center;
  margin-bottom: 4px;
  margin-top: 4px;
}

.rr-select-dropdown > div + .rr-select-info {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

}

.rr-select-dropdown  .ant-select-dropdown-menu-item-selected .rr-icon-dark {
  color: #FFFFFF;
}
@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';