.rr-add-btn2.ant-btn {
  display: inline-flex;
  align-items: center;
}

.rr-add-btn2 > span {
  color: #575962;
}

.rr-add-btn2:hover > span {
  color: darken(#575962, 10);
}

.rr-add-btn2[disabled] > span,
.rr-add-btn2[disabled]:hover > span,
.rr-add-btn2[disabled]:active > span {
  color: #c4c5d6;
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';