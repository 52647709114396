.rr-time-picker {

}

.rr-time-picker .rr-time-picker-icon {
  font-size: 20px;
  width: 20px;
  height: 20px;
  margin-top: -10px;
}
@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';