@import '../../assets/styles/vars';

.rr-rent-indicators-block.rr-collapsible-block.ant-collapse {

  .rr-collapsible-block-header-extra {
    color: #aaaaaa;
  }

   > .ant-collapse-item > .ant-collapse-header {
    border-left: 12px solid #525993;
    padding-left: 32px - 12px;
  }

  > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    left: 29px - 12px;
  }

  &.rr-rent-indicators-block-error > .ant-collapse-item > .ant-collapse-header {
    border-color: #f4516c;
  }

  &.rr-rent-indicators-block-problem > .ant-collapse-item > .ant-collapse-header {
    border-color: #FC8C2F;
  }

  .rr-collapsed-problem-indicator-sum {
    font-weight: 400;
    white-space: nowrap;
    color: #575962;
  }

  .rr-collapsed-problem-span-color {
    color: #575962;
  }

  .rr-collapsed-problem-span {
    font-weight: 400;
    font-size: 18px;
  }

  .rr-collapsed-problem-not {
    color: #34bfa3;
    font-weight: 400;
  }

  .rr-collapsed-problem-gray {
    color: #AAAAAA;
    font-weight: 400;
  }

  .rr-dot-indicators-border {
    margin-right: 10px;
    margin-left: 1px;
    margin-bottom: 1px;
  }

  .problemsAndWarnings-status-line > a .problemsAndWarnings-icon-calendar {
    font-size: 22px;
    margin-left: 10px;
    display: none;
  }

  .problemsAndWarnings-status-line:hover {
    opacity: @rr-link-hover-color-opacity;
  }

  .problemsAndWarnings-status-line:hover > a .problemsAndWarnings-icon-calendar {
    padding-bottom: 2px;
    display: inline-block ;
    color: rgba(82, 88, 145, @rr-link-hover-color-opacity);
  }

  .problemsAndWarnings-icon-color-red {
    color: #F4516c;
  }

  .problemsAndWarnings-icon-color-yellow {
    color: #FC8C2F;
  }

  .problemsAndWarnings-icon {
    font-size: 22px;
    margin-right: 10px;
    margin-bottom: 3px;
    margin-left: -2px;
  }

  .rr-form-item {
    margin-bottom: 0;
  }

  .currentRentIndicators .ant-row:not(:last-child) {
    margin-bottom: 18px;
  }

  .currentRentIndicators .ant-row:not(:last-child) {
    margin-bottom: 18px;
  }

}
@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';