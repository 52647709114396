.rr-coordinates-input {
    display: flex;
    gap: 20px;
}

.rr-coordinates-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.rr-coordinates-actions {
    display: flex;
    justify-content: space-between;
}

.rr-coordinates-map {
    flex-shrink: 0;
    flex-grow: 0;
    position: relative;
}

.rr-coordinates-locateBtn {
    position: absolute;
    bottom: 10px;
    left: 10px;
    z-index: 400;
}

.rr-coordinates-byAddressBtn.rr-link-btn-2 > .anticon {
    margin-right: 6px;
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';