@import '../../../assets/styles/vars';

.rr-disabled-module {
    cursor: default;
    text-align: center;
    margin-top: 56px;
    margin-bottom: 20px;
}

.rr-disabled-module-title {
    font-size: 18px;
    font-weight: 600;
    margin-top: 16px;
    color: #575962;
}

.rr-disabled-module-img {
    width: 158px;
    height: 158px;
    line-height: 158px;
    background-color: @rr-color-danger;
    border-radius: 50%;
    font-size: 80px;
    color: #ffffff;
    display: inline-block;
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';