.ant-input-number {
    font-feature-settings: normal;
    font-variant: normal;
    min-width: 192px;
    height: 38px;
    border-width: 0;
    border-radius: 6px;
    border-right-width: 0 !important;
}

.ant-input-number:hover {
    border-right-width: 0 !important;
}

.ant-input-number:focus {
    box-shadow: none;
}

.ant-input-number-handler-wrap {
    width: 100%;
    height: 100%;
    background: transparent;
    opacity: 1;
    transition: none;
    border-left-width: 0;
}

.ant-input-number .ant-input-number-handler-down {
    left: 0;
    border-radius: 4px 0 0 4px;
}

.ant-input-number .ant-input-number-handler-up {
    right: 0;
    border-radius: 0 4px 4px 0;
}

.ant-input-number-handler-up:hover,
.ant-input-number-handler-down:hover {
    height: 100% !important;
}

.ant-input-number-handler {
    position: absolute;
    height: 100%;
}

.ant-input-number-input-wrap {
    margin: 0 56px;
    position: relative;
    z-index: 2;
}

.ant-input-number-handler-wrap:hover .ant-input-number-handler {
    height: 100%;
}

.ant-input-number-handler:active {
    background: transparent;
}

.ant-input-number-input {
    text-align: center;
    height: 38px;
    padding: 0;
    border-radius: 0;
    border: 1px solid darken(#ebedf2, 5%);
    border-left-width: 0;
    border-right-width: 0;
    color: @rr-default-input-text-color;
}

.ant-input-number-input:hover {
    border-color: darken(darken(#ebedf2, 5%), 6%);
}

.ant-input-number-input:focus {
    border-color: #575962;
}

.ant-input-number-focused {
    border-color: transparent;
    box-shadow: none;
}

.ant-input-number-handler:after {
    color: #fff;
    top: 50%;
    display: inline-block;
    position: absolute;
    font-weight: 400;
}

.ant-input-number-handler-up:after {
    content: '+';
    font-size: 22px;
    left: 22px;
}

.ant-input-number-handler-down:after {
    content: '\2013';
    font-size: 20px;
    left: 23px;
    margin-top: -2px;
}

.ant-input-number-handler > .anticon {
    display: none !important;
}

.ant-input-number-handler,
.ant-input-number-handler:active {
    width: 56px;
    background-color: #525a95;
    border-color: #525a95;
}

.ant-input-number-handler-up:hover,
.ant-input-number-handler-down:hover {
    background-color: fade(#525a95, 90%);
    border-color: fade(#525a95, 90%);
}

.ant-input-number-handler-down-disabled,
.ant-input-number-handler-up-disabled,
.ant-input-number-handler-down-disabled:active,
.ant-input-number-handler-up-disabled:active,
.ant-input-number-handler-down-disabled:hover,
.ant-input-number-handler-up-disabled:hover {
    background-color: fade(#626a9f, 60%);
    border-color: fade(#626a9f, 60%);
}

.ant-input-number-disabled .ant-input-number-handler-wrap {
    display: block;
}

.ant-input-number-disabled .ant-input-number-handler {
    background-color: #dfe2ea;
    border-color: #dfe2ea;
    cursor: default;
}

.ant-input-number-disabled .ant-input-number-handler:after {
    color: #bebfc0;
}

.ant-input-number-disabled .ant-input-number-input {
    cursor: default;
    color: fade(@rr-default-input-text-color, 50%);
}

.ant-input-number-handler-up-disabled,
.ant-input-number-handler-down-disabled {
    cursor: default;
}

.has-error {
    &.fast {
        .ant-input-number-input {
            transition-duration: 0.15s;
        }
    }
    .ant-input-number-input {
        border-color: rgba(244, 82, 109, 0.4);

        &:hover {
            border-color: darken(rgba(244, 82, 109, 0.4), 20%);
        }
        &:focus {
            border-color: #f4526d;
        }
    }

    .ant-input-number-focused {
        border-right-width: 0 !important;
    }
}

///////////////////////////////////////////////////////////////////////////////////////
// Белый input-number для выбора часов/минут в календаре
///////////////////////////////////////////////////////////////////////////////////////

.rr-input-number-light.ant-input-number {
    min-width: 156px;
}

.rr-input-number-light.ant-input-number .ant-input-number-handler-down {
    left: 0;
}

.rr-input-number-light.ant-input-number .ant-input-number-handler-up {
    right: 0;
}

.rr-input-number-light.ant-input-number:not(.ant-input-number-disabled)
    .ant-input-number-handler-down:not(.ant-input-number-handler-down-disabled):hover
    .ant-input-number-handler-down-inner,
.rr-input-number-light.ant-input-number:not(.ant-input-number-disabled)
    .ant-input-number-handler-up:not(.ant-input-number-handler-up-disabled):hover
    .ant-input-number-handler-up-inner {
    opacity: 0.6;
}

.rr-input-number-light .ant-input-number-handler-up:after {
    content: none;
}

.rr-input-number-light .ant-input-number-handler-down:after {
    content: none;
}

.rr-input-number-light .ant-input-number-handler > .anticon {
    display: inline-block !important;
}

.rr-input-number-light .ant-input-number-handler,
.rr-input-number-light .ant-input-number-handler:active {
    width: 52px;
    background-color: #fff;
    border-color: #e8ecef;
    border: 1px solid #e8ecef;
}

.rr-input-number-light .ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-up-inner,
.rr-input-number-light .ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-down-inner {
    transform: scale(1.35) rotate(90deg);
    color: #6c6e86;
    right: 18px;
}

.rr-input-number .ant-input-number-input {
    border-radius: 4px;
}

.rr-input-number-light .ant-input-number-input {
    border-color: #e8ecef;
    font-weight: 500;
    padding: 0 4px;
}

.rr-input-number-light .ant-input-number-input-wrap {
    margin: 0 52px;
}

.rr-input-number-light
    .ant-input-number-handler-wrap
    .ant-input-number-handler.ant-input-number-handler-up-disabled
    .ant-input-number-handler-up-inner,
.rr-input-number-light
    .ant-input-number-handler-wrap
    .ant-input-number-handler.ant-input-number-handler-down-disabled
    .ant-input-number-handler-down-inner,
.rr-input-number-light.ant-input-number-disabled
    .ant-input-number-handler-wrap
    .ant-input-number-handler
    .ant-input-number-handler-up-inner,
.rr-input-number-light.ant-input-number-disabled
    .ant-input-number-handler-wrap
    .ant-input-number-handler
    .ant-input-number-handler-down-inner {
    color: fade(#6c6e86, 20%);
}

///////////////////////////////////////////////////////////////////////////////////////
// Инпут с белыми стрелками, для ввода количества обязательств в попапах
///////////////////////////////////////////////////////////////////////////////////////

.rr-input-number.ant-input-number {
    min-width: 70px;
    height: 30px;
    background-color: transparent;
}

.rr-input-number.ant-input-number .ant-input-number-handler-up > i > svg,
.rr-input-number.ant-input-number .ant-input-number-handler-down > i > svg {
    transform: rotate(90deg);
}

.rr-input-number.ant-input-number .ant-input-number-handler {
    position: absolute;
    height: 100%;
    width: 20px;
}

.rr-input-number.ant-input-number .ant-input-number-handler-down {
    border-width: 0;
}

.rr-input-number.ant-input-number .ant-input-number-handler-up-inner,
.rr-input-number.ant-input-number .ant-input-number-handler-down-inner {
    color: #fff;
}

:root .rr-input-number.ant-input-number .ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-up-inner,
:root .rr-input-number.ant-input-number .ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-down-inner {
    font-size: 28px;
}

.rr-input-number.ant-input-number .ant-input-number-input-wrap {
    margin: 0 20px;
    position: relative;
    //z-index: 1;
    border-radius: 4px;
    background-color: #fff;
}

.rr-input-number.ant-input-number-disabled .ant-input-number-input-wrap {
    background-color: fade(#fff, 50%);
}

.rr-input-number.ant-input-number:hover {
    border-right-width: 0 !important;
}

.rr-input-number.ant-input-number .ant-input-number-handler,
.rr-input-number.ant-input-number .ant-input-number-handler:active {
    background: transparent;
}

.rr-input-number.ant-input-number .ant-input-number-handler:hover .ant-input-number-handler-up-inner,
.rr-input-number.ant-input-number .ant-input-number-handler:hover .ant-input-number-handler-down-inner {
    color: #fff;
}

.rr-input-number.ant-input-number .ant-input-number-input {
    height: 30px;
    border: none;
    padding: 0 4px;
}

.rr-input-number.ant-input-number .ant-input-number-input:focus {
    border: none !important;
}

.rr-input-number.ant-input-number .ant-input-number-handler-down-inner {
    right: 8px;
    margin-top: -11px;
}

.rr-input-number.ant-input-number .ant-input-number-handler-up-inner {
    left: -1px;
    margin-top: -11px;
}

.rr-input-number.ant-input-number .ant-input-number-handler > .anticon {
    display: block !important;
}

.rr-input-number.ant-input-number .ant-input-number-handler-down-disabled .ant-input-number-handler-down-inner,
.rr-input-number.ant-input-number .ant-input-number-handler-up-disabled .ant-input-number-handler-up-inner,
.rr-input-number.ant-input-number .ant-input-number-handler-down-disabled:active .ant-input-number-handler-down-inner,
.rr-input-number.ant-input-number .ant-input-number-handler-up-disabled:active .ant-input-number-handler-up-inner,
.rr-input-number.ant-input-number .ant-input-number-handler-down-disabled:hover .ant-input-number-handler-down-inner,
.rr-input-number.ant-input-number .ant-input-number-handler-up-disabled:hover .ant-input-number-handler-up-inner {
    color: fade(#fff, 50%);
}

.rr-input-number.ant-input-number-disabled .ant-input-number-handler-down-inner,
.rr-input-number.ant-input-number-disabled .ant-input-number-handler-up-inner,
.rr-input-number.ant-input-number-disabled .ant-input-number-handler-down:active .ant-input-number-handler-down-inner,
.rr-input-number.ant-input-number-disabled .ant-input-number-handler-up:active .ant-input-number-handler-up-inner,
.rr-input-number.ant-input-number-disabled .ant-input-number-handler-down:hover .ant-input-number-handler-down-inner,
.rr-input-number.ant-input-number-disabled .ant-input-number-handler-up:hover .ant-input-number-handler-up-inner {
    color: fade(#fff, 50%);
}

.has-error .rr-input-number .ant-input-number-input {
    background-color: rgba(244, 82, 109, 0.4);
}

.has-warning .rr-input-number .ant-input-number-input {
    background-color: #ffebc1;
}

.has-error .rr-input-number.ant-input-number-focused,
.has-error .rr-input-number.ant-time-picker-input-focused,
.has-error .rr-input-number.ant-input-number:focus,
.has-error .rr-input-number.ant-time-picker-input:focus,
.has-warning .rr-input-number.ant-input-number-focused,
.has-warning .rr-input-number.ant-time-picker-input-focused,
.has-warning .rr-input-number.ant-input-number:focus,
.has-warning .rr-input-number.ant-time-picker-input:focus {
    border-right-width: 0 !important;
    box-shadow: none;
}

/////////////////////////////
.ant-calendar-footer-extra input {
    font-weight: 500;
    font-size: 16px;
}

.ant-calendar-footer-extra .rr-input-number {
    margin-right: 20px;
}

.ant-calendar-footer-extra .rr-input-number svg {
    color: rgba(87, 89, 98, 0.9);
    margin-top: 8px;
}

.ant-calendar-footer-extra .rr-input-number.ant-input-number .ant-input-number-input {
    height: 40px;
}
