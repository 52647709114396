@import '../../assets/styles/vars';

.currentRentIndicators .anticon {
    font-size: 20px;
    vertical-align: middle;
}

.rr-rent-indicators .ant-collapse {
    background-color: #fff;
}

.rr-rent-indicators .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    position: relative;
    padding: 0px;
    color: rgba(0, 0, 0, 0.85);
    line-height: inherit;
    cursor: pointer;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.rr-rent-indicators .ant-collapse-content.ant-collapse-content-active {
    padding-right: 32px;
    padding-left: 32px;
}

.rr-rent-indicators .ant-collapse-content > .ant-collapse-content-box {
    padding: 32px 0px;
}

.rr-rent-indicators .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding-left: 17px;
    padding-top: 13px;
    padding-bottom: 12px;
    display: flex;
    align-items: center;
    background-color: #fff;
}

.rr-rent-indicators-subrent-tab.ant-collapse-item-disabled {
    .rr-collapsed-header-icon {
        display: none;
    }

    .rr-collapsed-header-span,
    svg {
        color: #aaa;
    }
}

.rr-rent-indicators .tab-header-not-problem.ant-collapse > .ant-collapse-item.rr-rent-indicators-rent-tab > .ant-collapse-header {
    border-left: 12px solid #525993;
}

.rr-rent-indicators .tab-header-problem.ant-collapse > .ant-collapse-item.rr-rent-indicators-rent-tab > .ant-collapse-header {
    border-left: 12px solid #fc8c2f;
}

.rr-rent-indicators .tab-header-hard-problem.ant-collapse > .ant-collapse-item.rr-rent-indicators-rent-tab > .ant-collapse-header {
    border-left: 12px solid #f4516c;
}

.rr-rent-indicators
    .tab-header-subrent-not-problem.ant-collapse
    > .ant-collapse-item.rr-rent-indicators-subrent-tab
    > .ant-collapse-header {
    border-left: 12px solid #525993;
}

.rr-rent-indicators .tab-header-subrent-problem.ant-collapse > .ant-collapse-item.rr-rent-indicators-subrent-tab > .ant-collapse-header {
    border-left: 12px solid #fc8c2f;
}

.rr-rent-indicators
    .tab-header-subrent-hard-problem.ant-collapse
    > .ant-collapse-item.rr-rent-indicators-subrent-tab
    > .ant-collapse-header {
    border-left: 12px solid #f4516c;
}

.ant-collapse > .ant-collapse-item:last-child,
.ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
    border-radius: 0px;
}

.rr-rent-indicators .ant-collapse > .ant-collapse-item:last-child,
.ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
    border-radius: 0px;
    border-bottom: 0px;
}

.rr-rent-indicators .ant-collapse > .ant-collapse-item {
    border-bottom: 1px solid rgb(234, 234, 234);
}

.rr-rent-indicators .ant-collapse-content {
    border-top: 1px solid rgb(234, 234, 234);
}

.rr-rent-indicators .tab-header-icon {
    font-size: 22px;
    margin-right: 12px;
}

.rr-rent-indicators .tab-header-icon-color-rent {
    color: #525a95;
}

.rr-rent-indicators .tab-header-icon-color-subrent {
    color: #ffb822;
}

.rr-rent-indicators .problemsAndWarnings-icon {
    font-size: 22px;
    margin-right: 10px;
    margin-bottom: 3px;
    margin-left: -2px;
}

.rr-rent-indicators .problemsAndWarnings-status-line > a .problemsAndWarnings-icon-calendar {
    font-size: 22px;
    margin-left: 10px;
    display: none;
}

.rr-rent-indicators .problemsAndWarnings-status-line:hover {
    opacity: @rr-link-hover-color-opacity;
}

.rr-rent-indicators .problemsAndWarnings-status-line:hover > a .problemsAndWarnings-icon-calendar {
    padding-bottom: 4px;
    display: inline-block;
    color: rgba(82, 88, 145, @rr-link-hover-color-opacity);
}

.rr-rent-indicators .problemsAndWarnings-icon-color-red {
    color: #f4516c;
}

.rr-rent-indicators .problemsAndWarnings-icon-color-yellow {
    color: #fc8c2f;
}

.rr-rent-indicators .rr-collapsed-header-icon {
    font-size: 26px;
    margin-right: 24px;
    color: #575962;
}

.rr-rent-indicators .rr-collapsed-header-span {
    font-size: 18px;
    color: #575962;
}

.rr-rent-indicators .rr-collapsed-header {
    border-radius: 0px;
    border: none;
}

.rr-rent-indicators .rr-collapsed-problem-indicator-sum {
    font-weight: 400;
    white-space: nowrap;
    color: #575962;
}

.rr-rent-indicators .rr-collapsed-problem-span-color {
    color: #575962;
}

.rr-rent-indicators .rr-collapsed-problem-span {
    font-weight: 400;
    font-size: 18px;
}

.rr-rent-indicators .rr-collapsed-problem-not {
    color: #34bfa3;
    font-weight: 400;
}

.rr-rent-indicators .rr-collapsed-problem-gray {
    color: #aaaaaa;
    font-weight: 400;
}

.current-indicators-problem-icons-hover:hover {
    opacity: 0.75;
}

.rr-grid-problem-icons.ant-tooltip .ant-tooltip-arrow::before {
    background-color: #fff;
}

.rr-grid-problem-icons.ant-tooltip .ant-tooltip-inner {
    background-color: #fff;
}

.current-indicators-problem-icons {
    font-size: 20px;
    margin-right: 2px;
}

.current-indicators-problem-icons-color-hard,
.current-indicators-problem-icons-color-hard a,
.current-indicators-problem-icons-color-hard a:hover,
.rr-grid-shortName-cell.current-indicators-problem-icons-color-hard,
.rr-grid-shortName-cell.current-indicators-problem-icons-color-hard a,
.rr-grid-shortName-cell.current-indicators-problem-icons-color-hard a:hover,
.rr-grid-instanceCount-cell.current-indicators-problem-icons-color-hard,
.rr-grid-instanceCount-cell.current-indicators-problem-icons-color-hard a,
.rr-grid-instanceCount-cell.current-indicators-problem-icons-color-hard a:hover {
    color: #f4516c;
}

.current-indicators-problem-icons-color-lite,
.current-indicators-problem-icons-color-lite a,
.current-indicators-problem-icons-color-lite a:hover,
.rr-grid-shortName-cell.current-indicators-problem-icons-color-lite,
.rr-grid-shortName-cell.current-indicators-problem-icons-color-lite a,
.rr-grid-shortName-cell.current-indicators-problem-icons-color-lite a:hover,
.rr-grid-instanceCount-cell.current-indicators-problem-icons-color-lite,
.rr-grid-instanceCount-cell.current-indicators-problem-icons-color-lite a,
.rr-grid-instanceCount-cell.current-indicators-problem-icons-color-lite a:hover {
    color: #fc8c2f;
}

.ant-select-dropdown-menu-item-selected .current-indicators-problem-icons-color-hard,
.ant-select-dropdown-menu-item-selected .current-indicators-problem-icons-color-hard a,
.ant-select-dropdown-menu-item-selected .current-indicators-problem-icons-color-hard a:hover,
.ant-select-dropdown-menu-item-selected .current-indicators-problem-icons-color-lite,
.ant-select-dropdown-menu-item-selected .current-indicators-problem-icons-color-lite a,
.ant-select-dropdown-menu-item-selected .current-indicators-problem-icons-color-lite a:hover {
    color: #fff;
}

.rr-grid-number-pointer {
    color: #343f9a;
}

.rr-current-indicators-all-problem-icons {
    font-size: 36px;
    margin-right: 2px;
}

.rr-rent-indicators .ant-collapse > .ant-collapse-item:last-child,
.rr-rent-indicators .ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
    border-radius: 0px;
}

.rr-rent-indicators .rr-dot-indicators-border {
    margin-right: 10px;
    margin-left: 1px;
    margin-bottom: 1px;
}

.rr-rent-indicators-tab-title {
    display: flex;
    width: 100%;
    align-items: center;
}

.rr-rent-indicators-tab-title-hint {
    flex-grow: 1;
    text-align: right;
    color: #aaaaaa;
    padding-right: 34px;
}

.rr-rent-indicators .currentRentIndicators .ant-row:not(:last-child) {
    margin-bottom: 18px;
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';