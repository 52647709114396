// TODO Потом сделать параметры настраиваемыми
@rr-default-input-border-color: darken(#ebedf2, 5%); //
@rr-default-input-border-hover-color: darken(@rr-default-input-border-color, 6%); //
@rr-default-input-border-disabled-color: @rr-default-input-border-color; //
@rr-default-input-border-focus-color: #575962; //

@rr-default-input-text-color: rgba(87, 89, 98, 0.8); //
@rr-default-input-disabled-text-color: #ababab; //   #d0d0d0; //

@rr-default-input-placeholder-font-weight: 400; //
@rr-default-input-placeholder-color: #b9b9b9; // Цвет плэйсхолдера во всех инпутах
@rr-default-input-disabled-placeholder-color: #d0d0d0; //  Цвет плэйсхолдера во всех заблокированных инпутах TODO мб заменить цвет на потемнее // // #FF00FF; //

@rr-link-default-color: #343f9a; // Цвет ссылки по умолчанию
@rr-link-hover-color-opacity: 0.8; // Прозрачность при наведении
@rr-link-hover-color-opacity-percents: 80%;
@rr-link-hover-color: fade(@rr-link-default-color, @rr-link-hover-color-opacity-percents); //

@rr-entity-to-archive-color: #c4c5d6;
@rr-entity-from-archive-color: #00c5dc;

@rr-entity-delete-color: #f8516c;

//@rr-status-inProgress-color: #ffcd43;
@rr-status-inProgress-color: #fc8c2f;

@rr-status-active-color: #34bfa3;
@rr-status-new-color: #04b0f1;
@rr-status-planned-color: #04b0f1;
@rr-status-blocked-color: #a0a0a0;
@rr-status-notAvailable-color: #a0a0a0;
@rr-status-Finished-color: #34bfa3;

@rr-operation-status-book-color: #8b5cb1; // Бронирование / Забронирован
@rr-operation-status-cancel-color: #a0a0a0; // Отмена
@rr-operation-status-correct-color: #e83e8c; // Корректировка
@rr-operation-status-lostnoreturn-color: #08004a; // Списание
@rr-operation-status-order-color: #04b0f1; // Заявка / В заявке
@rr-operation-status-prolong-color: #3d64d4; // Продление
@rr-operation-status-rent-color: #fc8c2f; // Выдача
@rr-operation-status-draft-color: #575962; // Черновик
@rr-operation-status-planned-color: #04b0f1; // Запланировать

@rr-operation-status-return-color: #34bfa3; // Возврат
@rr-operation-status-returnbroken-color: #440000; // Прием с дефектом

@rr-operation-status-TEMPORARY_WITHDRAWN-color: #f25572; // Временно отозван
@rr-operation-status-DECOMMISSIONED-color: #08004a; // Списанн
@rr-operation-status-UNDER_MAINTENANCE-color: #a0a0a0; // На обслуживании

@rr-color-rent: #525a95; //
@rr-color-subrent: #ffb822; //
@rr-color-underMaintance: #f8516c; //

@rr-card-padding-left-right: 40px;
@rr-card-padding-top-bottom: 24px;

@rr-divider-color: #e4e5ec; // Цвет разделительной линии (в шапке в настройках)
@rr-info-text-color: #bcbcbc; // Цвет для подсказок в настройках
@rr-info-text-size: 12px; // Размер подсказок в настройках

@rr-color-available: #34bfa3;
@rr-color-danger: #f4516c;
@rr-color-warning: #fc8c2f;
@rr-color-disabled: #c4c5d6;
@rr-color-muted: #aaaaaa;

// ЦВЕТА
@rr-colors-azure: #04b0f1; // Статус новый, Заявка / В заявке
@rr-colors-green: #34bfa3; // + Статус активный, Возврат
@rr-colors-orange-1: #fc8c2f; // В прогрессе, выдача, предупреждение
@rr-colors-orange-2: #ffb822; // Субаренда
@rr-colors-orange-3: #ffcd43; // принять оплату
@rr-colors-grey-1: #575962; // Много где используется, Черновик рамка, цвет шрифта
@rr-colors-white-grey-1: #575962; // Для статуса черновика, пока для работ
@rr-colors-grey-2: #a0a0a0; // + Статус заблокирован, недоступен, Отмена, На обслуживании
@rr-colors-grey-3: #c4c5d6; // В архив
@rr-colors-grey-4: #e4e5ec; // Цвет разделительной линии (в шапке в настройках)
@rr-colors-grey-5: #bcbcbc; // Цвет для подсказок в настройках
@rr-colors-grey-6: #abacb0; // Кнопка заблокирована в гриде (в настройках, удаление например)

@rr-colors-purple-1: #383f77; // + Фиолетовый, наш основной
@rr-colors-purple-2: #525a95; // Еще один фиолетовый
@rr-colors-purple-3: #8b5cb1; // Бронирование / Забронирован
@rr-colors-purple-4: #525891; // Еще один фиолетовый (Например кнопка редактирования в гриде)

@rr-colors-cyan: #00c5dc; // Из архива
@rr-colors-red-1: #f4516c; // Этого больше всего // danger
@rr-colors-red-2: #f8516c; // Удалить, на обслуживании? //Этого меньше
@rr-colors-red-3: #f25572; // Временно отозван // Этого меньше всего

@rr-colors-pink-1: #e83e8c; // Корректировка

@rr-colors-blue-1: #3d64d4; // Продление
@rr-colors-blue-2: #08004a; // Списание / Списан
@rr-colors-brown: #440000; // Прием с дефектом

@rr-simple-order-color: #2196f3;
