.ant-notification-notice {
  font-feature-settings: normal;
  font-variant: normal;

  box-shadow: 0 1px 15px 0 rgba(69, 65, 78, 0.08);
  border-radius: 0;
}

.ant-notification {
  z-index: 9999;
}