.editable-field {
    cursor: text;
    display: flex;
    justify-content: center;

    &.active {
        text-decoration: underline;
        text-align: left;
        font-weight: 600;
        color: #343f9a;
    }
    &.disabled {
        color: #cccccc;
    }
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';