.rr-kitComponents__popover-content {
  font-size: 12px;
  font-weight: 500;
  color: rgba(87, 89, 98, 0.8);
}

.rr-kitComponents__popover-content > div {
  padding: 10px 16px;
}

//.rr-kitComponents__popover-content > div:nth-child(2) > div > div:first-child {
//  flex-grow: 1;
//  margin-right: 40px;
//}

.rr-kitComponents__popover-content > div:nth-child(1)  {
  display: table;
}

.rr-kitComponents__popover-content > div:nth-child(1) > div {
  display: table-row;
}

.rr-kitComponents__popover-content > div:nth-child(1) > div > div {
  display: table-cell;
}

.rr-kitComponents__popover-content > div:nth-child(1) > div.data-row:not(:last-child) > div {
  padding-bottom: 4px;
}

.rr-kitComponents__popover-content > div:nth-child(1) > div.data-row > div:nth-child(1) {
  padding-right: 12px;
}

.rr-kitComponents__popover-content > div:nth-child(1) > div.data-row > div:nth-child(2),
.rr-kitComponents__popover-content > div:nth-child(1) > div.result-row > div:nth-child(2) {
  max-width: 340px;
  min-width: 180px;
}


.rr-kitComponents__popover-content > div:nth-child(1) > div.data-row > div:nth-child(3),
.rr-kitComponents__popover-content > div:nth-child(1) > div.result-row > div:nth-child(3) {
  text-align: right;
  padding-left: 30px;
}

.rr-kitComponents__popover-content > div:nth-child(1) > div.result-row > div {
  padding-top: 14px;
}

.rr-kitComponents__popover-content > div:nth-child(1) > div.result-row > div:nth-child(3) {
  font-weight: 700;
}

.rr-kitComponents__popover-content .product-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.rr-kitComponents__popover-content .product-name > a {
  font-weight: 700;
  color: #343f9a;
}

.rr-kitComponents__popover-content .product-name > a:hover {
  text-decoration: underline;
  color: #343f9a;
}

.rr-kitComponents__popover-content .variant-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.rr-kitComponents__popover-content > div:nth-child(2) {
  border-top: 1px solid #cccccc;
  text-align: center;
  font-size: 11px;
  font-weight: 400;
}

.rr-kitComponents__popover-content > div:nth-child(2) > a {
  user-select: none;
  text-decoration: underline;
  color: #525993;
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';