.rr-checkbox {
  color: #525993;
}

.rr-checkbox .ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: unset;
  font-weight: bold;
  color: #525993;
  transform: translate(-50%, -55%) scale(1);
  content: '?';
}

.rr-checkbox .ant-checkbox-indeterminate .ant-checkbox-inner,
.rr-checkbox .ant-checkbox-indeterminate:hover .ant-checkbox-inner {
  background-color: #e2e5ec;
  border-color: #e2e5ec;
}

.rr-checkbox .ant-checkbox-indeterminate .ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #e2e5ec;
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';