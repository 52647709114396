.rr-icon-attachment-TEXT_DOCUMENT {
  color: #525992;
}

.rr-icon-attachment-IMAGE {
  color: #06B0F1;
}

.rr-icon-attachment-AUDIO {
  color: #30BFA3;
}

.rr-icon-attachment-VIDEO {
  color: #8B5CB2;
}

.rr-icon-attachment-ARCHIVE {
  color: #FD8C2D;
}

.rr-icon-attachment-PRESENTATION {
  color: #C94421;
}

.rr-icon-attachment-WORD {
  color: #1761BA;
}

.rr-icon-attachment-EXCEL {
  color: #117F43;
}

.rr-icon-attachment-PDF_TYPE {
  color: #DE222A;
}

.rr-icon-attachment-OTHERTYPE {
  color: #A0A0A0;
}

.rr-icon-attachment-CONTRACT {
  color: #34BFA3;
}
@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';