.rr-statuses-filter {
    .ant-select-selection--multiple {
        cursor: default;
        min-width: 160px;
        max-width: 260px;

        .ant-select-search--inline {
            margin-right: 0 !important;
        }
    }

    .calendar-multi-select {
        display: none;
    }
}

.ant-select-search__field {
    cursor: default;
}

.rr-statuses-filter .ant-select-selection--multiple > ul > li,
.rr-statuses-filter .ant-select-selection--multiple .ant-select-selection__rendered > ul > li {
    height: 24px;
    line-height: 24px;
}

.rr-statuses-filter-dropdown {
    min-width: 160px;
    max-width: 260px;
    background-color: #fff;
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';