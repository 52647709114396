.rr-OperationElementCancelToggleButton {
  font-size: 22px;
  vertical-align: middle;
  color: #9e9e9f;
}

.rr-OperationElementCancelToggleButton:hover {
  opacity: .8;
}

.rr-OperationElementCancelToggleButton-cancelled {
  color: #33bfa3;
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';