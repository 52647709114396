.rr-operationForm-type-select-buttons-group {
  position: relative;
  display: inline-flex;
}

.rr-operationForm-type-select-buttons-group-create-button.ant-btn-round.ant-btn-lg.rr-round-btn {
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
}

.rr-operationForm-type-select-buttons-group-select-button.ant-btn-round.ant-btn-lg.rr-round-btn {
  border-radius: 0 8px 8px 0;
  border-left: 2px solid #f0f2f5;
  padding-left: 16px;
  padding-right: 16px;
}

.rr-operationForm-type-select-buttons-group-select-button.ant-btn-round.ant-btn-lg.rr-round-btn .anticon {
  font-size: 16px;
}

.rr-operationForm-type-select-buttons-group__correction-popover-content .ant-popover-inner-content {
  padding: 0;
}

.rr-operationForm-type-select-buttons-group-create-button.ant-btn-round.ant-btn-lg.rr-round-btn.rr-round-btn-DRAFT,
.rr-operationForm-type-select-buttons-group-create-button.ant-btn-round.ant-btn-lg.rr-round-btn.rr-round-btn-SUBRENT_DRAFT {
  border-radius: 8px 0 0 8px;
}

.rr-operationForm-type-select-buttons-group-select-button.ant-btn-round.ant-btn-lg.rr-round-btn.rr-round-btn-DRAFT,
.rr-operationForm-type-select-buttons-group-select-button.ant-btn-round.ant-btn-lg.rr-round-btn.rr-round-btn-SUBRENT_DRAFT {
  border-radius: 0 8px 8px 0;
  border-left: none;
}

.rr-operationForm-type-select-buttons-group-select-button.ant-btn-round.ant-btn-lg.rr-round-btn.rr-round-btn-SUBRENT_BOOK_SHIPMENT,
.rr-operationForm-type-select-buttons-group-select-button.ant-btn-round.ant-btn-lg.rr-round-btn.rr-round-btn-SUBRENT_ACCEPT_SHIPMENT,
.rr-operationForm-type-select-buttons-group-select-button.ant-btn-round.ant-btn-lg.rr-round-btn.rr-round-btn-SUBRENT_PROLONG,
.rr-operationForm-type-select-buttons-group-select-button.ant-btn-round.ant-btn-lg.rr-round-btn.rr-round-btn-SUBRENT_RETURN_TO_SHIPPER,
.rr-operationForm-type-select-buttons-group-select-button.ant-btn-round.ant-btn-lg.rr-round-btn.rr-round-btn-SUBRENT_CANCEL,
.rr-operationForm-type-select-buttons-group-select-button.ant-btn-round.ant-btn-lg.rr-round-btn.rr-round-btn-SUBRENT_DRAFT {
  border-left: none;
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';