.userPopover-icon {
  font-size: 20px;
  margin-right: 20px;
}

.userPopover-block {
  padding: 10px 20px 10px 20px;
  width: 600px;
}

.user-popover-action:hover {
   background-color:#f4f5f8 ;
 }

.user-popover-icon-action:hover {
  color:#ccc ;
}

.user-popover-icon-action {
  color:#fff ;
}

.user-popover-icon-action2 {
  background-color:#525A95 ;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.user-popover-icon-action2-hover:hover {
  background-color:#383F77 ;
  border-radius: 0px 0px 5px 5px;
}


.ant-popover-placement-rightBottom > .ant-popover-content > .ant-popover-arrow {
  border-left-color: #525A95;
  border-bottom-color: #525A95 ;
}

.user-popover-account-unhover {
  display: none;
}

.user-popover-account-hover {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
}

.search-visible {
  display: none;
}
@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';